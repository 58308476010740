import { BiologicalSexKey } from 'shared/src/constants/constants';
import { SuburbDetails } from 'shared/src/pickers/suburb-picker/suburb-picker';
import { EventAttributionIdentifiersInput } from 'shared/src/types/graphql-schema';
import {
  useReliableLocalStorage,
  useReliableSessionStorage,
} from 'shared/src/util/use-reliable-storage/use-reliable-storage';

const STORAGE_BIOLOGICAL_SEX = 'biological_sex';
const STORAGE_CONDITION_ID_KEY = 'condition_id';
const STORAGE_COUNTRY_KEY = 'country_code';
const STORAGE_DOB_KEY = 'dob';
const STORAGE_FIRST_NAME_KEY = 'first_name';
const STORAGE_LAST_NAME_KEY = 'last_name';
const STORAGE_EMAIL_KEY = 'email';
const STORAGE_SUBURB_DETAILS_KEY = 'suburb_details';
const STORAGE_SUBURB_HINT_KEY = 'suburb_hint';
const STORAGE_PHONE_NUMBER = 'phone_number';
const STORAGE_LONG_DISTANCE_REASON = 'long_distance_reason'; // Trial site id is appended to the end
const STORAGE_POST_LOGIN_REDIRECT = 'post_login_redirect';
const CALENDLY_SUBMITTED = 'calendly_submitted'; // Trial site id is appended to the end
const STORAGE_REVERSE_SIGNUP = 'reverse_signup';
const ATTRIBUTION_IDENTIFIERS = 'attribution_identifiers';

export const useBiologicalSexFromStorage = (biologicalSex?: BiologicalSexKey) =>
  useReliableSessionStorage(STORAGE_BIOLOGICAL_SEX, biologicalSex);

export const useConditionIdFromStorage = (conditionId?: ConditionID) =>
  useReliableSessionStorage(STORAGE_CONDITION_ID_KEY, conditionId);

export const useCountryCodeFromStorage = (countryCode?: CountryCode) =>
  useReliableSessionStorage(STORAGE_COUNTRY_KEY, countryCode);

export const useDobFromStorage = (dob?: string) => useReliableSessionStorage(STORAGE_DOB_KEY, dob);

export const useFirstNameFromStorage = (firstName?: string) =>
  useReliableSessionStorage(STORAGE_FIRST_NAME_KEY, firstName);

export const useLastNameFromStorage = (lastName?: string) =>
  useReliableSessionStorage(STORAGE_LAST_NAME_KEY, lastName);

export const useSuburbDetailsFromStorage = (suburbDetails?: SuburbDetails) =>
  useReliableSessionStorage(STORAGE_SUBURB_DETAILS_KEY, suburbDetails);

export const useSuburbHintFromStorage = (suburbHint?: string) =>
  useReliableSessionStorage(STORAGE_SUBURB_HINT_KEY, suburbHint);

export const useAttributionIdentifiersFromStorage = (
  attributionParams?: EventAttributionIdentifiersInput
) => useReliableLocalStorage(ATTRIBUTION_IDENTIFIERS, attributionParams);

export const useLongDistanceReasonFromStorage = (trialSiteId?: TrialSiteID) =>
  useReliableSessionStorage<string | undefined>(
    `${STORAGE_LONG_DISTANCE_REASON}/${trialSiteId}`,
    undefined
  );

export const useCalendlySubmittedFromStorage = (trialSiteId?: TrialSiteID) =>
  useReliableLocalStorage<boolean | undefined>(`${CALENDLY_SUBMITTED}/${trialSiteId}`, undefined);

export const useEmailFromStorage = (email?: string) =>
  useReliableSessionStorage(STORAGE_EMAIL_KEY, email);

export const usePhoneFromStorage = (phone?: string) =>
  useReliableSessionStorage(STORAGE_PHONE_NUMBER, phone);

export const useReverseSignupFromStorage = () =>
  useReliableSessionStorage<boolean>(STORAGE_REVERSE_SIGNUP);

export const usePostLoginRedirectFromStorage = (path?: string) =>
  useReliableSessionStorage(STORAGE_POST_LOGIN_REDIRECT, path);
