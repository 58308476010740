import { ElementType, FC, forwardRef, MouseEvent } from 'react';

import { Button, ButtonProps, Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { delay } from 'shared/src/util/delay';

export const Link: FC<MuiLinkProps<RouterLink, RouterLinkProps>> = ({ ...props }) => (
  <MuiLink component={RouterLink} {...props} />
);

type ExternalLinkProps = MuiLinkProps<
  'a',
  {
    LinkComponent?: ElementType;
    eventLabel: string;
    target?: string;
    to: string;
  }
>;

const NEW_TAB = '_blank';
const MIDDLE_CLICK = 1;
const MAX_CLICK_DELAY = 250;

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ LinkComponent = MuiLink, to, eventLabel, onClick, ...props }, ref) => {
    di(useAnalytics, useLocation);
    const analytics = useAnalytics();
    const location = useLocation();

    // Adapted from ReactGA
    // https://github.com/react-ga/react-ga/blob/984a29c61d393eda13058a9e62e0e42ed170fb6e/src/components/OutboundLink.js#L14
    const handleClick = (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
      if (!analytics) {
        onClick?.(event);
        return;
      }

      const sameTarget = props.target !== NEW_TAB;
      const normalClick = !(
        event.ctrlKey ||
        event.shiftKey ||
        event.metaKey ||
        event.button === MIDDLE_CLICK
      );

      const trackPromise = analytics.track({
        event: 'hmio.outbound.click',
        destination: eventLabel,
        location: location.pathname,
      });

      if (sameTarget && normalClick) {
        event.preventDefault();
        Promise.race([trackPromise, delay(MAX_CLICK_DELAY)]).then(() => {
          window.location.href = to;
          onClick?.(event);
        });
      } else {
        onClick?.(event);
      }
    };

    return <LinkComponent {...props} ref={ref} href={to} onClick={handleClick} />;
  }
);

export const ExternalLinkButton: FC<ButtonLinkProps> = ({ ...props }) => (
  <Button component={ExternalLink} LinkComponent="a" {...props} />
);

export type ButtonLinkProps = ButtonProps<RouterLink, RouterLinkProps>;

export const ButtonLink: FC<ButtonLinkProps> = ({ ...props }) => (
  <Button component={RouterLink} {...props} />
);

export type LinkButtonProps = Omit<MuiLinkProps<'button'>, 'component'>;

export const LinkButton: FC<LinkButtonProps> = ({ ...props }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <MuiLink component="button" {...props} />
);
