import { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { RouteComponentProps } from 'react-router-dom';

import { useFeatureFlag } from 'shared/src/feature-flags/use-feature-flag';
import { lightPurple } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import { AuthorAbout, AuthorProfilePicture, AuthorTitle } from 'components/author/author';
import { ContentLinks } from 'components/content-links/content-links';
import { ErrorPage } from 'components/error/error-page';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { WhyMedicalReviewers } from 'pages/medical-reviewer/why-medical-reviewers/why-medical-reviewers';
import { NotFound } from 'pages/not-found/not-found';

import { useConditionArticlesByCommonMedicalReviewer } from './queries.contentful';

type MedicalReviewerProps = {
  slug: string;
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(8),
  },
  whyReviewers: {
    paddingTop: theme.spacing(10),
  },
  about: {
    paddingTop: theme.spacing(3),
  },
  links: {
    width: '100%',
    background: lightPurple[50],
    paddingBottom: theme.spacing(10),
  },
}));

// despite the fact that "articles reviews" are conditionally
// rendered, this page is never shown unless there are articles.
const FOOTER_BLEED = { kind: 'background', color: lightPurple[50] } as const;

export const MedicalReviewer: FC<MedicalReviewerProps> = ({ slug }) => {
  di(useConditionArticlesByCommonMedicalReviewer, PageBase);
  const classes = useStyles();

  const {
    data: conditionAuthorArticlesData,
    error: conditionAuthorArticlesError,
    loading: conditionAuthorArticlesLoading,
  } = useConditionArticlesByCommonMedicalReviewer({
    variables: {
      slug,
    },
  });

  const articleData = conditionAuthorArticlesData?.conditionArticleCollection?.items;
  const authorData = conditionAuthorArticlesData?.conditionAuthorCollection?.items[0];

  if (conditionAuthorArticlesLoading) {
    // we don't have the reviewer info so we can't show their name in the title
    return <LoadingPage windowTitle="Medical reviewer" />;
  }

  if (conditionAuthorArticlesError || !authorData) {
    return <ErrorPage />;
  }

  if (!articleData) {
    return <NotFound />;
  }

  const { about, profilePicture, name } = authorData;
  const title = authorData.name ?? 'Medical reviewer';

  return (
    <PageBase windowTitle={title} footerBleed={FOOTER_BLEED}>
      <div className={classes.container}>
        {profilePicture && (
          <AuthorProfilePicture profilePicture={profilePicture} label="Medical Reviewer" />
        )}
        {name && <AuthorTitle name={name} />}
        {about && (
          <div className={classes.about}>
            <AuthorAbout about={about} />
          </div>
        )}
        <div className={classes.whyReviewers}>
          <WhyMedicalReviewers />
        </div>
        {articleData.length > 0 && (
          <Box className={classes.links}>
            <ContentLinks
              title={`Articles reviewed by ${authorData.name}`}
              links={articleData.filter(notEmpty)}
              categoryLinks
            />
          </Box>
        )}
      </div>
    </PageBase>
  );
};

export const MedicalReviewerRoute: FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
  di(useFeatureFlag);
  const { value: medicalReviewerPageEnabled } = useFeatureFlag('medicalReviewerPageEnabled', false);

  if (!medicalReviewerPageEnabled) {
    return <NotFound />;
  }

  return <MedicalReviewer slug={match.params.slug} />;
};
