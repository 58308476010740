import { FC } from 'react';

import { Box, Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';
import { di } from 'react-magnetic-di/macro';
import { Link, LinkProps } from 'react-router-dom';

import { CarouselMultiple } from 'shared/src/carousel/carousel-multiple';
import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { useFeatureFlag } from 'shared/src/feature-flags/use-feature-flag';
import { nantesFontFamily } from 'shared/src/fonts/fonts';
import { LazyImage } from 'shared/src/image/image';
import { lightPurple, peach, purple, vividPurple } from 'shared/src/palette/color-system';

import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { useFetchContentHubHomeRaw } from 'pages/content-hub/queries.contentful';
import { FetchContentHubHome } from 'pages/content-hub/types/queries.contentful.generated';
import { NotFound } from 'pages/not-found/not-found';

import waves from './waves.svg';

type CollectionColorSet = {
  backgroundColor: string;
  primaryColor: string;
};

// todo change these to secondary colors
// https://www.figma.com/design/EKAJnpTaCTX0pJT7aoaY73/2023-S1-Design-system?node-id=0-1&t=GTSi220mwwxcBsi7-0
const collectionsColors: CollectionColorSet[] = [
  { backgroundColor: peach[50], primaryColor: peach[800] },
  { backgroundColor: lightGreen[50], primaryColor: '#63A68A' },
  { backgroundColor: vividPurple[50], primaryColor: vividPurple[700] },
];

const useCollectionStyles = makeStyles<Theme, { color: string }>(({ spacing }) => ({
  outlinedText: {
    fontFamily: nantesFontFamily,
    fontSize: '24px',
    color: props => props.color,
    // WebkitTextStroke: props => `1.5px ${props.color}`, // Outline color and width
    fontWeight: 'bold',
    // letterSpacing: '-2px', // Reducing letter spacing
    textAlign: 'center',
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  button: {
    backgroundColor: props => props.color,
    padding: spacing(0.5, 1),
    textDecoration: 'none',
    color: 'white',
    borderRadius: spacing(1),
    marginTop: spacing(1),
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  heroImage: {
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    maxHeight: '400px',
  },
  articleHeroImage: {
    width: '100%',
    // maxHeight: '00px',
  },
  heroArticleTitle: {
    [breakpoints.down('xs')]: {
      fontSize: '20px',
    },
    [breakpoints.up('sm')]: {
      fontSize: '32px',
    },
    fontFamily: nantesFontFamily,
    color: purple[500],
    textDecoration: 'none',
    textAlign: 'center',
  },
  articleTitle: {
    fontFamily: nantesFontFamily,
    fontSize: '16px',
    color: purple[500],
    textDecoration: 'none',
    textAlign: 'center',
    marginTop: spacing(1),
  },
  sectionTitle: {
    [breakpoints.down('xs')]: {
      fontSize: '20px',
    },
    [breakpoints.up('sm')]: {
      fontSize: '28px',
    },
    color: purple[500],
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 900,
    marginBottom: spacing(2),
  },
  medicationButton: {
    borderRadius: '8px',
    padding: spacing(1, 2),
    backgroundColor: lightPurple[800],
    color: 'white',
  },
}));

const WaveLine = () => (
  <Box display="flex" justifyContent="center" marginTop={4} marginBottom={2}>
    <LazyImage src={waves} alt="" />
    <LazyImage src={waves} alt="" />
  </Box>
);

const LinkWithoutUnderline = (props: LinkProps) => (
  <Link {...props} style={{ textDecoration: 'none' }} />
);

const SectionTitle: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.sectionTitle}>
      {children}
    </Typography>
  );
};

type CollectionData = NonNullable<
  NonNullable<
    NonNullable<FetchContentHubHome['contentHubCollection']>['items'][0]
  >['collectionsCollection']
>['items'][0];
type CollectionProps = {
  collection: CollectionData;
  index: number;
};
const Collection: FC<CollectionProps> = ({ collection, index }) => {
  const { backgroundColor } = collectionsColors[index % collectionsColors.length];
  const { primaryColor } = collectionsColors[index % collectionsColors.length];
  const classes = useCollectionStyles({ color: primaryColor });

  if (!collection) {
    return null;
  }

  // todo link to the individual condition?
  const uniqueConditions = [
    ...new Set(collection.articlesCollection?.items?.map(item => item?.category?.condition)),
  ];
  return (
    <Box bgcolor={backgroundColor} height="100%" borderRadius="12px" paddingY={3} paddingX={1}>
      <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gridGap="4px">
        {uniqueConditions.map(condition => (
          <Box
            bgcolor="white"
            borderRadius="4px"
            paddingX={1}
            paddingY={0.5}
            color={primaryColor}
            fontSize="12px"
          >
            {condition}
          </Box>
        ))}
      </Box>
      <Typography variant="h3" className={classes.outlinedText}>
        {collection.shortTitle}
      </Typography>

      <Box display="flex" justifyContent="center">
        <Link to={`/topic/${collection.slug}`} className={classes.button}>
          Learn more
        </Link>
      </Box>
    </Box>
  );
};

export const ContentHubPage: FC = () => {
  di(PageBase);

  const classes = useStyles();
  const { value: enabled } = useFeatureFlag('contentHubEnabled', false);

  const { data } = useFetchContentHubHomeRaw({ variables: { preview: true } });

  const contentHubPage = data?.contentHubCollection?.items[0];

  if (!enabled) {
    return <NotFound />;
  }

  if (!contentHubPage) {
    return <LoadingPage windowTitle="Content Hub" />;
  }

  return (
    <PageBase windowTitle="Content Hub">
      {contentHubPage.heroArticle?.heroImage && (
        <LinkWithoutUnderline to={`/blog/${contentHubPage.heroArticle?.slug}`}>
          <Container disableGutters>
            <Box display="flex" justifyContent="center">
              <ContentfulImage
                image={contentHubPage.heroArticle?.heroImage}
                maxWidth="sm"
                maxHeight={800}
                // resize=
                // resize="fill"
                className={classes.heroImage}
              />
            </Box>
          </Container>
        </LinkWithoutUnderline>
      )}
      <Container>
        <Box marginTop={2}>
          <LinkWithoutUnderline to={`/blog/${contentHubPage.heroArticle?.slug}`}>
            <Typography variant="h5" className={classes.heroArticleTitle}>
              {contentHubPage.heroArticle?.title}
            </Typography>
          </LinkWithoutUnderline>
        </Box>
        <WaveLine />

        <SectionTitle>Featured</SectionTitle>
        <CarouselMultiple>
          {contentHubPage.featuredArticlesCollection?.items?.map(
            featuredArticle =>
              featuredArticle && (
                <LinkWithoutUnderline
                  key={featuredArticle.sys.id}
                  to={`/blog/${featuredArticle.slug}`}
                >
                  {featuredArticle.heroImage && (
                    <ContentfulImage
                      image={featuredArticle.heroImage}
                      maxWidth="sm"
                      maxHeight={500}
                      resize="fill"
                      className={classes.articleHeroImage}
                    />
                  )}
                  <Typography variant="h5" className={classes.articleTitle}>
                    {featuredArticle?.title}
                  </Typography>
                  {/*  todo can't get preview of content afaict, maybe set the subtitle or something? */}
                </LinkWithoutUnderline>
              )
          )}
        </CarouselMultiple>
        <WaveLine />
        <SectionTitle>Collections</SectionTitle>

        <CarouselMultiple>
          {contentHubPage.collectionsCollection?.items?.map((collection, index) => (
            <Collection key={collection?.sys.id} collection={collection} index={index} />
          ))}
        </CarouselMultiple>

        {/* <WaveLine /> */}
        {/* <SectionTitle>Medications</SectionTitle> */}

        {/* <Box display="flex" justifyContent="center" marginY={2}> */}
        {/*  <LinkWithoutUnderline to="/medications" className={classes.medicationButton}> */}
        {/*    View the medications directory */}
        {/*  </LinkWithoutUnderline> */}
        {/* </Box> */}

        {/* todo link to all medications */}
        {/* <Box border={'1px solid #63A68A'} borderRadius={'8px'}> */}
        {/*  Common medications */}
        {/*  {contentHubPage.medicationsCollection?.items?.map(medication => ( */}
        {/*    <div> */}
        {/*      <LinkWithoutUnderline key={medication?.sys.id} to={`/medication/${medication?.slug}`}> */}
        {/*        {medication?.title} */}
        {/*      </LinkWithoutUnderline> */}
        {/*    </div> */}
        {/*  ))} */}
        {/* </Box> */}

        <WaveLine />
        <SectionTitle>Conditions</SectionTitle>

        {/* todo images lazy loading? looks bad */}
        <Box marginBottom={4}>
          <CarouselMultiple>
            {contentHubPage.conditionsCollection?.items?.map(condition => (
              <LinkWithoutUnderline key={condition?.sys.id} to={`/condition/${condition?.slug}`}>
                {condition?.heroImage && (
                  <ContentfulImage
                    image={condition?.heroImage}
                    maxWidth="sm"
                    maxHeight={500}
                    resize="fill"
                    className={classes.heroImage}
                  />
                )}
                <Box color={purple[500]} textAlign="center" fontSize="16px" fontWeight="bold">
                  {condition?.condition}
                </Box>
              </LinkWithoutUnderline>
            ))}
          </CarouselMultiple>
        </Box>
      </Container>
    </PageBase>
  );
};
