import { FC } from 'react';

import {
  createTheme,
  jssPreset,
  responsiveFontSizes,
  SimplePaletteColorOptions,
  StylesProvider as MuiStylesProvider,
  ThemeOptions,
} from '@material-ui/core';
import { TypeText } from '@material-ui/core/styles/createPalette';
import createSpacing from '@material-ui/core/styles/createSpacing';
import shadows, { Shadows } from '@material-ui/core/styles/shadows';
import { create } from 'jss';
import jssPluginSyntaxCompose from 'jss-plugin-compose';

import { fontFiles } from 'shared/src/fonts/font-files';
import {
  fontWeightBold,
  fontWeightLight,
  fontWeightRegular,
  getFontFaces,
  latoFontFamily,
  nantesFontFamily,
} from 'shared/src/fonts/fonts';
import { ArrowRight } from 'shared/src/icon/icon-images';
import {
  beige,
  gray,
  lightPurple,
  linkColor,
  purple,
  vividPurple,
} from 'shared/src/palette/color-system';
import { palette } from 'shared/src/palette/palette';

import { Overrides } from './types';

const jss = create({
  // Compose plugin should go to the end
  plugins: [...jssPreset().plugins, jssPluginSyntaxCompose()],
});

export const StylesProvider: FC = ({ children }) => (
  <MuiStylesProvider jss={jss}>{children}</MuiStylesProvider>
);

const breakpointValues = {
  xs: 0,
  sm: 768, // default 600
  md: 992, // default 960
  lg: 1200, // default 1280
  xl: 1920,
};

/**
 * NB! Should be only used to define subthemes and never for actual styling
 */
export const spacing = createSpacing(8);
const text = palette.text as Partial<TypeText>;

const headingLineHeight = 1.18;
export const buildHeadingStyles = (fontSize: number, fontFamily: string = nantesFontFamily) => ({
  color: text.secondary,
  fontSize,
  fontFamily,
  fontWeight: fontWeightBold,
  lineHeight: headingLineHeight,
  // Corresponds to tracking 10 in Adobe products
  // See https://stackoverflow.com/a/36612356
  letterSpacing: '0.01em',
});

const extraOverrides: Overrides = {
  MuiAutocomplete: {
    // These are ugly and specific selectors because they're hardcoded with this specificity in material ui
    // See:
    //  - https://www.javaer101.com/en/article/38800820.html
    //  - https://stackoverflow.com/questions/60531601/how-can-i-customize-the-padding-in-a-material-ui-autocomplete-control
    //  - https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Autocomplete/Autocomplete.js
    inputRoot: {
      '&&[class*="MuiOutlinedInput-root"]': {
        padding: '1px 0',
      },
      '&&[class*="MuiOutlinedInput-root"] $input:first-child': {
        paddingLeft: spacing(1.5),
      },
      '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: spacing(1.5),
      },
    },
  },
  MuiAlert: {
    standardWarning: {
      backgroundColor: beige[50],
      '& .MuiAlert-icon': {
        color: beige[700],
      },
    },
  },
};

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: latoFontFamily,
    fontWeightLight,
    fontWeightRegular,
    fontWeightBold,
    h1: buildHeadingStyles(65),
    h2: buildHeadingStyles(55),
    h3: buildHeadingStyles(45),
    h4: buildHeadingStyles(35, latoFontFamily),
    h5: buildHeadingStyles(25, latoFontFamily),
    h6: buildHeadingStyles(22, latoFontFamily),
    subtitle1: {
      fontSize: 20,
      lineHeight: headingLineHeight,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: headingLineHeight,
    },
    // Used in components, e.g. inputs
    body1: {
      fontSize: 14,
    },
    // Assigned to body tag
    body2: {
      fontSize: 16,
    },
    button: {
      fontSize: 14,
      // TODO: try and use medium instead according to the guidelines
      fontWeight: fontWeightBold,
      letterSpacing: '0.02rem',
      textTransform: 'initial',
    },
    caption: {},
  },
  // TODO: remove and use defaults or the new grid
  breakpoints: {
    values: breakpointValues,
  },
  palette,
  // Redefine the default shadow
  shadows: ['none', '0px 4px 8px rgba(91,91,91,0.3)', ...shadows.slice(2)] as Shadows,
  shape: {
    borderRadius: spacing(1.5),
  },
  overrides: {
    ...extraOverrides,
    MuiCssBaseline: {
      '@global': {
        '@font-face': getFontFaces(fontFiles),
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: 15,
        '&.MuiTypography-h2': {
          marginBottom: 30,
          [`@media (max-width: ${breakpointValues.md}px)`]: {
            marginBottom: 15,
          },
        },
      },
    },
    MuiLink: {
      root: {
        // For links `colorTextPrimary` means blue link color
        '&[class*=MuiTypography-colorTextPrimary]': {
          color: linkColor,
        },
        // Inherit color by default inside headers
        '[class*=MuiTypography-h] &[class*=MuiTypography-colorTextPrimary]': {
          color: 'inherit',
        },
        // TODO Remove when bootstrap is gone
        '&.MuiTypography-colorTextSecondary:hover': {
          color: gray[1000],
        },
        '&.MuiTypography-colorInherit:hover': {
          color: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        // Make all the button regardless on the border presence
        border: '1px solid transparent',
        padding: spacing('11px', 2),
        borderRadius: spacing(1.5),
        lineHeight: 1.25,
        fontSize: 16,
      },
      // TODO: text button hover and active states according to the guideline
      text: {
        padding: spacing('11px', 2),
      },
      textSecondary: {
        color: lightPurple[800],
        '&:hover': {
          backgroundColor: lightPurple[50],
        },
      },
      label: {
        // Compensates for the additional button height caused by a line height > 1
        // 0.25 additional line height on a 16px font = 4px
        marginTop: -1,
        marginBottom: -3,
      },
      // TODO: outlined and contained buttons shadows and active states according to the guideline
      outlined: {
        backgroundColor: 'white',
        padding: spacing('11px', 4),
        color: gray[500],
        '&&$disabled': {
          backgroundColor: palette.action?.disabledBackground,
        },
        '&:hover': {
          color: text.disabled,
        },
        '&&:hover': {
          backgroundColor: 'white',
        },
        '&&&': {
          borderColor: 'currentColor',
        },
      },
      outlinedPrimary: {
        '&:hover': {
          color: (palette.primary as SimplePaletteColorOptions).dark,
        },
      },
      outlinedSecondary: {
        color: lightPurple[800],
        borderColor: lightPurple[800],
        '&&:hover': {
          color: lightPurple[600],
        },
      },
      // Redefine <Link> color when used as Button's component
      containedPrimary: {
        '&:hover': {
          color: gray[0],
        },
      },
      contained: {
        backgroundColor: gray[100],
        color: gray[500],
      },
      containedSizeLarge: {
        padding: spacing(2, 4),
      },
      sizeSmall: {
        height: spacing(4),
        padding: spacing(0.5, 1.5),
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: spacing(1.5),
        backgroundColor: gray[0],
        '&$disabled': {
          backgroundColor: palette.action?.disabledBackground,
        },
      },
      input: {
        backgroundColor: 'transparent',
        borderRadius: spacing(1.5),
        padding: spacing(1.5),
      },
      notchedOutline: {
        '$root$disabled &': {
          borderColor: gray[200],
        },
        '$root:not($disabled):not($focused):hover &': {
          borderColor: vividPurple[500],
        },
        '$root:focus &': {
          borderColor: purple[500],
        },
        borderRadius: spacing(1.5),
        borderColor: gray[400],
        borderWidth: 2,
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: spacing('11px', 2),
      },
    },
    MuiCardContent: {
      root: {
        // :last-child is required to override a strange default in material-ui
        // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CardContent/CardContent.js#L11
        '&, &:last-child': {
          padding: spacing(3),
        },
        [`@media (min-width: ${breakpointValues.sm}px)`]: {
          '&, &:last-child': {
            padding: spacing(4),
          },
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        [`@media (min-width: ${breakpointValues.sm}px)`]: {
          marginBottom: spacing(2),
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        color: 'inherit',
      },
    },
    MuiInputLabel: {
      outlined: {
        '&&': {
          position: 'static',
          transform: 'none',
          marginBottom: spacing(1),
          fontSize: 14,
          color: 'inherit',
          /**
           * For outlined and filled variants of text inputs Material UI sets the
           * the 'pointer-events' property to 'none' since in their default
           * implementation the label sits inside the input component.
           * Since our use case requires that we move the label to sit above
           * the input component (with the overrides above), we need the pointer
           * events to be enabled. This is useful when, say we click on the label,
           * we want the input to be focused.
           */
          pointerEvents: 'auto',
        },
      },
    },
    MuiInputBase: {
      root: {
        // Use text secondary text color by default in inputs
        color: text.secondary,
        fontSize: 16,
        padding: '1px 0',
      },
      input: {
        height: '1em',
        '&::placeholder': {
          color: gray[400],
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          borderRadius: spacing(2),
          backgroundColor: gray[0],
        },
        borderRadius: spacing(2),
        boxShadow: '0px 4px 8px rgba(91,91,91,0.3)',
        padding: spacing(2, 1.5),
      },
      selectMenu: {
        height: '1em',
        minHeight: '1em',
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: `calc(100% - ${spacing(4)}px)`,
      },
      paperScrollPaper: {
        maxHeight: `calc(100% - ${spacing(4)}px)`,
      },
      paper: {
        margin: spacing(2),
      },
    },
    MuiDialogActions: {
      root: {
        width: '100%',
        maxWidth: breakpointValues.md + spacing(6), // Offset the padding by allowing more width
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: spacing(3),
        [`@media (max-width: ${breakpointValues.sm}px)`]: {
          justifyContent: 'flex-start',
        },
      },
    },
    MuiTabs: {
      indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '&::after': {
          content: '""',
          maxWidth: `calc(100% - ${spacing(3)}px)`,
          width: '100%',
          backgroundColor: gray[1000],
        },
      },
    },
    MuiTab: {
      root: {
        '&&': {
          minWidth: 0,
        },
        fontSize: 16,
        '&$selected': {
          color: gray[1000],
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: spacing(0.5),
        height: spacing(1),
      },
      bar: {
        borderRadius: spacing(0.5),
        position: 'relative',
        height: '100%',
        // Rounded borders in the right corners for the scaled bar
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: spacing(0.8),
          height: spacing(0.8),
          borderRadius: spacing(0.5),
          right: spacing(0.1),
          top: spacing(0.1),
          backgroundColor: purple[200],
        },

        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          // Only visible in indeterminate variant
          borderTopLeftRadius: spacing(0.5),
          borderBottomLeftRadius: spacing(0.5),
        },
      },
      // Reset default progress background
      colorPrimary: {
        backgroundColor: gray[200],
      },
    },
    MuiBreadcrumbs: {
      separator: {
        fontSize: spacing(1.5),
        marginLeft: spacing(0.5),
        marginRight: spacing(0.5),
        display: 'inline',
      },
      li: {
        display: 'inline',
      },
      ol: {
        display: 'inline',
      },
    },
    MuiIconButton: {
      root: {
        padding: spacing(1),
        borderRadius: spacing(1.5),
      },
    },
    MuiTouchRipple: {
      child: {
        borderRadius: 'inherit',
      },
    },
    MuiToolbar: {
      regular: {
        paddingLeft: spacing(1),
      },
    },
    MuiTableFooter: {
      root: {
        '& [class*="MuiTableCell-root"]': {
          border: 'none', // remove the extra border at the bottom of the table
        },
      },
    },
    MuiTablePagination: {
      select: {
        boxShadow: 'none',
      },
    },
  },

  props: {
    MuiLink: {
      color: 'textPrimary',
    },
    MuiOutlinedInput: {
      // Disable notch in fields outline
      notched: false,
    },
    MuiInputLabel: {
      // Disable placeholder hiding without focus
      shrink: true,
    },
    MuiTextField: {
      margin: 'normal',
      // Use outlined fields by default
      variant: 'outlined',
      FormHelperTextProps: {
        // Disable helper text margins
        variant: 'standard',
      },
    },
    MuiFormControl: {
      margin: 'normal',
    },
    MuiLinearProgress: {
      variant: 'determinate',
    },
    MuiBreadcrumbs: {
      separator: <ArrowRight height="0.9em" />,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
};

export const staticFontsTheme = createTheme(themeOptions);

export const theme = responsiveFontSizes(staticFontsTheme);
