import { gql } from '@apollo/client';

import { APPLICATION_FRAGMENT } from 'shared/src/trials/application-fragment';
import { APPLICATION_SITE_INFO_FRAGMENT } from 'shared/src/trials/application-site-info-record-fragment';
import { mutationFactory, queryFactory } from 'shared/src/util/query-factory';

import { MATS_LOCATION_FRAGMENT } from 'mats/src/fragments/location/location-fragment';
import { PATIENT_FRAGMENT } from 'mats/src/fragments/patient/patient-fragment';
import { TRIAL_SITE_FRAGMENT } from 'mats/src/fragments/trial-site/trial-site-fragment';

import {
  PatientApplications,
  PatientBasicInfo,
  UpdatePatient,
  UpdatePatientVariables,
} from './types/queries.generated';

const PATIENT_BASIC_INFO_QUERY = gql`
  query PatientBasicInfo {
    patient {
      ...PatientRecordHmio
      localeWithInfo {
        code
      }
    }
  }

  ${PATIENT_FRAGMENT}
`;

/** Patient with basic profile information */
export const usePatientBasicInfoQuery = queryFactory<PatientBasicInfo>(PATIENT_BASIC_INFO_QUERY);

const PATIENT_APPLICATIONS_QUERY = gql`
  query PatientApplications {
    patient {
      ...PatientRecordHmio
      conditionsWithMatchingInfo(useLongDistanceMatching: true) {
        id
        matchingInfo {
          id
          matchingTrials {
            id
            trialSites(filter: { filterToEligibleSites: true, useLongDistanceMatching: true }) {
              id
            }
          }
          matchingTrialsWithSkips {
            id
            trialSites(filter: { filterToEligibleSites: true, useLongDistanceMatching: true }) {
              id
            }
          }
        }
      }
      applications {
        ...ApplicationRecord
        trial {
          id
        }
        applicationSites {
          ...ApplicationSiteInfoRecord
          creationSource
          trialSite {
            id
            ...TrialSiteRecordHmio
            location {
              ...MatsLocation
              locales {
                code
                displayLanguage
                displayLanguageLocal
              }
            }
            primaryContactDetails {
              email
              phone
            }
          }
        }
      }
    }
  }

  ${PATIENT_FRAGMENT}
  ${APPLICATION_FRAGMENT}
  ${APPLICATION_SITE_INFO_FRAGMENT}
  ${TRIAL_SITE_FRAGMENT}
  ${MATS_LOCATION_FRAGMENT}
`;

// TODO: Filter eligible sites only in matching trials

/** Patient with all existing applications and matching trial site info */
export const usePatientApplicationsQuery = queryFactory<PatientApplications>(
  PATIENT_APPLICATIONS_QUERY
);

const UPDATE_PATIENT = gql`
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      id
      firstName
      lastName
      email
      dob
      sex
      suburb
      country
      state
      phoneNumber
      lat
      lng
      termsVersion
      suburbLastConfirmedAt
      phoneLastConfirmedAt
      localeWithInfo {
        code
      }
      applications {
        id
      }
      conditions {
        id
        name
      }
      contactPreferences {
        schedule
        channels
      }
      conditionsWithMatchingInfo(useLongDistanceMatching: true) {
        id
        condition {
          id
          name
        }
        matchingInfo {
          id
          matchingTrials {
            id
          }
          matchingTrialsWithSkips {
            id
          }
          notMatchingTrialsWithSkips {
            id
          }
          unansweredTrials {
            id
          }
          nextMandatoryAttributeDefinition {
            id
          }
          nextMissingAttributeDefinition {
            id
          }
          nextSkippedAttributeDefinition {
            id
          }
        }
      }
    }
  }
`;

export const useUpdatePatient = mutationFactory<UpdatePatient, UpdatePatientVariables>(
  UPDATE_PATIENT
);
