export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnalyticsJobID: AnalyticsJobID;
  ApiIntegrationId: ApiIntegrationId;
  ApplicationID: ApplicationID;
  ApplicationSiteEventId: ApplicationSiteEventID;
  ApplicationSiteID: ApplicationSiteID;
  ApplicationSiteSyncChangesetId: ApplicationSiteSyncChangesetId;
  ApplicationSiteSyncEventId: ApplicationSiteSyncEventId;
  AttributeDefinitionID: AttributeDefinitionID;
  /** A composite key formatted as PatientID:AttributeDefinitionID */
  AttributeValueID: AttributeValueID;
  CampaignId: CampaignId;
  CampaignPatientEmrRequestRecordId: CampaignPatientEmrRequestRecordId;
  CampaignPatientID: CampaignPatientID;
  CommitmentId: CommitmentId;
  ConditionID: ConditionID;
  CountryCode: CountryCode;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: string;
  DerivedAttributeRuleID: DerivedAttributeRuleID;
  DerivedConditionRuleID: DerivedConditionRuleID;
  DocumentID: DocumentID;
  EmailTemplateID: EmailTemplateID;
  GooglePlaceId: GooglePlaceId;
  IndicationID: IndicationID;
  InsightsOrganisationID: InsightsOrganisationID;
  InsightsUserID: InsightsUserID;
  InvestigatorID: InvestigatorID;
  LocationID: LocationID;
  Long: number;
  OrganisationID: OrganisationID;
  PatientID: PatientID;
  PreappId: PreappId;
  SponsorID: SponsorID;
  StateCode: StateCode;
  TrialID: TrialID;
  TrialOnboardingRequestId: TrialOnboardingRequestId;
  TrialSiteID: TrialSiteID;
  /** Federation scalar type used to represent any external entities passed to _entities query. */
  _Any: any;
  /** Federation type representing set of fields */
  _FieldSet: any;
};

export type AddApplicationSiteContactEventInput = {
  applicationSiteId: Scalars['ApplicationSiteID'];
  contactChannel: ContactChannel;
};

export type AddConditionAliasInput = {
  alias: Scalars['String'];
};

export type AddDerivedConditionActionInput = {
  conditionIdTo: Scalars['ConditionID'];
  hasCondition: Scalars['Boolean'];
  valueFrom: ValueOrSkipInput;
};

export type AddInvestigatorInput = {
  id: Scalars['InvestigatorID'];
};

export type AddOptionItemActionInput = {
  id: Scalars['String'];
  label: Scalars['String'];
};

export type AddOutgoingDerivedAttributesActionInput = {
  attributeDefinitionIdTo: Scalars['AttributeDefinitionID'];
  valueFrom: ValueOrSkipInput;
  valueTo: ValueOrSkipInput;
};

export type AddPatientContactedEventInput = {
  campaignId: Scalars['CampaignId'];
  contactChannel: ContactChannel;
  patientId: Scalars['PatientID'];
};

export type AddTrialSiteInput = {
  longDistanceReason?: InputMaybe<Scalars['String']>;
  trialSiteId: Scalars['TrialSiteID'];
};

export type AgeRequirement = {
  __typename: 'AgeRequirement';
  operator: Operator;
  value: Scalars['Float'];
};

export type AgentUpdateApplicationActionInput = {
  addTrialSite?: InputMaybe<AddTrialSiteInput>;
  id: Scalars['ApplicationID'];
  patientId: Scalars['PatientID'];
  setIsValidated?: InputMaybe<SetBooleanInput>;
  setLabels?: InputMaybe<SetLabelsInput>;
};

export type AgentUpdateApplicationSiteActionInput = {
  makeApiIntegrationCall?: InputMaybe<SetBooleanInput>;
  setFollowUpDate?: InputMaybe<SetOptionalDateInput>;
  setIsVisibleToInvestigator?: InputMaybe<SetBooleanInput>;
  setStatus?: InputMaybe<SetApplicationStatusInput>;
};

export type AgentUpdatePatientInput = {
  setEmail?: InputMaybe<SetStringInput>;
};

export type AgentUser = {
  __typename: 'AgentUser';
  email: Scalars['String'];
};

export type AnalyticsConfiguration = {
  __typename: 'AnalyticsConfiguration';
  campaignNotes: Maybe<Scalars['String']>;
  country: Country;
  estimatedConsentToShareProb: Maybe<Scalars['Float']>;
  estimatedPrescreenCompletedProb: Maybe<Scalars['Float']>;
  estimatedPrescreenPassedProb: Maybe<Scalars['Float']>;
  estimatedReferralsOutsideDatabase: Maybe<Scalars['Int']>;
  estimatedTrialConditionMatchProb: Maybe<Scalars['Float']>;
  evaluationDistances: Maybe<Array<Scalars['Int']>>;
  isFeaso: Scalars['Boolean'];
};

export type AnalyticsJob = {
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob;
};


export type AnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type AnalyticsJobConfigurationInput = {
  countMatchesAnalyticsJobConfiguration?: InputMaybe<CountMatchesAnalyticsJobConfigurationInput>;
  countMatchesPerSiteAnalyticsJobConfiguration?: InputMaybe<CountMatchesPerSiteAnalyticsJobConfigurationInput>;
  derivedConditionsAnalyticsJobConfiguration?: InputMaybe<Scalars['Boolean']>;
  matchProbabilityAnalyticsJobConfiguration?: InputMaybe<MatchProbabilityAnalyticsJobConfigurationInput>;
  patientEvaluationAnalyticsJobConfiguration?: InputMaybe<PatientEvaluationAnalyticsJobConfigurationInput>;
};

export type AnalyticsJobPagedResult = PagedResult & {
  __typename: 'AnalyticsJobPagedResult';
  analyticsJobs: Array<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum AnalyticsJobStatus {
  ARCHIVED = 'ARCHIVED',
  DONE = 'DONE',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING'
}

export type AnalyticsJobsSearchInput = {
  analyticsJobStatus?: InputMaybe<SearchAnalyticsJobsAnalyticsJobStatusInput>;
};

export type AndTrialRule = TrialRuleSerialisable & {
  __typename: 'AndTrialRule';
  childRules: Array<AndTrialRule | BooleanTrialRule | FloatTrialRule | HasTakenMedicationGroupTrialRule | HasTakenMedicationTrialRule | NotTrialRule | OrTrialRule | RadioTrialRule | RootAndTrialRule | ScreeningBooleanTrialRule>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
};

export type AndTrialRuleFlat = TrialRuleFlat & {
  __typename: 'AndTrialRuleFlat';
  childRules: Array<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
};

export type AndTrialRuleInput = {
  childRules: Array<TrialRuleInput>;
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
};

export enum ApiErrorCode {
  AUTH_FORBIDDEN = 'AUTH_FORBIDDEN',
  AUTH_MISSING = 'AUTH_MISSING',
  LOGIN_ATTEMPT_LIMIT_EXCEEDED = 'LOGIN_ATTEMPT_LIMIT_EXCEEDED',
  LOGIN_CODE_EXPIRED = 'LOGIN_CODE_EXPIRED',
  OTHER = 'OTHER'
}

/**
 * Either a Patient or a deidentified view of that patient,
 * depending on whether the current investigator has permission to see that patient's personal details
 */
export type ApplicantResult = DeidentifiedProfile | Patient;

export type Application = {
  __typename: 'Application';
  applicant: ApplicantResult;
  applicationSites: Array<ApplicationSite>;
  appliedAt: Scalars['String'];
  /** Returns attribute values relevant to this application. (i.e. values for attributes encoded in the trial's rules) */
  attributeValues: Array<BooleanAttributeValue | FloatAttributeValue | MedicationAttributeValue | MedicationGroupAttributeValue | RadioAttributeValue | ScreeningBooleanAttributeValue>;
  /** Temporary copy of attributeValues with patient access permissions */
  attributeValuesPatient: Array<BooleanAttributeValue | FloatAttributeValue | MedicationAttributeValue | MedicationGroupAttributeValue | RadioAttributeValue | ScreeningBooleanAttributeValue>;
  id: Scalars['ApplicationID'];
  isArchived: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  labels: Array<Scalars['String']>;
  trial: Trial;
  trialId: Scalars['TrialID'];
  updatedAt: Scalars['DateTime'];
};

export type ApplicationCountFilterInput = {
  statusScreening?: InputMaybe<ApplicationStatusScreening>;
};

export enum ApplicationMode {
  BOOKING_LINK = 'BOOKING_LINK',
  PATIENT_DIRECT_CONTACT = 'PATIENT_DIRECT_CONTACT',
  SCREENING_FORM_LINK = 'SCREENING_FORM_LINK',
  TSP_REVIEW = 'TSP_REVIEW'
}

export enum ApplicationModeReason {
  OTHER = 'OTHER',
  OUTREACH_EXHAUSTED = 'OUTREACH_EXHAUSTED',
  REQUESTED_BY_SITE = 'REQUESTED_BY_SITE',
  SITE_NOT_INTERESTED = 'SITE_NOT_INTERESTED'
}

export type ApplicationSite = {
  __typename: 'ApplicationSite';
  application: Application;
  /** @deprecated Use applicationId */
  applicationID: Scalars['ApplicationID'];
  applicationId: Scalars['ApplicationID'];
  applicationStatusScreening: ApplicationStatusScreening;
  applicationStatusScreeningUpdatedAt: Scalars['DateTime'];
  appliedAt: Scalars['DateTime'];
  creationSource: ApplicationSiteCreationSource;
  dateOfEnrollment: Maybe<Scalars['DateTime']>;
  dateOfScheduledVisit: Maybe<Scalars['DateTime']>;
  dateOfScreeningConsentSigned: Maybe<Scalars['DateTime']>;
  events: Maybe<Array<ApplicationSiteEvent>>;
  followUpDate: Maybe<Scalars['DateTime']>;
  hasAttendedAppointment: Scalars['Boolean'];
  id: Scalars['ApplicationSiteID'];
  /** @deprecated Use creationSource */
  isAutoApplied: Scalars['Boolean'];
  isVisibleToInvestigators: Scalars['Boolean'];
  lastContactAttempt: Maybe<ApplicationSiteEvent>;
  longDistanceReason: Maybe<Scalars['String']>;
  note: Maybe<Scalars['String']>;
  noteUpdatedAt: Maybe<Scalars['DateTime']>;
  noteUpdatedBy: Maybe<Investigator>;
  rejectReason: Maybe<ApplicationSiteRejectReason>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  screeningFormLinkResolved: Maybe<Scalars['String']>;
  sentoffAt: Maybe<Scalars['String']>;
  sitePatientExternalId: Maybe<Scalars['String']>;
  sponsorRecordExternalId: Maybe<Scalars['String']>;
  trialSite: TrialSite;
  updatedAt: Scalars['DateTime'];
};

export type ApplicationSiteCount = {
  __typename: 'ApplicationSiteCount';
  count: Scalars['Int'];
  statusScreening: ApplicationStatusScreening;
  trialId: Scalars['TrialID'];
};

export enum ApplicationSiteCreationSource {
  CALL_CENTER = 'CALL_CENTER',
  INTERNAL = 'INTERNAL',
  INVESTIGATOR = 'INVESTIGATOR',
  PATIENT_AUTO = 'PATIENT_AUTO',
  PATIENT_MANUAL = 'PATIENT_MANUAL'
}

export type ApplicationSiteEvent = {
  __typename: 'ApplicationSiteEvent';
  applicationSiteId: Scalars['ApplicationSiteID'];
  authorName: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  eventDetails: Scalars['String'];
  eventType: Scalars['String'];
  id: Scalars['ApplicationSiteEventId'];
};

export type ApplicationSitePagedResult = PagedResult & {
  __typename: 'ApplicationSitePagedResult';
  applicationSites: Array<ApplicationSite>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ApplicationSiteRejectReason = {
  __typename: 'ApplicationSiteRejectReason';
  additionalFeedback: Scalars['String'];
  createdAt: Scalars['DateTime'];
  rejectStatus: ApplicationSiteRejectReasonStatus;
};

export enum ApplicationSiteRejectReasonStatus {
  COHORT_AT_CAPACITY = 'COHORT_AT_CAPACITY',
  DECLINED_PARTICIPATION = 'DECLINED_PARTICIPATION',
  DECLINED_TO_BE_CONTACTED = 'DECLINED_TO_BE_CONTACTED',
  EXISTING_PATIENT = 'EXISTING_PATIENT',
  FAILED_EXCLUSION = 'FAILED_EXCLUSION',
  FAILED_INCLUSION = 'FAILED_INCLUSION',
  NOT_ENOUGH_INFORMATION = 'NOT_ENOUGH_INFORMATION',
  NO_SHOW = 'NO_SHOW',
  OTHER = 'OTHER',
  PARTICIPATING_IN_ANOTHER_STUDY = 'PARTICIPATING_IN_ANOTHER_STUDY',
  RECRUITMENT_CLOSED = 'RECRUITMENT_CLOSED',
  SCREENING_FOR_ANOTHER_STUDY = 'SCREENING_FOR_ANOTHER_STUDY',
  TOO_FAR_AWAY = 'TOO_FAR_AWAY',
  UNABLE_TO_ATTEND_APPOINTMENTS = 'UNABLE_TO_ATTEND_APPOINTMENTS',
  UNABLE_TO_CONTACT = 'UNABLE_TO_CONTACT',
  UNABLE_TO_TRAVEL = 'UNABLE_TO_TRAVEL'
}

export type ApplicationSiteSyncChangeset = {
  __typename: 'ApplicationSiteSyncChangeset';
  appliedAt: Maybe<Scalars['DateTime']>;
  changeSet: Maybe<Scalars['String']>;
  existingApplicationSite: Maybe<ApplicationSite>;
  id: Scalars['ApplicationSiteSyncChangesetId'];
  originData: Maybe<Scalars['String']>;
  patient: Maybe<Patient>;
  syncOutcome: Maybe<ApplicationSiteSyncOutcome>;
};

export type ApplicationSiteSyncChangesetFilterInput = {
  applicationSiteSyncEventId?: InputMaybe<Scalars['ApplicationSiteSyncEventId']>;
  existingApplicationSiteId?: InputMaybe<Scalars['ApplicationSiteID']>;
  hasBeenApplied?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['PatientID']>;
  syncOutcome?: InputMaybe<ApplicationSiteSyncOutcome>;
};

export type ApplicationSiteSyncChangesetPagedResult = PagedResult & {
  __typename: 'ApplicationSiteSyncChangesetPagedResult';
  applicationSiteSyncChangesets: Array<ApplicationSiteSyncChangeset>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ApplicationSiteSyncEvent = {
  __typename: 'ApplicationSiteSyncEvent';
  id: Scalars['ApplicationSiteSyncEventId'];
  origin: Maybe<Scalars['String']>;
  syncFinishedAt: Maybe<Scalars['DateTime']>;
  syncStartedAt: Maybe<Scalars['DateTime']>;
  syncStatus: SyncStatus;
  syncType: Scalars['String'];
};

export type ApplicationSiteSyncEventPagedResult = PagedResult & {
  __typename: 'ApplicationSiteSyncEventPagedResult';
  applicationSiteSyncEvents: Array<ApplicationSiteSyncEvent>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum ApplicationSiteSyncOutcome {
  FAILED_OTHER = 'FAILED_OTHER',
  FAILED_PATIENT_NOT_FOUND = 'FAILED_PATIENT_NOT_FOUND',
  FAILED_TRIAL_NOT_FOUND = 'FAILED_TRIAL_NOT_FOUND',
  FAILED_TRIAL_SITE_NOT_FOUND = 'FAILED_TRIAL_SITE_NOT_FOUND',
  NEW_APPLICATION_SITE = 'NEW_APPLICATION_SITE',
  NO_UPDATES_APPLICATION_SITE_DISCONTINUED = 'NO_UPDATES_APPLICATION_SITE_DISCONTINUED',
  NO_UPDATES_REQUIRED = 'NO_UPDATES_REQUIRED',
  UPDATE_APPLICATION_SITE = 'UPDATE_APPLICATION_SITE'
}

export enum ApplicationStatusScreening {
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  CONTACT_LIST = 'CONTACT_LIST',
  DISCONTINUED = 'DISCONTINUED',
  ENROLLED = 'ENROLLED',
  FIRST_VISIT_BOOKED = 'FIRST_VISIT_BOOKED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  POST_SCREEN_FAILURE = 'POST_SCREEN_FAILURE',
  PRE_SCREENED = 'PRE_SCREENED',
  PRE_SCREEN_FAILURE = 'PRE_SCREEN_FAILURE',
  SCREENING = 'SCREENING',
  SCREEN_FAILURE = 'SCREEN_FAILURE'
}

export type AttributeDefinition = {
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type AttributeDefinitionUsage = {
  __typename: 'AttributeDefinitionUsage';
  conditionIds: Array<Scalars['ConditionID']>;
  derivedAttributesAttributeDefinitionIds: Array<Scalars['AttributeDefinitionID']>;
  numberOfAnswers: Scalars['Long'];
  requiredAttributesAttributeDefinitionIds: Array<Scalars['AttributeDefinitionID']>;
  trialIds: Array<Scalars['TrialID']>;
};

export type AttributeValue = {
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
};

export type AttributeValueInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  booleanAttributeValue?: InputMaybe<Scalars['Boolean']>;
  floatAttributeValue?: InputMaybe<Scalars['Float']>;
  isSkipped?: InputMaybe<Scalars['Boolean']>;
  medicationAttributeValue?: InputMaybe<MedicationValueInput>;
  medicationGroupAttributeValue?: InputMaybe<MedicationGroupValueInput>;
  radioAttributeValue?: InputMaybe<Scalars['String']>;
};

export type AuthStatus = {
  __typename: 'AuthStatus';
  signinStatus: Scalars['String'];
};

export type AutocompleteResult = {
  __typename: 'AutocompleteResult';
  description: Scalars['String'];
  mainText: Scalars['String'];
  placeId: Scalars['GooglePlaceId'];
  secondaryText: Scalars['String'];
  types: Array<Scalars['String']>;
};

export enum BdStatus {
  Closed = 'Closed',
  InDiscussion = 'InDiscussion',
  Live = 'Live',
  NoMsa = 'NoMsa',
  Opportunity = 'Opportunity',
  Startup = 'Startup'
}

export type BooleanAttributeDefinition = AttributeDefinition & {
  __typename: 'BooleanAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  question: Scalars['String'];
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type BooleanAttributeValue = AttributeValue & {
  __typename: 'BooleanAttributeValue';
  attributeDefinition: BooleanAttributeDefinition;
  booleanValue: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
};


export type BooleanAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type BooleanInput = {
  value?: InputMaybe<Scalars['Boolean']>;
};

export type BooleanTrialRule = TrialRuleSerialisable & {
  __typename: 'BooleanTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  skippedResult: Scalars['Boolean'];
};

export type BooleanTrialRuleFlat = TrialRuleFlat & {
  __typename: 'BooleanTrialRuleFlat';
  attributeDefinition: BooleanAttributeDefinition;
  description: Maybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  skippedResult: Scalars['Boolean'];
};

export type BooleanTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  skippedResult: Scalars['Boolean'];
};

export type BooleanValue = ValueOrSkip & {
  __typename: 'BooleanValue';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  booleanValue: Maybe<Scalars['Boolean']>;
};

export type CalendarBookingEmailInput = {
  calendarBookingUrl: Scalars['String'];
};

export type Campaign = {
  __typename: 'Campaign';
  campaignPatient: Maybe<CampaignPatient>;
  campaignTrialConfigs: Array<CampaignTrialConfig>;
  campaignTrialSiteConfigs: Array<CampaignTrialSiteConfig>;
  fixedQuestions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  id: Scalars['CampaignId'];
  name: Scalars['String'];
  trialSites: Array<TrialSite>;
  trials: Array<Trial>;
  type: CampaignType;
};


export type CampaigncampaignPatientArgs = {
  patientId: Scalars['PatientID'];
};

export type CampaignCallListPatient = {
  __typename: 'CampaignCallListPatient';
  campaign: Campaign;
  campaignPatient: Maybe<CampaignPatient>;
  patient: Maybe<Patient>;
  predictedPatientValue: Maybe<Scalars['Float']>;
};

export type CampaignCallListPatientInput = {
  campaignId: Scalars['Long'];
  patientId: Scalars['Long'];
  patientRank: Scalars['Float'];
  predictedPatientValue: Scalars['Float'];
};

export type CampaignCallListPatientPagedResult = PagedResult & {
  __typename: 'CampaignCallListPatientPagedResult';
  campaignCallListPatients: Array<CampaignCallListPatient>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type CampaignPatient = {
  __typename: 'CampaignPatient';
  assigneeEmail: Maybe<Scalars['String']>;
  campaign: Campaign;
  contactEvents: Array<PatientContactedEvent>;
  followUpDateTime: Maybe<Scalars['DateTime']>;
  id: Scalars['CampaignPatientID'];
  lastContactEventTime: Maybe<Scalars['DateTime']>;
  notes: Maybe<Scalars['String']>;
  outcome: Maybe<CampaignPatientOutcome>;
  patient: Patient;
  status: CampaignPatientStatus;
};

export type CampaignPatientCreateInput = {
  assigneeEmail: Scalars['String'];
  campaignId: Scalars['CampaignId'];
  notes?: InputMaybe<Scalars['String']>;
  patientId: Scalars['PatientID'];
};

export type CampaignPatientEmrRequestRecord = {
  __typename: 'CampaignPatientEmrRequestRecord';
  campaignPatient: CampaignPatient;
  completedAt: Maybe<Scalars['DateTime']>;
  consentObtainedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  externalId: Maybe<Scalars['String']>;
  id: Scalars['CampaignPatientEmrRequestRecordId'];
  identityConfirmedAt: Maybe<Scalars['DateTime']>;
  status: EmrRequestStatus;
  type: EmrRequestType;
};

export type CampaignPatientFilterInput = {
  assigneeEmail?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['CampaignId']>;
  patientId?: InputMaybe<Scalars['PatientID']>;
  status?: InputMaybe<CampaignPatientStatus>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type CampaignPatientFilterOptions = {
  __typename: 'CampaignPatientFilterOptions';
  timezones: Array<Maybe<Scalars['String']>>;
};

export enum CampaignPatientOutcome {
  DO_NOT_CONTACT = 'DO_NOT_CONTACT',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NO_MATCH = 'NO_MATCH',
  OTHER = 'OTHER',
  PHONE_HANDOFF = 'PHONE_HANDOFF',
  SCHEDULED_IN_CTMS = 'SCHEDULED_IN_CTMS',
  TRIAL_SITE_PORTAL = 'TRIAL_SITE_PORTAL',
  UNABLE_TO_CONTACT = 'UNABLE_TO_CONTACT',
  WRONG_PERSON = 'WRONG_PERSON'
}

export type CampaignPatientPagedResult = PagedResult & {
  __typename: 'CampaignPatientPagedResult';
  campaignPatients: Array<CampaignPatient>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum CampaignPatientStatus {
  EMR_REVIEW = 'EMR_REVIEW',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  SUCCESSFUL = 'SUCCESSFUL'
}

export type CampaignPatientUpdateInput = {
  campaignPatientId: Scalars['CampaignPatientID'];
  setAssigneeEmail?: InputMaybe<SetOptionalStringInput>;
  setFollowUpDate?: InputMaybe<SetOptionalDateInput>;
  setNotes?: InputMaybe<SetOptionalStringInput>;
  setOutcome?: InputMaybe<SetCampaignPatientOutcomeInput>;
  setStatus?: InputMaybe<SetCampaignPatientStatusInput>;
};

export type CampaignPatientsCreateInput = {
  assigneeEmail: Scalars['String'];
  campaignId: Scalars['CampaignId'];
  patientIds: Array<Scalars['PatientID']>;
};

export type CampaignTrialConfig = {
  __typename: 'CampaignTrialConfig';
  prioritisationConstant: Maybe<Scalars['Long']>;
  trial: Trial;
  trialId: Scalars['TrialID'];
};

export type CampaignTrialConfigInput = {
  prioritisationConstant?: InputMaybe<Scalars['Long']>;
  trialId: Scalars['TrialID'];
};

export type CampaignTrialSiteConfig = {
  __typename: 'CampaignTrialSiteConfig';
  trialSite: TrialSite;
  trialSiteId: Scalars['TrialSiteID'];
};

export enum CampaignType {
  INBOUND_CALLING = 'INBOUND_CALLING',
  OUTBOUND_CALLING = 'OUTBOUND_CALLING',
  PROSPECTIVE_CALLING = 'PROSPECTIVE_CALLING'
}

export type CancelTrialSiteInput = {
  trialSiteId: Scalars['TrialSiteID'];
};

export type Commitment = {
  __typename: 'Commitment';
  displayText: Scalars['String'];
  id: Scalars['CommitmentId'];
  title: Scalars['String'];
};

export enum CompensationOption {
  HEALTH_ASSESSMENT = 'HEALTH_ASSESSMENT',
  MONETARY = 'MONETARY',
  NONE = 'NONE',
  OTHER = 'OTHER',
  TRAVEL_REIMBURSEMENT = 'TRAVEL_REIMBURSEMENT',
  UNKNOWN = 'UNKNOWN'
}

export type Condition = {
  __typename: 'Condition';
  aliases: Array<Scalars['String']>;
  derivedConditionRules: Array<DerivedConditionRule>;
  id: Scalars['ConditionID'];
  indication: Indication;
  mandatoryAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  name: Scalars['String'];
};

export type ConditionWithMatchingInfo = {
  __typename: 'ConditionWithMatchingInfo';
  condition: Condition;
  id: Scalars['String'];
  matchingInfo: MatchingInfo;
};

export enum ContactChannel {
  CALL_ANSWERED = 'CALL_ANSWERED',
  CALL_INVALID_NUMBER = 'CALL_INVALID_NUMBER',
  CALL_LEFT_VOICEMAIL = 'CALL_LEFT_VOICEMAIL',
  CALL_NO_ANSWER = 'CALL_NO_ANSWER',
  EMAIL = 'EMAIL',
  INCOMING_CALL_ANSWERED = 'INCOMING_CALL_ANSWERED',
  SMS = 'SMS'
}

export type ContactInfoEmailInput = {
  emailText: Scalars['String'];
};

export type ContactPreferences = {
  __typename: 'ContactPreferences';
  channels: Maybe<Array<ContactPreferencesChannel>>;
  schedule: Maybe<Scalars['String']>;
  structuredSchedule: Maybe<ContactPreferencesStructureSchedule>;
};

export enum ContactPreferencesChannel {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export type ContactPreferencesStructureSchedule = {
  __typename: 'ContactPreferencesStructureSchedule';
  source: ContactPreferencesStructureScheduleSource;
  weeklySchedule: ContactPreferencesWeeklySchedule;
};

export enum ContactPreferencesStructureScheduleSource {
  OPENAI = 'OPENAI',
  USER = 'USER'
}

export type ContactPreferencesTime = {
  __typename: 'ContactPreferencesTime';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type ContactPreferencesTimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type ContactPreferencesTimeInterval = {
  __typename: 'ContactPreferencesTimeInterval';
  end: ContactPreferencesTime;
  start: ContactPreferencesTime;
};

export type ContactPreferencesTimeIntervalInput = {
  end: ContactPreferencesTimeInput;
  start: ContactPreferencesTimeInput;
};

export type ContactPreferencesWeeklySchedule = {
  __typename: 'ContactPreferencesWeeklySchedule';
  friday: Maybe<ContactPreferencesTimeInterval>;
  monday: Maybe<ContactPreferencesTimeInterval>;
  saturday: Maybe<ContactPreferencesTimeInterval>;
  sunday: Maybe<ContactPreferencesTimeInterval>;
  thursday: Maybe<ContactPreferencesTimeInterval>;
  tuesday: Maybe<ContactPreferencesTimeInterval>;
  wednesday: Maybe<ContactPreferencesTimeInterval>;
};

export type ContactPreferencesWeeklyScheduleInput = {
  friday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  monday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  saturday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  sunday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  thursday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  tuesday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
  wednesday?: InputMaybe<ContactPreferencesTimeIntervalInput>;
};

export type ConversionsEventInput = {
  eventAttributionIdentifiers?: InputMaybe<EventAttributionIdentifiersInput>;
  eventId: Scalars['String'];
  eventName: EventName;
  eventsourceUrl: Scalars['String'];
  metaIdentifiers?: InputMaybe<MetaIdentifiersInput>;
  userAgent: Scalars['String'];
};

export type CountMatchesAnalyticsJob = AnalyticsJob & {
  __typename: 'CountMatchesAnalyticsJob';
  configuration: CountMatchesAnalyticsJobConfiguration;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  result: Maybe<CountMatchesAnalyticsJobResult>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: CountMatchesAnalyticsJob;
};


export type CountMatchesAnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type CountMatchesAnalyticsJobConfiguration = {
  __typename: 'CountMatchesAnalyticsJobConfiguration';
  trialId: Scalars['TrialID'];
};

export type CountMatchesAnalyticsJobConfigurationInput = {
  trialId: Scalars['TrialID'];
};

export type CountMatchesAnalyticsJobResult = {
  __typename: 'CountMatchesAnalyticsJobResult';
  matchingResult: MatchingResult;
};

export type CountMatchesPerSiteAnalyticsJob = AnalyticsJob & {
  __typename: 'CountMatchesPerSiteAnalyticsJob';
  configuration: CountMatchesPerSiteAnalyticsJobConfiguration;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  result: Maybe<CountMatchesPerSiteAnalyticsJobResult>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: CountMatchesPerSiteAnalyticsJob;
};


export type CountMatchesPerSiteAnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type CountMatchesPerSiteAnalyticsJobConfiguration = {
  __typename: 'CountMatchesPerSiteAnalyticsJobConfiguration';
  maxDistanceFromTrialSite: Maybe<Scalars['Int']>;
  sendEvents: Maybe<Scalars['Boolean']>;
  trialId: Scalars['TrialID'];
};

export type CountMatchesPerSiteAnalyticsJobConfigurationInput = {
  maxDistanceFromTrialSite?: InputMaybe<Scalars['Int']>;
  sendEvents?: InputMaybe<Scalars['Boolean']>;
  trialId: Scalars['TrialID'];
};

export type CountMatchesPerSiteAnalyticsJobResult = {
  __typename: 'CountMatchesPerSiteAnalyticsJobResult';
  trialMatchingResult: MatchingResult;
  trialSites: Array<TrialSiteAndMatchingResult>;
  trialSitesMatchingConditionTotal: MatchingResult;
  trialSitesTotal: MatchingResult;
};

export type Country = {
  __typename: 'Country';
  code: Scalars['CountryCode'];
  name: Scalars['String'];
};

export type CreateAnalyticsJobsInput = {
  organisationPatientEvaluationAnalyticsJob?: InputMaybe<OrganisationPatientEvaluationAnalyticsJobInput>;
};

export type CreateAttributeDefinitionInput = {
  boolean?: InputMaybe<CreateBooleanAttributeDefinitionInput>;
  float?: InputMaybe<CreateFloatAttributeDefinitionInput>;
  medication?: InputMaybe<CreateMedicationAttributeDefinitionInput>;
  medicationGroup?: InputMaybe<CreateMedicationGroupAttributeDefinitionInput>;
  radio?: InputMaybe<CreateRadioAttributeDefinitionInput>;
  screening?: InputMaybe<CreateBooleanAttributeDefinitionInput>;
};

export type CreateBooleanAttributeDefinitionInput = {
  name: Scalars['String'];
};

export type CreateFloatAttributeDefinitionInput = {
  name: Scalars['String'];
};

export type CreateInsightsOrganisationInput = {
  dashboardConfiguration?: InputMaybe<DashboardConfigurationInput>;
  name: Scalars['String'];
  trialSiteIds?: InputMaybe<Array<Scalars['TrialSiteID']>>;
};

export type CreateInsightsUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['InsightsOrganisationID'];
  termsVersions?: InputMaybe<Array<TermsVersionInput>>;
};

export type CreateMedicationAttributeDefinitionInput = {
  name: Scalars['String'];
};

export type CreateMedicationGroupAttributeDefinitionInput = {
  name: Scalars['String'];
};

export type CreateOrganisationActionInput = {
  name: Scalars['String'];
};

export type CreateRadioAttributeDefinitionInput = {
  name: Scalars['String'];
};

export type DashboardConfiguration = {
  __typename: 'DashboardConfiguration';
  forecastingConditionsDashboardId: Scalars['Int'];
  homePageDashboardId: Scalars['Int'];
  insightsOrganisationPortfolioDashboardId: Scalars['Int'];
  timezoneId: Scalars['String'];
  trialOptimisationDashboardId: Scalars['Int'];
  trialPerformanceDashboardId: Scalars['Int'];
  trialSiteInsightsDashboardId: Scalars['Int'];
};

export type DashboardConfigurationInput = {
  forecastingConditionsDashboardId: Scalars['Int'];
  homePageDashboardId: Scalars['Int'];
  insightsOrganisationPortfolioDashboardId: Scalars['Int'];
  timezoneId: Scalars['String'];
  trialOptimisationDashboardId: Scalars['Int'];
  trialPerformanceDashboardId: Scalars['Int'];
  trialSiteInsightsDashboardId: Scalars['Int'];
};

export type DeidentifiedPatientDetails = {
  applicationSites: Array<ApplicationSite>;
  attributeValues: Array<BooleanAttributeValue | FloatAttributeValue | MedicationAttributeValue | MedicationGroupAttributeValue | RadioAttributeValue | ScreeningBooleanAttributeValue>;
  conditions: Array<Condition>;
  conditionsWithMatchingInfo: Maybe<Array<ConditionWithMatchingInfo>>;
  contactPreferences: Maybe<ContactPreferences>;
  country: Scalars['CountryCode'];
  dob: Maybe<Scalars['String']>;
  ethnicity: Maybe<Scalars['String']>;
  id: Scalars['PatientID'];
  lat: Scalars['String'];
  lng: Scalars['String'];
  /** @deprecated Use localeWithInfo instead */
  locale: LocaleCode;
  localeWithInfo: Locale;
  origin: Maybe<Scalars['String']>;
  racialCategory1: Maybe<Scalars['String']>;
  racialCategory2: Maybe<Scalars['String']>;
  sex: Scalars['String'];
  state: Scalars['StateCode'];
  suburb: Scalars['String'];
  timezone: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};


export type DeidentifiedPatientDetailsconditionsWithMatchingInfoArgs = {
  isPhoneEvaluation: InputMaybe<Scalars['Boolean']>;
  priorityTrialIds: InputMaybe<Array<Scalars['TrialID']>>;
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};

export type DeidentifiedProfile = DeidentifiedPatientDetails & {
  __typename: 'DeidentifiedProfile';
  applicationSites: Array<ApplicationSite>;
  attributeValues: Array<BooleanAttributeValue | FloatAttributeValue | MedicationAttributeValue | MedicationGroupAttributeValue | RadioAttributeValue | ScreeningBooleanAttributeValue>;
  conditions: Array<Condition>;
  conditionsWithMatchingInfo: Maybe<Array<ConditionWithMatchingInfo>>;
  contactPreferences: Maybe<ContactPreferences>;
  country: Scalars['CountryCode'];
  dob: Maybe<Scalars['String']>;
  ethnicity: Maybe<Scalars['String']>;
  id: Scalars['PatientID'];
  lat: Scalars['String'];
  lng: Scalars['String'];
  locale: LocaleCode;
  localeWithInfo: Locale;
  origin: Maybe<Scalars['String']>;
  racialCategory1: Maybe<Scalars['String']>;
  racialCategory2: Maybe<Scalars['String']>;
  sex: Scalars['String'];
  state: Scalars['StateCode'];
  suburb: Scalars['String'];
  timezone: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};


export type DeidentifiedProfileconditionsWithMatchingInfoArgs = {
  isPhoneEvaluation: InputMaybe<Scalars['Boolean']>;
  priorityTrialIds: InputMaybe<Array<Scalars['TrialID']>>;
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};

export type DerivedAttribute = {
  __typename: 'DerivedAttribute';
  attributeDefinitionFrom: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  attributeDefinitionTo: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  from: BooleanValue | FloatValue | MedicationGroupValueOrSkip | MedicationValueOrSkip | RadioValue | Skip;
  id: Scalars['DerivedAttributeRuleID'];
  to: BooleanValue | FloatValue | MedicationGroupValueOrSkip | MedicationValueOrSkip | RadioValue | Skip;
};

export type DerivedConditionRule = {
  __typename: 'DerivedConditionRule';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  conditionIdTo: Scalars['ConditionID'];
  hasCondition: Scalars['Boolean'];
  id: Scalars['DerivedConditionRuleID'];
  valueFrom: BooleanValue | FloatValue | MedicationGroupValueOrSkip | MedicationValueOrSkip | RadioValue | Skip;
};

export type DerivedConditionsAnalyticsJob = AnalyticsJob & {
  __typename: 'DerivedConditionsAnalyticsJob';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: DerivedConditionsAnalyticsJob;
};


export type DerivedConditionsAnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type DocumentMetadata = {
  __typename: 'DocumentMetadata';
  attributeDefinition: Maybe<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  comment: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  documentReviewed: Scalars['Boolean'];
  filename: Scalars['String'];
  filepath: Scalars['String'];
  filesize: Scalars['Int'];
  id: Scalars['DocumentID'];
  patient: Patient;
  updatedAt: Scalars['String'];
};

export type EmailTemplate = {
  __typename: 'EmailTemplate';
  additionalInfo: EmailTemplateAdditionalInfo;
  calendarBookingUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  emailTemplateType: EmailTemplateType;
  id: Scalars['EmailTemplateID'];
  screeningFormUrl: Maybe<Scalars['String']>;
  trialSite: Maybe<TrialSite>;
  updatedAt: Scalars['String'];
  updatedBy: Maybe<Investigator>;
};

export type EmailTemplateAdditionalInfo = {
  __typename: 'EmailTemplateAdditionalInfo';
  emailText: Maybe<Scalars['String']>;
};

export enum EmailTemplateType {
  ContactInfo = 'ContactInfo',
  Generic = 'Generic',
  Scheduling = 'Scheduling',
  Screening = 'Screening'
}

export enum EmrRequestStatus {
  COMPLETED = 'COMPLETED',
  CONSENT_OBTAINED = 'CONSENT_OBTAINED',
  FAILED = 'FAILED',
  IDENTITY_CONFIRMED = 'IDENTITY_CONFIRMED',
  PENDING = 'PENDING'
}

export enum EmrRequestType {
  XCURES_TREATMENT_REQUEST = 'XCURES_TREATMENT_REQUEST'
}

export type EncoderUpdateTrialSiteActionInput = {
  addInvestigator?: InputMaybe<AddInvestigatorInput>;
  id: Scalars['TrialSiteID'];
  removeInvestigator?: InputMaybe<RemoveInvestigatorInput>;
  setAcceptsOutOfState?: InputMaybe<SetOptionalBooleanInput>;
  setApplicationMode?: InputMaybe<SetApplicationModeInput>;
  setBookingLink?: InputMaybe<SetOptionalStringInput>;
  setComments?: InputMaybe<SetOptionalStringInput>;
  setCompensationTextOverride?: InputMaybe<SetOptionalStringInput>;
  setCompensationType?: InputMaybe<SetCompensationTypeInput>;
  setIsAutoApply?: InputMaybe<SetBooleanInput>;
  setIsShowingApplications?: InputMaybe<SetBooleanInput>;
  setLocationId?: InputMaybe<SetLocationIdInput>;
  setMaxDistance?: InputMaybe<SetOptionalMaxDistanceInput>;
  setNickname?: InputMaybe<SetOptionalStringInput>;
  setPrimaryContactInvestigatorId?: InputMaybe<SetPrimaryContactInvestigatorIdInput>;
  setRecruitingStatus?: InputMaybe<SetTrialSiteRecruitingStatusInput>;
  setScreeningFormLink?: InputMaybe<SetOptionalStringInput>;
  setShouldSendApi?: InputMaybe<SetShouldSendApiInput>;
};

export enum EncodingStatus {
  blacklisted = 'blacklisted',
  encoded = 'encoded',
  investigator_outreach = 'investigator_outreach',
  medical_encoding = 'medical_encoding',
  review = 'review'
}

export enum Ethnicity {
  BLACK_ALONE_NON_HISPANIC = 'BLACK_ALONE_NON_HISPANIC',
  HISPANIC_LATINO = 'HISPANIC_LATINO',
  INCOMPLETE_ANSWERS = 'INCOMPLETE_ANSWERS',
  OTHER_NON_HISPANIC = 'OTHER_NON_HISPANIC',
  TWO_OR_MORE_NON_HISPANIC = 'TWO_OR_MORE_NON_HISPANIC',
  WHITE_ALONE_NON_HISPANIC = 'WHITE_ALONE_NON_HISPANIC'
}

export type EthnicityStats = {
  __typename: 'EthnicityStats';
  ethnicity: Ethnicity;
  hasCondition: Scalars['Boolean'];
  stats: MatchingStats;
};

export type EventAttributionIdentifiersInput = {
  adId?: InputMaybe<Scalars['String']>;
  adSetId?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  clickId?: InputMaybe<Scalars['String']>;
  gbraid?: InputMaybe<Scalars['String']>;
  gclid?: InputMaybe<Scalars['String']>;
  matchType?: InputMaybe<Scalars['String']>;
  medium?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  sourceOriginUrl?: InputMaybe<Scalars['String']>;
  wbraid?: InputMaybe<Scalars['String']>;
};

export enum EventName {
  AccountCreated = 'AccountCreated',
  ConditionSearch = 'ConditionSearch',
  QuestionnaireCompleted = 'QuestionnaireCompleted',
  QuestionnaireStarted = 'QuestionnaireStarted',
  ScreeningQuestionAnswered = 'ScreeningQuestionAnswered',
  SubmitApplication = 'SubmitApplication'
}

export type FacebookAuthInput = {
  facebookAccessToken: Scalars['String'];
  facebookUserId: Scalars['String'];
};

export type FloatAttributeDefinition = AttributeDefinition & {
  __typename: 'FloatAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  question: Scalars['String'];
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  units: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type FloatAttributeValue = AttributeValue & {
  __typename: 'FloatAttributeValue';
  attributeDefinition: FloatAttributeDefinition;
  createdAt: Scalars['String'];
  floatValue: Maybe<Scalars['Float']>;
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
};


export type FloatAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type FloatInput = {
  value?: InputMaybe<Scalars['Float']>;
};

export type FloatTrialRule = TrialRuleSerialisable & {
  __typename: 'FloatTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  operator: Operator;
  skippedResult: Scalars['Boolean'];
  value: Scalars['Float'];
};

export type FloatTrialRuleFlat = TrialRuleFlat & {
  __typename: 'FloatTrialRuleFlat';
  attributeDefinition: FloatAttributeDefinition;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  operator: Operator;
  skippedResult: Scalars['Boolean'];
  value: Scalars['Float'];
};

export type FloatTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  operator: Operator;
  skippedResult: Scalars['Boolean'];
  value: Scalars['Float'];
};

export type FloatValue = ValueOrSkip & {
  __typename: 'FloatValue';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  floatValue: Maybe<Scalars['Float']>;
};

export type FuzzyDate = {
  __typename: 'FuzzyDate';
  current: Maybe<Scalars['Boolean']>;
  day: Maybe<Scalars['Int']>;
  isBeforeX: Maybe<Scalars['Boolean']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type FuzzyDateInput = {
  current?: InputMaybe<Scalars['Boolean']>;
  day?: InputMaybe<Scalars['Int']>;
  isBeforeX?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum GenderRequirements {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum GroupMedicationEvaluator {
  HAS_EVER_TAKEN = 'HAS_EVER_TAKEN',
  HAS_NEVER_TAKEN = 'HAS_NEVER_TAKEN',
  HAS_NOT_TAKEN_IN_LAST_X_MONTHS = 'HAS_NOT_TAKEN_IN_LAST_X_MONTHS',
  HAS_TAKEN_IN_LAST_X_MONTHS = 'HAS_TAKEN_IN_LAST_X_MONTHS',
  IS_CURRENTLY_TAKING = 'IS_CURRENTLY_TAKING',
  IS_NOT_CURRENTLY_TAKING = 'IS_NOT_CURRENTLY_TAKING'
}

export type HasTakenMedicationGroupTrialRule = TrialRuleSerialisable & {
  __typename: 'HasTakenMedicationGroupTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  groupMedicationEvaluator: GroupMedicationEvaluator;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  lookBackInMonths: Maybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type HasTakenMedicationGroupTrialRuleFlat = TrialRuleFlat & {
  __typename: 'HasTakenMedicationGroupTrialRuleFlat';
  attributeDefinition: MedicationGroupAttributeDefinition;
  description: Maybe<Scalars['String']>;
  groupMedicationEvaluator: GroupMedicationEvaluator;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  lookBackInMonths: Maybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type HasTakenMedicationGroupTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  groupMedicationEvaluator: GroupMedicationEvaluator;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  lookBackInMonths?: InputMaybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type HasTakenMedicationTrialRule = TrialRuleSerialisable & {
  __typename: 'HasTakenMedicationTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  individualMedicationEvaluator: IndividualMedicationEvaluator;
  isPhoneRule: Scalars['Boolean'];
  lookBackInMonths: Maybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type HasTakenMedicationTrialRuleFlat = TrialRuleFlat & {
  __typename: 'HasTakenMedicationTrialRuleFlat';
  attributeDefinition: MedicationAttributeDefinition;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  individualMedicationEvaluator: IndividualMedicationEvaluator;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  lookBackInMonths: Maybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type HasTakenMedicationTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  individualMedicationEvaluator: IndividualMedicationEvaluator;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  lookBackInMonths?: InputMaybe<Scalars['Int']>;
  skippedResult: Scalars['Boolean'];
};

export type Indication = {
  __typename: 'Indication';
  id: Scalars['IndicationID'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export enum IndividualMedicationEvaluator {
  HAS_EVER_TAKEN = 'HAS_EVER_TAKEN',
  HAS_NEVER_TAKEN = 'HAS_NEVER_TAKEN',
  HAS_NOT_TAKEN_IN_LAST_X_MONTHS = 'HAS_NOT_TAKEN_IN_LAST_X_MONTHS',
  HAS_TAKEN_FOR_X_MONTHS = 'HAS_TAKEN_FOR_X_MONTHS',
  HAS_TAKEN_IN_LAST_X_MONTHS = 'HAS_TAKEN_IN_LAST_X_MONTHS',
  IS_CURRENTLY_TAKING = 'IS_CURRENTLY_TAKING',
  IS_NOT_CURRENTLY_TAKING = 'IS_NOT_CURRENTLY_TAKING'
}

export type InsightUserUpdateTrialActionInput = {
  actionNote?: InputMaybe<Scalars['String']>;
  setPatientRecruitingStatus?: InputMaybe<SetPatientRecruitingStatusInput>;
  trialId: Scalars['TrialID'];
};

export type InsightUserUpdateTrialSiteActionInput = {
  actionNote?: InputMaybe<Scalars['String']>;
  id: Scalars['TrialSiteID'];
  setMaxDistance?: InputMaybe<SetOptionalMaxDistanceInput>;
  setRecruitingStatus?: InputMaybe<SetTrialSiteRecruitingStatusInput>;
};

export type InsightsOrganisation = {
  __typename: 'InsightsOrganisation';
  allTrialSites: Array<TrialSite>;
  allTrials: Array<Trial>;
  dashboardConfiguration: DashboardConfiguration;
  feasoTrials: Array<Trial>;
  id: Scalars['InsightsOrganisationID'];
  insightsUsers: Array<InsightsUser>;
  name: Scalars['String'];
  reportingTrials: Array<Trial>;
};

export type InsightsUser = {
  __typename: 'InsightsUser';
  email: Scalars['String'];
  id: Scalars['InsightsUserID'];
  name: Maybe<Scalars['String']>;
  organisation: InsightsOrganisation;
  termsVersions: Array<TermsVersion>;
};

export type Investigator = {
  __typename: 'Investigator';
  applicationSiteCount: Array<ApplicationSiteCount>;
  comments: Maybe<Scalars['String']>;
  dontContact: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  hasWarmEmail: Scalars['Boolean'];
  id: Scalars['InvestigatorID'];
  isReceivingProfileSendoff: Maybe<Scalars['Boolean']>;
  lastLogin: Maybe<Scalars['DateTime']>;
  locations: Array<Location>;
  manageableInvestigators: Array<Investigator>;
  name: Scalars['String'];
  organisations: Array<Organisation>;
  phone: Maybe<Scalars['String']>;
  phoneCode: Maybe<Scalars['String']>;
  position: Maybe<Scalars['String']>;
  termsVersions: Array<TermsVersion>;
  trialOnboardingRequests: Array<TrialOnboardingRequest>;
  trialSites: Array<TrialSite>;
};


export type InvestigatortrialSitesArgs = {
  filter: InputMaybe<SearchInvestigatorTrialSiteInput>;
  searchType: InputMaybe<SearchType>;
};

export type InvestigatorUpdateLocationActionInput = {
  locationId: Scalars['LocationID'];
  setLocales?: InputMaybe<SetLocalesInput>;
};

export type InvestigatorUpdateTrialActionInput = {
  setCommitmentInPerson?: InputMaybe<SetOptionalStringInput>;
  setCommitmentIsTelehealth?: InputMaybe<SetBooleanInput>;
  setCommitmentLength?: InputMaybe<SetOptionalStringInput>;
  setCommitmentTelehealth?: InputMaybe<SetOptionalStringInput>;
  trialId: Scalars['TrialID'];
};

export type InvestigatorUpdateTrialSiteActionInput = {
  id: Scalars['TrialSiteID'];
  removeInvestigator?: InputMaybe<RemoveInvestigatorInput>;
  setMaxDistance?: InputMaybe<SetOptionalMaxDistanceInput>;
  setRecruitingStatus?: InputMaybe<SetTrialSiteRecruitingStatusInput>;
};

export type InviteInvestigatorActionInput = {
  investigatorInvites: Array<InvitedInvestigatorInput>;
  trialSiteId: Scalars['TrialSiteID'];
};

export type InviteInvestigatorToLocationActionInput = {
  investigatorInvites: Array<InvitedInvestigatorInput>;
  locationId: Scalars['LocationID'];
};

export type InvitedInvestigatorInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Locale = {
  __typename: 'Locale';
  code: LocaleCode;
  country: Scalars['CountryCode'];
  displayLanguage: Scalars['String'];
  displayLanguageLocal: Scalars['String'];
  displayName: Scalars['String'];
  displayNameLocal: Scalars['String'];
  languageCode: Scalars['String'];
  tag: Scalars['String'];
};

export enum LocaleCode {
  AF_NA = 'AF_NA',
  AF_ZA = 'AF_ZA',
  AGQ_CM = 'AGQ_CM',
  AK_GH = 'AK_GH',
  AM_ET = 'AM_ET',
  AR_AE = 'AR_AE',
  AR_BH = 'AR_BH',
  AR_DJ = 'AR_DJ',
  AR_DZ = 'AR_DZ',
  AR_EG = 'AR_EG',
  AR_EH = 'AR_EH',
  AR_ER = 'AR_ER',
  AR_IL = 'AR_IL',
  AR_IQ = 'AR_IQ',
  AR_JO = 'AR_JO',
  AR_KM = 'AR_KM',
  AR_KW = 'AR_KW',
  AR_LB = 'AR_LB',
  AR_LY = 'AR_LY',
  AR_MA = 'AR_MA',
  AR_MR = 'AR_MR',
  AR_OM = 'AR_OM',
  AR_PS = 'AR_PS',
  AR_QA = 'AR_QA',
  AR_SA = 'AR_SA',
  AR_SD = 'AR_SD',
  AR_SO = 'AR_SO',
  AR_SS = 'AR_SS',
  AR_SY = 'AR_SY',
  AR_TD = 'AR_TD',
  AR_TN = 'AR_TN',
  AR_YE = 'AR_YE',
  ASA_TZ = 'ASA_TZ',
  AST_ES = 'AST_ES',
  AS_IN = 'AS_IN',
  AZ_AZ = 'AZ_AZ',
  AZ_CYRL = 'AZ_CYRL',
  AZ_LATN = 'AZ_LATN',
  BAS_CM = 'BAS_CM',
  BEM_ZM = 'BEM_ZM',
  BEZ_TZ = 'BEZ_TZ',
  BE_BY = 'BE_BY',
  BG_BG = 'BG_BG',
  BM_ML = 'BM_ML',
  BN_BD = 'BN_BD',
  BN_IN = 'BN_IN',
  BO_CN = 'BO_CN',
  BO_IN = 'BO_IN',
  BRX_IN = 'BRX_IN',
  BR_FR = 'BR_FR',
  BS_BA = 'BS_BA',
  BS_CYRL = 'BS_CYRL',
  BS_LATN = 'BS_LATN',
  CA_AD = 'CA_AD',
  CA_ES = 'CA_ES',
  CA_FR = 'CA_FR',
  CA_IT = 'CA_IT',
  CCP_BD = 'CCP_BD',
  CCP_IN = 'CCP_IN',
  CEB_PH = 'CEB_PH',
  CE_RU = 'CE_RU',
  CGG_UG = 'CGG_UG',
  CHR_US = 'CHR_US',
  CKB_IQ = 'CKB_IQ',
  CKB_IR = 'CKB_IR',
  CS_CZ = 'CS_CZ',
  CY_GB = 'CY_GB',
  DAV_KE = 'DAV_KE',
  DA_DK = 'DA_DK',
  DA_GL = 'DA_GL',
  DE_AT = 'DE_AT',
  DE_BE = 'DE_BE',
  DE_CH = 'DE_CH',
  DE_DE = 'DE_DE',
  DE_IT = 'DE_IT',
  DE_LI = 'DE_LI',
  DE_LU = 'DE_LU',
  DJE_NE = 'DJE_NE',
  DOI_IN = 'DOI_IN',
  DSB_DE = 'DSB_DE',
  DUA_CM = 'DUA_CM',
  DYO_SN = 'DYO_SN',
  DZ_BT = 'DZ_BT',
  EBU_KE = 'EBU_KE',
  EE_GH = 'EE_GH',
  EE_TG = 'EE_TG',
  EL_CY = 'EL_CY',
  EL_GR = 'EL_GR',
  EN = 'EN',
  EN_AE = 'EN_AE',
  EN_AG = 'EN_AG',
  EN_AI = 'EN_AI',
  EN_AS = 'EN_AS',
  EN_AT = 'EN_AT',
  EN_AU = 'EN_AU',
  EN_BB = 'EN_BB',
  EN_BE = 'EN_BE',
  EN_BI = 'EN_BI',
  EN_BM = 'EN_BM',
  EN_BS = 'EN_BS',
  EN_BW = 'EN_BW',
  EN_BZ = 'EN_BZ',
  EN_CA = 'EN_CA',
  EN_CC = 'EN_CC',
  EN_CH = 'EN_CH',
  EN_CK = 'EN_CK',
  EN_CM = 'EN_CM',
  EN_CX = 'EN_CX',
  EN_CY = 'EN_CY',
  EN_DE = 'EN_DE',
  EN_DG = 'EN_DG',
  EN_DK = 'EN_DK',
  EN_DM = 'EN_DM',
  EN_ER = 'EN_ER',
  EN_FI = 'EN_FI',
  EN_FJ = 'EN_FJ',
  EN_FK = 'EN_FK',
  EN_FM = 'EN_FM',
  EN_GB = 'EN_GB',
  EN_GD = 'EN_GD',
  EN_GG = 'EN_GG',
  EN_GH = 'EN_GH',
  EN_GI = 'EN_GI',
  EN_GM = 'EN_GM',
  EN_GU = 'EN_GU',
  EN_GY = 'EN_GY',
  EN_HK = 'EN_HK',
  EN_IE = 'EN_IE',
  EN_IL = 'EN_IL',
  EN_IM = 'EN_IM',
  EN_IN = 'EN_IN',
  EN_IO = 'EN_IO',
  EN_JE = 'EN_JE',
  EN_JM = 'EN_JM',
  EN_KE = 'EN_KE',
  EN_KI = 'EN_KI',
  EN_KN = 'EN_KN',
  EN_KY = 'EN_KY',
  EN_LC = 'EN_LC',
  EN_LR = 'EN_LR',
  EN_LS = 'EN_LS',
  EN_MG = 'EN_MG',
  EN_MH = 'EN_MH',
  EN_MO = 'EN_MO',
  EN_MP = 'EN_MP',
  EN_MS = 'EN_MS',
  EN_MT = 'EN_MT',
  EN_MU = 'EN_MU',
  EN_MW = 'EN_MW',
  EN_MY = 'EN_MY',
  EN_NA = 'EN_NA',
  EN_NF = 'EN_NF',
  EN_NG = 'EN_NG',
  EN_NL = 'EN_NL',
  EN_NR = 'EN_NR',
  EN_NU = 'EN_NU',
  EN_NZ = 'EN_NZ',
  EN_PG = 'EN_PG',
  EN_PH = 'EN_PH',
  EN_PK = 'EN_PK',
  EN_PN = 'EN_PN',
  EN_PR = 'EN_PR',
  EN_PW = 'EN_PW',
  EN_RW = 'EN_RW',
  EN_SB = 'EN_SB',
  EN_SC = 'EN_SC',
  EN_SD = 'EN_SD',
  EN_SE = 'EN_SE',
  EN_SG = 'EN_SG',
  EN_SH = 'EN_SH',
  EN_SI = 'EN_SI',
  EN_SL = 'EN_SL',
  EN_SS = 'EN_SS',
  EN_SX = 'EN_SX',
  EN_SZ = 'EN_SZ',
  EN_TC = 'EN_TC',
  EN_TK = 'EN_TK',
  EN_TO = 'EN_TO',
  EN_TT = 'EN_TT',
  EN_TV = 'EN_TV',
  EN_TZ = 'EN_TZ',
  EN_UG = 'EN_UG',
  EN_UM = 'EN_UM',
  EN_US = 'EN_US',
  EN_VC = 'EN_VC',
  EN_VG = 'EN_VG',
  EN_VI = 'EN_VI',
  EN_VU = 'EN_VU',
  EN_WS = 'EN_WS',
  EN_ZA = 'EN_ZA',
  EN_ZM = 'EN_ZM',
  EN_ZW = 'EN_ZW',
  ES_AR = 'ES_AR',
  ES_BO = 'ES_BO',
  ES_BR = 'ES_BR',
  ES_BZ = 'ES_BZ',
  ES_CL = 'ES_CL',
  ES_CO = 'ES_CO',
  ES_CR = 'ES_CR',
  ES_CU = 'ES_CU',
  ES_DO = 'ES_DO',
  ES_EA = 'ES_EA',
  ES_EC = 'ES_EC',
  ES_ES = 'ES_ES',
  ES_GQ = 'ES_GQ',
  ES_GT = 'ES_GT',
  ES_HN = 'ES_HN',
  ES_IC = 'ES_IC',
  ES_MX = 'ES_MX',
  ES_NI = 'ES_NI',
  ES_PA = 'ES_PA',
  ES_PE = 'ES_PE',
  ES_PH = 'ES_PH',
  ES_PR = 'ES_PR',
  ES_PY = 'ES_PY',
  ES_SV = 'ES_SV',
  ES_US = 'ES_US',
  ES_UY = 'ES_UY',
  ES_VE = 'ES_VE',
  ET_EE = 'ET_EE',
  EU_ES = 'EU_ES',
  EWO_CM = 'EWO_CM',
  FA_AF = 'FA_AF',
  FA_IR = 'FA_IR',
  FF_ADLM = 'FF_ADLM',
  FF_GN = 'FF_GN',
  FF_LATN = 'FF_LATN',
  FF_SN = 'FF_SN',
  FIL_PH = 'FIL_PH',
  FI_FI = 'FI_FI',
  FO_DK = 'FO_DK',
  FO_FO = 'FO_FO',
  FR_BE = 'FR_BE',
  FR_BF = 'FR_BF',
  FR_BI = 'FR_BI',
  FR_BJ = 'FR_BJ',
  FR_BL = 'FR_BL',
  FR_CA = 'FR_CA',
  FR_CD = 'FR_CD',
  FR_CF = 'FR_CF',
  FR_CG = 'FR_CG',
  FR_CH = 'FR_CH',
  FR_CI = 'FR_CI',
  FR_CM = 'FR_CM',
  FR_DJ = 'FR_DJ',
  FR_DZ = 'FR_DZ',
  FR_FR = 'FR_FR',
  FR_GA = 'FR_GA',
  FR_GF = 'FR_GF',
  FR_GN = 'FR_GN',
  FR_GP = 'FR_GP',
  FR_GQ = 'FR_GQ',
  FR_HT = 'FR_HT',
  FR_KM = 'FR_KM',
  FR_LU = 'FR_LU',
  FR_MA = 'FR_MA',
  FR_MC = 'FR_MC',
  FR_MF = 'FR_MF',
  FR_MG = 'FR_MG',
  FR_ML = 'FR_ML',
  FR_MQ = 'FR_MQ',
  FR_MR = 'FR_MR',
  FR_MU = 'FR_MU',
  FR_NC = 'FR_NC',
  FR_NE = 'FR_NE',
  FR_PF = 'FR_PF',
  FR_PM = 'FR_PM',
  FR_RE = 'FR_RE',
  FR_RW = 'FR_RW',
  FR_SC = 'FR_SC',
  FR_SN = 'FR_SN',
  FR_SY = 'FR_SY',
  FR_TD = 'FR_TD',
  FR_TG = 'FR_TG',
  FR_TN = 'FR_TN',
  FR_VU = 'FR_VU',
  FR_WF = 'FR_WF',
  FR_YT = 'FR_YT',
  FUR_IT = 'FUR_IT',
  FY_NL = 'FY_NL',
  GA_GB = 'GA_GB',
  GA_IE = 'GA_IE',
  GD_GB = 'GD_GB',
  GL_ES = 'GL_ES',
  GSW_CH = 'GSW_CH',
  GSW_FR = 'GSW_FR',
  GSW_LI = 'GSW_LI',
  GUZ_KE = 'GUZ_KE',
  GU_IN = 'GU_IN',
  GV_IM = 'GV_IM',
  HAW_US = 'HAW_US',
  HA_GH = 'HA_GH',
  HA_NE = 'HA_NE',
  HA_NG = 'HA_NG',
  HE_IL = 'HE_IL',
  HI_IN = 'HI_IN',
  HR_BA = 'HR_BA',
  HR_HR = 'HR_HR',
  HSB_DE = 'HSB_DE',
  HU_HU = 'HU_HU',
  HY_AM = 'HY_AM',
  ID_ID = 'ID_ID',
  IG_NG = 'IG_NG',
  II_CN = 'II_CN',
  IS_IS = 'IS_IS',
  IT_CH = 'IT_CH',
  IT_IT = 'IT_IT',
  IT_SM = 'IT_SM',
  IT_VA = 'IT_VA',
  JA_JP = 'JA_JP',
  JGO_CM = 'JGO_CM',
  JMC_TZ = 'JMC_TZ',
  JV_ID = 'JV_ID',
  KAB_DZ = 'KAB_DZ',
  KAM_KE = 'KAM_KE',
  KA_GE = 'KA_GE',
  KDE_TZ = 'KDE_TZ',
  KEA_CV = 'KEA_CV',
  KHQ_ML = 'KHQ_ML',
  KI_KE = 'KI_KE',
  KKJ_CM = 'KKJ_CM',
  KK_KZ = 'KK_KZ',
  KLN_KE = 'KLN_KE',
  KL_GL = 'KL_GL',
  KM_KH = 'KM_KH',
  KN_IN = 'KN_IN',
  KOK_IN = 'KOK_IN',
  KO_KP = 'KO_KP',
  KO_KR = 'KO_KR',
  KSB_TZ = 'KSB_TZ',
  KSF_CM = 'KSF_CM',
  KSH_DE = 'KSH_DE',
  KS_ARAB = 'KS_ARAB',
  KS_IN = 'KS_IN',
  KU_TR = 'KU_TR',
  KW_GB = 'KW_GB',
  KY_KG = 'KY_KG',
  LAG_TZ = 'LAG_TZ',
  LB_LU = 'LB_LU',
  LG_UG = 'LG_UG',
  LKT_US = 'LKT_US',
  LN_AO = 'LN_AO',
  LN_CD = 'LN_CD',
  LN_CF = 'LN_CF',
  LN_CG = 'LN_CG',
  LO_LA = 'LO_LA',
  LRC_IQ = 'LRC_IQ',
  LRC_IR = 'LRC_IR',
  LT_LT = 'LT_LT',
  LUO_KE = 'LUO_KE',
  LUY_KE = 'LUY_KE',
  LU_CD = 'LU_CD',
  LV_LV = 'LV_LV',
  MAI_IN = 'MAI_IN',
  MAS_KE = 'MAS_KE',
  MAS_TZ = 'MAS_TZ',
  MER_KE = 'MER_KE',
  MFE_MU = 'MFE_MU',
  MGH_MZ = 'MGH_MZ',
  MGO_CM = 'MGO_CM',
  MG_MG = 'MG_MG',
  MI_NZ = 'MI_NZ',
  MK_MK = 'MK_MK',
  ML_IN = 'ML_IN',
  MNI_BENG = 'MNI_BENG',
  MNI_IN = 'MNI_IN',
  MN_MN = 'MN_MN',
  MR_IN = 'MR_IN',
  MS_BN = 'MS_BN',
  MS_ID = 'MS_ID',
  MS_MY = 'MS_MY',
  MS_SG = 'MS_SG',
  MT_MT = 'MT_MT',
  MUA_CM = 'MUA_CM',
  MY_MM = 'MY_MM',
  MZN_IR = 'MZN_IR',
  NAQ_NA = 'NAQ_NA',
  NB_NO = 'NB_NO',
  NB_SJ = 'NB_SJ',
  NDS_DE = 'NDS_DE',
  NDS_NL = 'NDS_NL',
  ND_ZW = 'ND_ZW',
  NE_IN = 'NE_IN',
  NE_NP = 'NE_NP',
  NL_AW = 'NL_AW',
  NL_BE = 'NL_BE',
  NL_BQ = 'NL_BQ',
  NL_CW = 'NL_CW',
  NL_NL = 'NL_NL',
  NL_SR = 'NL_SR',
  NL_SX = 'NL_SX',
  NMG_CM = 'NMG_CM',
  NNH_CM = 'NNH_CM',
  NN_NO = 'NN_NO',
  NO_NO = 'NO_NO',
  NUS_SS = 'NUS_SS',
  NYN_UG = 'NYN_UG',
  OM_ET = 'OM_ET',
  OM_KE = 'OM_KE',
  OR_IN = 'OR_IN',
  OS_GE = 'OS_GE',
  OS_RU = 'OS_RU',
  PA_ARAB = 'PA_ARAB',
  PA_GURU = 'PA_GURU',
  PA_IN = 'PA_IN',
  PA_PK = 'PA_PK',
  PCM_NG = 'PCM_NG',
  PL_PL = 'PL_PL',
  PS_AF = 'PS_AF',
  PS_PK = 'PS_PK',
  PT_AO = 'PT_AO',
  PT_BR = 'PT_BR',
  PT_CH = 'PT_CH',
  PT_CV = 'PT_CV',
  PT_GQ = 'PT_GQ',
  PT_GW = 'PT_GW',
  PT_LU = 'PT_LU',
  PT_MO = 'PT_MO',
  PT_MZ = 'PT_MZ',
  PT_PT = 'PT_PT',
  PT_ST = 'PT_ST',
  PT_TL = 'PT_TL',
  QU_BO = 'QU_BO',
  QU_EC = 'QU_EC',
  QU_PE = 'QU_PE',
  RM_CH = 'RM_CH',
  RN_BI = 'RN_BI',
  ROF_TZ = 'ROF_TZ',
  RO_MD = 'RO_MD',
  RO_RO = 'RO_RO',
  RU_BY = 'RU_BY',
  RU_KG = 'RU_KG',
  RU_KZ = 'RU_KZ',
  RU_MD = 'RU_MD',
  RU_RU = 'RU_RU',
  RU_UA = 'RU_UA',
  RWK_TZ = 'RWK_TZ',
  RW_RW = 'RW_RW',
  SAH_RU = 'SAH_RU',
  SAQ_KE = 'SAQ_KE',
  SAT_IN = 'SAT_IN',
  SAT_OLCK = 'SAT_OLCK',
  SA_IN = 'SA_IN',
  SBP_TZ = 'SBP_TZ',
  SD_ARAB = 'SD_ARAB',
  SD_DEVA = 'SD_DEVA',
  SD_IN = 'SD_IN',
  SD_PK = 'SD_PK',
  SEH_MZ = 'SEH_MZ',
  SES_ML = 'SES_ML',
  SE_FI = 'SE_FI',
  SE_NO = 'SE_NO',
  SE_SE = 'SE_SE',
  SG_CF = 'SG_CF',
  SHI_LATN = 'SHI_LATN',
  SHI_MA = 'SHI_MA',
  SHI_TFNG = 'SHI_TFNG',
  SI_LK = 'SI_LK',
  SK_SK = 'SK_SK',
  SL_SI = 'SL_SI',
  SMN_FI = 'SMN_FI',
  SN_ZW = 'SN_ZW',
  SO_DJ = 'SO_DJ',
  SO_ET = 'SO_ET',
  SO_KE = 'SO_KE',
  SO_SO = 'SO_SO',
  SQ_AL = 'SQ_AL',
  SQ_MK = 'SQ_MK',
  SQ_XK = 'SQ_XK',
  SR_BA = 'SR_BA',
  SR_CS = 'SR_CS',
  SR_CYRL = 'SR_CYRL',
  SR_LATN = 'SR_LATN',
  SR_ME = 'SR_ME',
  SR_RS = 'SR_RS',
  SU_ID = 'SU_ID',
  SU_LATN = 'SU_LATN',
  SV_AX = 'SV_AX',
  SV_FI = 'SV_FI',
  SV_SE = 'SV_SE',
  SW_CD = 'SW_CD',
  SW_KE = 'SW_KE',
  SW_TZ = 'SW_TZ',
  SW_UG = 'SW_UG',
  TA_IN = 'TA_IN',
  TA_LK = 'TA_LK',
  TA_MY = 'TA_MY',
  TA_SG = 'TA_SG',
  TEO_KE = 'TEO_KE',
  TEO_UG = 'TEO_UG',
  TE_IN = 'TE_IN',
  TG_TJ = 'TG_TJ',
  TH_TH = 'TH_TH',
  TI_ER = 'TI_ER',
  TI_ET = 'TI_ET',
  TK_TM = 'TK_TM',
  TO_TO = 'TO_TO',
  TR_CY = 'TR_CY',
  TR_TR = 'TR_TR',
  TT_RU = 'TT_RU',
  TWQ_NE = 'TWQ_NE',
  TZM_MA = 'TZM_MA',
  UG_CN = 'UG_CN',
  UK_UA = 'UK_UA',
  UR_IN = 'UR_IN',
  UR_PK = 'UR_PK',
  UZ_AF = 'UZ_AF',
  UZ_ARAB = 'UZ_ARAB',
  UZ_CYRL = 'UZ_CYRL',
  UZ_LATN = 'UZ_LATN',
  UZ_UZ = 'UZ_UZ',
  VAI_LATN = 'VAI_LATN',
  VAI_LR = 'VAI_LR',
  VAI_VAII = 'VAI_VAII',
  VI_VN = 'VI_VN',
  VUN_TZ = 'VUN_TZ',
  WAE_CH = 'WAE_CH',
  WO_SN = 'WO_SN',
  XH_ZA = 'XH_ZA',
  XOG_UG = 'XOG_UG',
  YAV_CM = 'YAV_CM',
  YO_BJ = 'YO_BJ',
  YO_NG = 'YO_NG',
  YUE_CN = 'YUE_CN',
  YUE_HANS = 'YUE_HANS',
  YUE_HANT = 'YUE_HANT',
  YUE_HK = 'YUE_HK',
  ZGH_MA = 'ZGH_MA',
  ZH_CN = 'ZH_CN',
  ZH_HANS = 'ZH_HANS',
  ZH_HANT = 'ZH_HANT',
  ZH_HK = 'ZH_HK',
  ZH_MO = 'ZH_MO',
  ZH_SG = 'ZH_SG',
  ZH_TW = 'ZH_TW',
  ZU_ZA = 'ZU_ZA'
}

export type LocalisationTranslationInput = {
  externalLocalisationId: Scalars['Long'];
  localeCode: LocaleCode;
  translation: Scalars['String'];
  translationKey: Scalars['String'];
};

export type Location = {
  __typename: 'Location';
  address: Scalars['String'];
  contactEmail: Maybe<Scalars['String']>;
  contactPhoneNumber: Maybe<Scalars['String']>;
  countryCode: Scalars['CountryCode'];
  externalId: Maybe<Scalars['String']>;
  id: Scalars['LocationID'];
  investigators: Array<Investigator>;
  latitude: Maybe<Scalars['Float']>;
  locales: Array<Locale>;
  longitude: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  state: Maybe<Scalars['StateCode']>;
  timezone: Maybe<Timezone>;
};

export enum LoginCodeFlowType {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export enum LoginSource {
  EMAIL = 'EMAIL',
  REFERRAL = 'REFERRAL',
  SMS = 'SMS'
}

export type MatchProbabilityAnalyticsJob = AnalyticsJob & {
  __typename: 'MatchProbabilityAnalyticsJob';
  configuration: MatchProbabilityAnalyticsJobConfiguration;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  result: Maybe<MatchProbabilityAnalyticsJobResult>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: MatchProbabilityAnalyticsJob;
};


export type MatchProbabilityAnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type MatchProbabilityAnalyticsJobConfiguration = {
  __typename: 'MatchProbabilityAnalyticsJobConfiguration';
  trialId: Scalars['TrialID'];
};

export type MatchProbabilityAnalyticsJobConfigurationInput = {
  trialId: Scalars['TrialID'];
};

export type MatchProbabilityAnalyticsJobResult = {
  __typename: 'MatchProbabilityAnalyticsJobResult';
  trialId: Scalars['TrialID'];
};

export type MatchingInfo = {
  __typename: 'MatchingInfo';
  allAttributesCount: Scalars['Int'];
  id: Scalars['String'];
  matchingTrials: Array<Trial>;
  matchingTrialsWithSkips: Array<Trial>;
  missingAttributesCount: Scalars['Int'];
  /** @deprecated Use needsMoreAnswersTrials */
  missingAttributesTrials: Array<Scalars['TrialID']>;
  nextMandatoryAttributeDefinition: Maybe<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  nextMissingAttributeDefinition: Maybe<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  nextSkippedAttributeDefinition: Maybe<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  notMatchingTrials: Array<Trial>;
  notMatchingTrialsWithSkips: Array<Trial>;
  patient: Patient;
  seenTrials: Array<Trial>;
  skippedAttributesCount: Scalars['Int'];
  /** @deprecated Use notMatchingTrialsWithSkips */
  skippedFalseTrials: Array<Scalars['TrialID']>;
  /** @deprecated Use matchingTrialsWithSkips */
  skippedTrueTrials: Array<Scalars['TrialID']>;
  unansweredTrials: Array<Trial>;
  /** @deprecated Use notMatchingTrials */
  unskippedFalseTrials: Array<Scalars['TrialID']>;
  /** @deprecated Use matchingTrials */
  unskippedTrueTrials: Array<Scalars['TrialID']>;
};

export type MatchingResult = {
  __typename: 'MatchingResult';
  hasMissingAnswers: Scalars['Int'];
  skippedFalse: Scalars['Int'];
  skippedTrue: Scalars['Int'];
  total: Scalars['Int'];
  unskippedFalse: Scalars['Int'];
  unskippedTrue: Scalars['Int'];
};

export type MatchingStats = {
  __typename: 'MatchingStats';
  match: Scalars['Int'];
  matchWithSkips: Scalars['Int'];
  needsMoreAnswers: Scalars['Int'];
  notMatch: Scalars['Int'];
  notMatchWithSkips: Scalars['Int'];
};

export enum MaxDistance {
  ANYWHERE = 'ANYWHERE',
  UNKNOWN = 'UNKNOWN',
  WITHIN_16KM = 'WITHIN_16KM',
  WITHIN_32KM = 'WITHIN_32KM',
  WITHIN_50KM = 'WITHIN_50KM',
  WITHIN_65KM = 'WITHIN_65KM',
  WITHIN_80KM = 'WITHIN_80KM',
  WITHIN_95KM = 'WITHIN_95KM',
  WITHIN_110KM = 'WITHIN_110KM',
  WITHIN_130KM = 'WITHIN_130KM',
  WITHIN_160KM = 'WITHIN_160KM',
  WITHIN_240KM = 'WITHIN_240KM',
  WITHIN_320KM = 'WITHIN_320KM',
  WITHIN_480KM = 'WITHIN_480KM'
}

export type MedicationAttributeDefinition = AttributeDefinition & {
  __typename: 'MedicationAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  medicationName: Scalars['String'];
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type MedicationAttributeValue = AttributeValue & {
  __typename: 'MedicationAttributeValue';
  attributeDefinition: MedicationAttributeDefinition;
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
  value: Maybe<MedicationValue>;
};


export type MedicationAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type MedicationGroupAttributeDefinition = AttributeDefinition & {
  __typename: 'MedicationGroupAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  medicationAttributeDefinitions: Array<MedicationAttributeDefinition>;
  medicationGroupName: Scalars['String'];
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};


export type MedicationGroupAttributeDefinitionmedicationAttributeDefinitionsArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type MedicationGroupAttributeValue = AttributeValue & {
  __typename: 'MedicationGroupAttributeValue';
  attributeDefinition: MedicationGroupAttributeDefinition;
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
  value: Maybe<MedicationGroupValue>;
};


export type MedicationGroupAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type MedicationGroupInput = {
  value?: InputMaybe<MedicationGroupValueInput>;
};

export type MedicationGroupValue = {
  __typename: 'MedicationGroupValue';
  hasTaken: Scalars['Boolean'];
  intervals: Maybe<Array<MedicationInterval>>;
};

export type MedicationGroupValueInput = {
  hasTaken: Scalars['Boolean'];
  intervals?: InputMaybe<Array<MedicationIntervalInput>>;
};

export type MedicationGroupValueOrSkip = ValueOrSkip & {
  __typename: 'MedicationGroupValueOrSkip';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  medicationGroupValue: Maybe<MedicationGroupValue>;
};

export type MedicationInput = {
  value?: InputMaybe<MedicationValueInput>;
};

export type MedicationInterval = {
  __typename: 'MedicationInterval';
  end: FuzzyDate;
  start: FuzzyDate;
};

export type MedicationIntervalInput = {
  end: FuzzyDateInput;
  start: FuzzyDateInput;
};

export type MedicationValue = {
  __typename: 'MedicationValue';
  hasTaken: Scalars['Boolean'];
  intervals: Maybe<Array<MedicationInterval>>;
};

export type MedicationValueInput = {
  hasTaken: Scalars['Boolean'];
  intervals?: InputMaybe<Array<MedicationIntervalInput>>;
};

export type MedicationValueOrSkip = ValueOrSkip & {
  __typename: 'MedicationValueOrSkip';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  medicationValue: Maybe<MedicationValue>;
};

export type MergeConditionsActionInput = {
  fromConditionId: Scalars['ConditionID'];
  toConditionId: Scalars['ConditionID'];
};

export type MergeLocationsActionInput = {
  fromLocationIds: Array<Scalars['LocationID']>;
  toLocationId: Scalars['LocationID'];
};

export type MergedConditionsResult = {
  __typename: 'MergedConditionsResult';
  condition: Condition;
  updatedPatientIds: Array<Scalars['PatientID']>;
  updatedTrialIds: Array<Scalars['TrialID']>;
};

export type MetaIdentifiersInput = {
  fbc?: InputMaybe<Scalars['String']>;
  fbp?: InputMaybe<Scalars['String']>;
};

export type MetabaseGraphToken = {
  __typename: 'MetabaseGraphToken';
  siteUrl: Scalars['String'];
  token: Scalars['String'];
};

export type MfaConfiguration = {
  __typename: 'MfaConfiguration';
  enabled: Scalars['Boolean'];
  qrCodeImage: Maybe<Scalars['String']>;
  recoveryCodes: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename: 'Mutation';
  addApplicationSiteContactEvent: Maybe<ApplicationSiteEvent>;
  addPatientContactedEvent: PatientContactedEvent;
  agentUpdateApplication: Application;
  agentUpdateApplicationSite: ApplicationSite;
  agentUpdatePatient: Patient;
  applyApplicationSiteSyncChangeset: ApplicationSiteSyncChangeset;
  archiveExpiredAnalyticsJobs: Array<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  changePassword: Response;
  checkPhoneVerificationCode: Patient;
  cloneTrial: Trial;
  confirmUpload: DocumentMetadata;
  createAnalyticsJobs: Array<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  /** Creates and adds the current investigator to an organisation */
  createAndJoinOrganisation: Organisation;
  createApplication: Application;
  createAttributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  createCampaign: Campaign;
  createCampaignCallListPatients: Response;
  createCampaignPatient: CampaignPatient;
  createCampaignPatients: Array<CampaignPatient>;
  createCommitment: Commitment;
  createCondition: Condition;
  createDailyAnalyticsJobs: Array<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  createIndication: Indication;
  createInsightsOrganisation: InsightsOrganisation;
  createInsightsUser: InsightsUser;
  createInvestigator: Investigator;
  createLocation: Location;
  createMatchProbabilityAnalyticsJobForCampaign: Array<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  createOrganisation: Organisation;
  createPreapp: Preapp;
  createSponsor: Sponsor;
  createSponsorLead: Response;
  createTranslations: Scalars['Boolean'];
  createTrial: Trial;
  createTrialOnboardingRequest: TrialOnboardingRequest;
  createTrialSite: TrialSite;
  deleteAttributeDefinition: AttributeDefinitionUsage;
  deleteInsightsOrganisation: Response;
  deleteInsightsUser: Response;
  deleteInvestigator: Response;
  deleteOrganisation: Response;
  deletePatient: Response;
  deleteSponsor: Response;
  deleteTrialSite: Response;
  deleteTrialSites: Response;
  encoderUpdateTrialOnboardingRequest: TrialOnboardingRequest;
  encoderUpdateTrialSite: TrialSite;
  generateInvestigatorOneClickLoginCode: Scalars['String'];
  generatePatientOneClickLoginCode: Scalars['String'];
  generateSyncChangeset: ApplicationSiteSyncEvent;
  importRawTrials: Response;
  importTrialSitesForTrial: Maybe<Scalars['Int']>;
  importTrialSitesForTrials: Scalars['Int'];
  insightUserUpdateTrialSite: TrialSite;
  /** If both an email and user id are provided, email is given preference */
  insightsUserLogin: InsightsUser;
  /** If both an email and user id are provided, email is given preference */
  insightsUserSendLoginCode: Response;
  insightsUserUpdateTrial: Trial;
  investigatorCreateApplication: DeidentifiedProfile;
  /** @deprecated Use locationMutation.investigatorInviteToLocation instead */
  investigatorInviteToTrialSite: TrialSite;
  /** If both an email and investigator id are provided, email is given preference */
  investigatorLogin: Investigator;
  investigatorRegister: Investigator;
  /** If both an email and investigator id are provided, email is given preference */
  investigatorSendLoginCode: Response;
  investigatorUpdateLocation: Location;
  investigatorUpdateTrial: Trial;
  investigatorUpdateTrialOnboardingRequest: TrialOnboardingRequest;
  investigatorUpdateTrialSite: TrialSite;
  inviteInvestigatorToLocation: Location;
  loginPatient: Patient;
  mergeConditions: MergedConditionsResult;
  mergeLocations: Response;
  passwordReset: Response;
  patientCreateApplication: Patient;
  /** @deprecated Deprecated: use the one with the better name, loginPatient, instead, replace with loginPatient(input: UserCredentials) */
  patientLogin: Patient;
  patientUpdateApplication: Application;
  /** @deprecated use investigatorRegister instead */
  registerInvestigator: Investigator;
  registerPatient: Patient;
  removeCurrentInvestigatorFromOrganisation: Response;
  removeInvestigatorFromOrganisation: Response;
  requestEmrForCampaignPatient: CampaignPatientEmrRequestRecord;
  sendConversionsTrackingEvent: Response;
  sendLoginCode: Response;
  sendLoginCodeNew: SendLoginCodeResponse;
  sendPhoneVerificationCode: PhoneVerificationChannel;
  sendoffProfiles: Scalars['String'];
  setAndReturnTrialRulesFlat: TrialRulesFlat;
  setMatchesSeen: Patient;
  setupMfa: MfaConfiguration;
  submitAnalyticsJob: CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob;
  submitAttributeValues: Patient;
  syncAllTranslations: Scalars['Boolean'];
  syncMandatoryFlowTranslations: Scalars['Boolean'];
  syncRecruitingStatusesWithRegistry: Scalars['String'];
  syncStudyTeamEvents: Scalars['Int'];
  syncTranslationsForAttributes: Scalars['Boolean'];
  syncTranslationsForTrial: Scalars['Boolean'];
  testInsightsQuery: Scalars['String'];
  updateApplicationSite: ApplicationSite;
  updateApplicationSites: Array<ApplicationSite>;
  updateAttributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  updateCampaign: Campaign;
  updateCampaignPatient: CampaignPatient;
  updateCommitment: Commitment;
  updateCondition: Condition;
  updateCurrentInsightsUser: InsightsUser;
  updateCurrentInvestigator: Investigator;
  updateEmailTemplate: EmailTemplate;
  updateIndication: Indication;
  updateInsightsOrganisation: InsightsOrganisation;
  updateInsightsUser: InsightsUser;
  updateInvestigator: Investigator;
  updateLocation: Location;
  updateManagedInvestigator: Investigator;
  updatePatient: Patient;
  /** @deprecated Use agentUpdatePatient instead */
  updatePatientEmail: Patient;
  updatePreapp: Preapp;
  updateRegistryStatuses: Scalars['Int'];
  updateSponsor: Sponsor;
  updateTrial: Trial;
  verifyEmail: Response;
};


export type MutationaddApplicationSiteContactEventArgs = {
  input: AddApplicationSiteContactEventInput;
};


export type MutationaddPatientContactedEventArgs = {
  input: AddPatientContactedEventInput;
};


export type MutationagentUpdateApplicationArgs = {
  action: AgentUpdateApplicationActionInput;
  isCallCenter: InputMaybe<Scalars['Boolean']>;
};


export type MutationagentUpdateApplicationSiteArgs = {
  action: AgentUpdateApplicationSiteActionInput;
  id: Scalars['ApplicationSiteID'];
};


export type MutationagentUpdatePatientArgs = {
  id: InputMaybe<Scalars['PatientID']>;
  input: AgentUpdatePatientInput;
};


export type MutationapplyApplicationSiteSyncChangesetArgs = {
  id: Scalars['ApplicationSiteSyncChangesetId'];
};


export type MutationchangePasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationcheckPhoneVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};


export type MutationcloneTrialArgs = {
  fromTrialId: Scalars['TrialID'];
  includeRules: Scalars['Boolean'];
  includeSites: Scalars['Boolean'];
  toTrialId: Scalars['TrialID'];
};


export type MutationconfirmUploadArgs = {
  id: InputMaybe<Scalars['PatientID']>;
  patientDocumentMetadata: UploadMetadataInput;
};


export type MutationcreateAnalyticsJobsArgs = {
  input: CreateAnalyticsJobsInput;
};


export type MutationcreateAndJoinOrganisationArgs = {
  action: CreateOrganisationActionInput;
};


export type MutationcreateApplicationArgs = {
  patientId: InputMaybe<Scalars['PatientID']>;
  trialId: Scalars['TrialID'];
};


export type MutationcreateAttributeDefinitionArgs = {
  createAttributeDefinitionInput: CreateAttributeDefinitionInput;
};


export type MutationcreateCampaignArgs = {
  name: Scalars['String'];
  trials: Array<CampaignTrialConfigInput>;
  type: CampaignType;
};


export type MutationcreateCampaignCallListPatientsArgs = {
  campaignCallListPatients: Array<CampaignCallListPatientInput>;
};


export type MutationcreateCampaignPatientArgs = {
  input: CampaignPatientCreateInput;
};


export type MutationcreateCampaignPatientsArgs = {
  input: CampaignPatientsCreateInput;
};


export type MutationcreateCommitmentArgs = {
  displayText: Scalars['String'];
  title: Scalars['String'];
};


export type MutationcreateConditionArgs = {
  indicationID: Scalars['IndicationID'];
  name: Scalars['String'];
};


export type MutationcreateIndicationArgs = {
  name: Scalars['String'];
  subject: Scalars['String'];
};


export type MutationcreateInsightsOrganisationArgs = {
  input: CreateInsightsOrganisationInput;
};


export type MutationcreateInsightsUserArgs = {
  input: CreateInsightsUserInput;
};


export type MutationcreateInvestigatorArgs = {
  name: Scalars['String'];
};


export type MutationcreateLocationArgs = {
  address: Scalars['String'];
  contactEmail: InputMaybe<Scalars['String']>;
  contactPhoneNumber: InputMaybe<Scalars['String']>;
  countryCode: Scalars['CountryCode'];
  googlePlaceId: InputMaybe<Scalars['GooglePlaceId']>;
  latitude: InputMaybe<Scalars['Float']>;
  locales: Array<LocaleCode>;
  longitude: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  state: InputMaybe<Scalars['String']>;
};


export type MutationcreateMatchProbabilityAnalyticsJobForCampaignArgs = {
  campaignId: Scalars['CampaignId'];
};


export type MutationcreateOrganisationArgs = {
  action: CreateOrganisationActionInput;
};


export type MutationcreatePreappArgs = {
  interest: Scalars['String'];
  patientId: Scalars['PatientID'];
  trialId: Scalars['TrialID'];
};


export type MutationcreateSponsorArgs = {
  name: Scalars['String'];
};


export type MutationcreateSponsorLeadArgs = {
  input: SponsorLeadInput;
};


export type MutationcreateTranslationsArgs = {
  translations: Array<LocalisationTranslationInput>;
};


export type MutationcreateTrialArgs = {
  id: Scalars['TrialID'];
};


export type MutationcreateTrialOnboardingRequestArgs = {
  input: TrialOnboardingRequestInput;
};


export type MutationcreateTrialSiteArgs = {
  locationId: Scalars['LocationID'];
  trialId: Scalars['TrialID'];
};


export type MutationdeleteAttributeDefinitionArgs = {
  id: Scalars['AttributeDefinitionID'];
};


export type MutationdeleteInsightsOrganisationArgs = {
  id: Scalars['InsightsOrganisationID'];
};


export type MutationdeleteInsightsUserArgs = {
  id: Scalars['InsightsUserID'];
};


export type MutationdeleteInvestigatorArgs = {
  id: Scalars['InvestigatorID'];
};


export type MutationdeleteOrganisationArgs = {
  id: Scalars['OrganisationID'];
};


export type MutationdeletePatientArgs = {
  id: InputMaybe<Scalars['PatientID']>;
};


export type MutationdeleteSponsorArgs = {
  id: Scalars['SponsorID'];
};


export type MutationdeleteTrialSiteArgs = {
  ids: Scalars['TrialSiteID'];
};


export type MutationdeleteTrialSitesArgs = {
  ids: Array<Scalars['TrialSiteID']>;
};


export type MutationencoderUpdateTrialOnboardingRequestArgs = {
  action: UpdateTrialOnboardingRequestEncoderActionInput;
};


export type MutationencoderUpdateTrialSiteArgs = {
  action: EncoderUpdateTrialSiteActionInput;
};


export type MutationgenerateInvestigatorOneClickLoginCodeArgs = {
  id: Scalars['InvestigatorID'];
};


export type MutationgeneratePatientOneClickLoginCodeArgs = {
  id: Scalars['PatientID'];
};


export type MutationgenerateSyncChangesetArgs = {
  type: SyncType;
};


export type MutationimportRawTrialsArgs = {
  trialIDs: Array<Scalars['TrialID']>;
};


export type MutationimportTrialSitesForTrialArgs = {
  trialId: Scalars['TrialID'];
};


export type MutationimportTrialSitesForTrialsArgs = {
  trialIds: Array<Scalars['TrialID']>;
};


export type MutationinsightUserUpdateTrialSiteArgs = {
  action: InsightUserUpdateTrialSiteActionInput;
};


export type MutationinsightsUserLoginArgs = {
  email: InputMaybe<Scalars['String']>;
  insightsUserId: InputMaybe<Scalars['InsightsUserID']>;
  loginCode: Scalars['String'];
};


export type MutationinsightsUserSendLoginCodeArgs = {
  email: InputMaybe<Scalars['String']>;
  insightsUserId: InputMaybe<Scalars['InsightsUserID']>;
};


export type MutationinsightsUserUpdateTrialArgs = {
  action: InsightUserUpdateTrialActionInput;
};


export type MutationinvestigatorCreateApplicationArgs = {
  patientId: Scalars['PatientID'];
  trialId: Scalars['TrialID'];
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};


export type MutationinvestigatorInviteToTrialSiteArgs = {
  action: InviteInvestigatorActionInput;
};


export type MutationinvestigatorLoginArgs = {
  email: InputMaybe<Scalars['String']>;
  investigatorId: InputMaybe<Scalars['InvestigatorID']>;
  loginCode: InputMaybe<Scalars['String']>;
  oneClickLoginCode: InputMaybe<Scalars['String']>;
};


export type MutationinvestigatorRegisterArgs = {
  registrationInput: RegisterInvestigatorActionInput;
};


export type MutationinvestigatorSendLoginCodeArgs = {
  email: InputMaybe<Scalars['String']>;
  investigatorId: InputMaybe<Scalars['InvestigatorID']>;
};


export type MutationinvestigatorUpdateLocationArgs = {
  action: InvestigatorUpdateLocationActionInput;
};


export type MutationinvestigatorUpdateTrialArgs = {
  action: InvestigatorUpdateTrialActionInput;
};


export type MutationinvestigatorUpdateTrialOnboardingRequestArgs = {
  action: UpdateTrialOnboardingRequestInvestigatorActionInput;
};


export type MutationinvestigatorUpdateTrialSiteArgs = {
  action: InvestigatorUpdateTrialSiteActionInput;
};


export type MutationinviteInvestigatorToLocationArgs = {
  action: InviteInvestigatorToLocationActionInput;
};


export type MutationloginPatientArgs = {
  input: UserCredentialsInput;
};


export type MutationmergeConditionsArgs = {
  action: MergeConditionsActionInput;
};


export type MutationmergeLocationsArgs = {
  action: MergeLocationsActionInput;
};


export type MutationpasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationpatientCreateApplicationArgs = {
  autoApply: InputMaybe<Scalars['Boolean']>;
  trialId: Scalars['TrialID'];
};


export type MutationpatientLoginArgs = {
  input: UserCredentialsInput;
};


export type MutationpatientUpdateApplicationArgs = {
  action: PatientUpdateApplicationActionInput;
  patientId: InputMaybe<Scalars['PatientID']>;
};


export type MutationregisterInvestigatorArgs = {
  name: Scalars['String'];
};


export type MutationregisterPatientArgs = {
  input: RegisterRequestInput;
};


export type MutationremoveInvestigatorFromOrganisationArgs = {
  action: OrganisationRemoveInvestigatorActionInput;
};


export type MutationrequestEmrForCampaignPatientArgs = {
  campaignPatientId: Scalars['CampaignPatientID'];
  type: EmrRequestType;
};


export type MutationsendConversionsTrackingEventArgs = {
  input: ConversionsEventInput;
};


export type MutationsendLoginCodeArgs = {
  input: Scalars['String'];
};


export type MutationsendLoginCodeNewArgs = {
  input: Scalars['String'];
  preferredLoginCodeFlowType: LoginCodeFlowType;
};


export type MutationsendPhoneVerificationCodeArgs = {
  channel: PhoneVerificationChannelInput;
  phoneNumber: Scalars['String'];
};


export type MutationsetAndReturnTrialRulesFlatArgs = {
  rootAndTrialRule: RootAndTrialRuleInput;
  trialId: Scalars['TrialID'];
};


export type MutationsetMatchesSeenArgs = {
  trialIds: Array<Scalars['TrialID']>;
};


export type MutationsetupMfaArgs = {
  enable: Scalars['Boolean'];
};


export type MutationsubmitAnalyticsJobArgs = {
  input: AnalyticsJobConfigurationInput;
};


export type MutationsubmitAttributeValuesArgs = {
  attributeValueInputs: Array<AttributeValueInput>;
  isPhoneEvaluation: InputMaybe<Scalars['Boolean']>;
  patientId: InputMaybe<Scalars['PatientID']>;
  priorityTrialIds: InputMaybe<Array<Scalars['TrialID']>>;
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};


export type MutationsyncRecruitingStatusesWithRegistryArgs = {
  syncFrom: Scalars['DateTime'];
  syncTo: InputMaybe<Scalars['DateTime']>;
};


export type MutationsyncStudyTeamEventsArgs = {
  apiIntegrationId: Scalars['ApiIntegrationId'];
};


export type MutationsyncTranslationsForAttributesArgs = {
  attributeDefinitionIds: Array<Scalars['AttributeDefinitionID']>;
};


export type MutationsyncTranslationsForTrialArgs = {
  trialId: Scalars['TrialID'];
};


export type MutationtestInsightsQueryArgs = {
  currentJobEvaluationTime: Scalars['String'];
  currentJobId: Scalars['AnalyticsJobID'];
  previousJobEvaluationTime: Scalars['String'];
  previousJobId: Scalars['AnalyticsJobID'];
  trialId: Scalars['TrialID'];
};


export type MutationupdateApplicationSiteArgs = {
  action: UpdateApplicationSiteInput;
};


export type MutationupdateApplicationSitesArgs = {
  action: UpdateApplicationSitesInput;
};


export type MutationupdateAttributeDefinitionArgs = {
  updateAttributeDefinitionAction: UpdateAttributeDefinitionActionInput;
};


export type MutationupdateCampaignArgs = {
  action: UpdateCampaignActionInput;
};


export type MutationupdateCampaignPatientArgs = {
  input: CampaignPatientUpdateInput;
};


export type MutationupdateCommitmentArgs = {
  action: UpdateCommitmentActionInput;
};


export type MutationupdateConditionArgs = {
  action: UpdateConditionActionInput;
};


export type MutationupdateCurrentInsightsUserArgs = {
  action: UpdateCurrentInsightsUserActionInput;
};


export type MutationupdateCurrentInvestigatorArgs = {
  action: UpdateCurrentInvestigatorInput;
};


export type MutationupdateEmailTemplateArgs = {
  action: UpdateEmailTemplateActionInput;
};


export type MutationupdateIndicationArgs = {
  action: UpdateIndicationActionInput;
};


export type MutationupdateInsightsOrganisationArgs = {
  action: UpdateInsightsOrganisationActionInput;
};


export type MutationupdateInsightsUserArgs = {
  action: UpdateInsightsUserActionInput;
};


export type MutationupdateInvestigatorArgs = {
  action: UpdateInvestigatorActionInput;
};


export type MutationupdateLocationArgs = {
  action: UpdateLocationActionInput;
};


export type MutationupdateManagedInvestigatorArgs = {
  action: UpdateManagedInvestigatorActionInput;
};


export type MutationupdatePatientArgs = {
  id: InputMaybe<Scalars['PatientID']>;
  input: UpdatePatientInput;
};


export type MutationupdatePatientEmailArgs = {
  email: Scalars['String'];
  id: Scalars['PatientID'];
};


export type MutationupdatePreappArgs = {
  action: UpdatePreappActionInput;
};


export type MutationupdateRegistryStatusesArgs = {
  trialIDs: Array<Scalars['TrialID']>;
};


export type MutationupdateSponsorArgs = {
  action: UpdateSponsorActionInput;
};


export type MutationupdateTrialArgs = {
  action: UpdateTrialActionInput;
};


export type MutationverifyEmailArgs = {
  emailVerificationToken: Scalars['String'];
  id: Scalars['PatientID'];
};

export type NotTrialRule = TrialRuleSerialisable & {
  __typename: 'NotTrialRule';
  childRule: AndTrialRule | BooleanTrialRule | FloatTrialRule | HasTakenMedicationGroupTrialRule | HasTakenMedicationTrialRule | NotTrialRule | OrTrialRule | RadioTrialRule | RootAndTrialRule | ScreeningBooleanTrialRule;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
};

export type NotTrialRuleFlat = TrialRuleFlat & {
  __typename: 'NotTrialRuleFlat';
  childRule: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
};

export type NotTrialRuleInput = {
  childRule: TrialRuleInput;
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
};

export type OldTrialLocation = {
  __typename: 'OldTrialLocation';
  country: Scalars['CountryCode'];
  states: Maybe<Array<Scalars['StateCode']>>;
};

export type OneClickLoginInput = {
  oneClickLoginCode: Scalars['String'];
};

export enum Operator {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE'
}

export type OptionItem = {
  __typename: 'OptionItem';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  id: Scalars['String'];
  label: Scalars['String'];
};

export type OrTrialRule = TrialRuleSerialisable & {
  __typename: 'OrTrialRule';
  childRules: Array<AndTrialRule | BooleanTrialRule | FloatTrialRule | HasTakenMedicationGroupTrialRule | HasTakenMedicationTrialRule | NotTrialRule | OrTrialRule | RadioTrialRule | RootAndTrialRule | ScreeningBooleanTrialRule>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
};

export type OrTrialRuleFlat = TrialRuleFlat & {
  __typename: 'OrTrialRuleFlat';
  childRules: Array<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
};

export type OrTrialRuleInput = {
  childRules: Array<TrialRuleInput>;
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
};

export type Organisation = {
  __typename: 'Organisation';
  id: Scalars['OrganisationID'];
  investigators: Array<Investigator>;
  name: Scalars['String'];
};

export type OrganisationPatientEvaluationAnalyticsJobInput = {
  autoPublish: Scalars['Boolean'];
  evaluationTime: Scalars['String'];
  organisationId: Scalars['InsightsOrganisationID'];
};

export type OrganisationRemoveInvestigatorActionInput = {
  investigatorId: Scalars['InvestigatorID'];
  organisationIds: Array<Scalars['OrganisationID']>;
};

export type PageInput = {
  number: Scalars['Int'];
  size?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type PagedResult = {
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Patient = DeidentifiedPatientDetails & {
  __typename: 'Patient';
  applicationSites: Array<ApplicationSite>;
  applications: Array<Application>;
  attributeValues: Array<BooleanAttributeValue | FloatAttributeValue | MedicationAttributeValue | MedicationGroupAttributeValue | RadioAttributeValue | ScreeningBooleanAttributeValue>;
  /** @deprecated Patient may have multiple conditions. Use conditions field */
  conditionID: Scalars['ConditionID'];
  conditions: Array<Condition>;
  conditionsWithMatchingInfo: Maybe<Array<ConditionWithMatchingInfo>>;
  contactPreferences: Maybe<ContactPreferences>;
  country: Scalars['CountryCode'];
  dob: Maybe<Scalars['String']>;
  documentUploadPresignedPutUrl: Scalars['String'];
  documents: Array<DocumentMetadata>;
  email: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  ethnicity: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  hasAccountCreatedConversionEventBeenSent: Scalars['Boolean'];
  id: Scalars['PatientID'];
  lastName: Maybe<Scalars['String']>;
  lat: Scalars['String'];
  lng: Scalars['String'];
  /** @deprecated Use localeWithInfo instead */
  locale: LocaleCode;
  localeWithInfo: Locale;
  origin: Maybe<Scalars['String']>;
  patientEvents: Array<PatientContactedEvent>;
  phoneLastConfirmedAt: Maybe<Scalars['DateTime']>;
  phoneNumber: Maybe<Scalars['String']>;
  phoneVerifiedAt: Maybe<Scalars['String']>;
  racialCategory1: Maybe<Scalars['String']>;
  racialCategory2: Maybe<Scalars['String']>;
  sex: Scalars['String'];
  state: Scalars['StateCode'];
  suburb: Scalars['String'];
  suburbLastConfirmedAt: Maybe<Scalars['DateTime']>;
  termsVersion: Maybe<Scalars['Int']>;
  timezone: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};


export type PatientconditionsWithMatchingInfoArgs = {
  isPhoneEvaluation: InputMaybe<Scalars['Boolean']>;
  priorityTrialIds: InputMaybe<Array<Scalars['TrialID']>>;
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};


export type PatientdocumentUploadPresignedPutUrlArgs = {
  contentLength: Scalars['Int'];
  filename: Scalars['String'];
};


export type PatientpatientEventsArgs = {
  campaignId: InputMaybe<Scalars['CampaignId']>;
};

export type PatientContactedEvent = PatientEvent & {
  __typename: 'PatientContactedEvent';
  author: Maybe<Scalars['String']>;
  campaign: Maybe<Campaign>;
  contactChannel: ContactChannel;
  createdAt: Scalars['DateTime'];
};

export type PatientEvaluationAnalyticsJob = AnalyticsJob & {
  __typename: 'PatientEvaluationAnalyticsJob';
  configuration: PatientEvaluationAnalyticsJobConfiguration;
  createdAt: Maybe<Scalars['DateTime']>;
  getEvaluationTime: Scalars['DateTime'];
  id: Scalars['AnalyticsJobID'];
  processingFinishAt: Maybe<Scalars['DateTime']>;
  processingStartAt: Maybe<Scalars['DateTime']>;
  result: Maybe<PatientEvaluationAnalyticsJobResult>;
  status: AnalyticsJobStatus;
  submittedBy: Maybe<Scalars['String']>;
  totalPatientCount: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updateTotalPatientCount: PatientEvaluationAnalyticsJob;
};


export type PatientEvaluationAnalyticsJobupdateTotalPatientCountArgs = {
  totalPatientCount: InputMaybe<Scalars['Int']>;
};

export type PatientEvaluationAnalyticsJobConfiguration = {
  __typename: 'PatientEvaluationAnalyticsJobConfiguration';
  autoPublish: Scalars['Boolean'];
  evaluationTime: Scalars['String'];
  insightsOrganisationId: Scalars['InsightsOrganisationID'];
  trialId: Scalars['TrialID'];
};

export type PatientEvaluationAnalyticsJobConfigurationInput = {
  autoPublish: Scalars['Boolean'];
  evaluationTime: Scalars['String'];
  insightsOrganisationId: Scalars['InsightsOrganisationID'];
  trialId: Scalars['TrialID'];
};

export type PatientEvaluationAnalyticsJobResult = {
  __typename: 'PatientEvaluationAnalyticsJobResult';
  publish: Scalars['Boolean'];
  trialEncodingStatus: EncodingStatus;
  trialPatientRecruitingStatus: RecruitingStatus;
  trialSiteIds: Array<Scalars['TrialSiteID']>;
};

export type PatientEvent = {
  campaign: Maybe<Campaign>;
  createdAt: Scalars['DateTime'];
};

export type PatientFilterInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type PatientPagedResult = PagedResult & {
  __typename: 'PatientPagedResult';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  patients: Array<Patient>;
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum PatientRegistrationStatus {
  NOT_REGISTERED = 'NOT_REGISTERED',
  REGISTERED = 'REGISTERED',
  REGISTERED_FACEBOOK_LINKED = 'REGISTERED_FACEBOOK_LINKED'
}

export type PatientUpdateApplicationActionInput = {
  addTrialSite?: InputMaybe<AddTrialSiteInput>;
  cancelTrialSite?: InputMaybe<CancelTrialSiteInput>;
  id: Scalars['ApplicationID'];
};

export type PhoneVerificationChannel = {
  __typename: 'PhoneVerificationChannel';
  value: VerificationChannel;
};

export type PhoneVerificationChannelInput = {
  value: VerificationChannel;
};

export type Preapp = {
  __typename: 'Preapp';
  id: Scalars['PreappId'];
  interest: Maybe<Scalars['String']>;
  patient: Patient;
  trial: Trial;
};

export type PrimaryContactDetails = {
  __typename: 'PrimaryContactDetails';
  email: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  phoneCode: Maybe<Scalars['String']>;
};

export type Query = {
  __typename: 'Query';
  /** Union of all types that use the @key directive, including both types native to the schema and extended types */
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  agents: Array<AgentUser>;
  analyticsJob: Maybe<CountMatchesAnalyticsJob | CountMatchesPerSiteAnalyticsJob | DerivedConditionsAnalyticsJob | MatchProbabilityAnalyticsJob | PatientEvaluationAnalyticsJob>;
  apiErrorDummyQuery: ApiErrorCode;
  application: Maybe<Application>;
  applicationSite: Maybe<ApplicationSite>;
  applicationSiteSyncChangesets: ApplicationSiteSyncChangesetPagedResult;
  applicationSiteSyncEvents: ApplicationSiteSyncEventPagedResult;
  areUncompletedAnalyticsJobAvailable: Scalars['Boolean'];
  attributeDefinition: Maybe<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  authStatus: Maybe<AuthStatus>;
  campaign: Campaign;
  campaignCallListPatients: CampaignCallListPatientPagedResult;
  campaignCallListsByPatient: Array<CampaignCallListPatient>;
  campaignPatient: CampaignPatient;
  campaignPatientFilterOptions: CampaignPatientFilterOptions;
  campaignPatients: CampaignPatientPagedResult;
  campaigns: Array<Campaign>;
  commitments: Array<Commitment>;
  condition: Condition;
  conditions: Array<Condition>;
  currentInsightsUser: InsightsUser;
  currentInvestigator: Investigator;
  emailTemplate: EmailTemplate;
  findAllApplicationSitesForDownload: Array<ApplicationSite>;
  findAllOrganisations: Array<Organisation>;
  forecastingConditionsDashboardMetabaseToken: MetabaseGraphToken;
  getTranslationsForAttributeDefinitions: Array<Translation>;
  getTranslationsForCondition: Array<Translation>;
  getTranslationsForTrial: Array<Translation>;
  healthcheck: Scalars['String'];
  healthcheckIndications: Scalars['String'];
  homepageDashboardMetabaseToken: MetabaseGraphToken;
  indications: Array<Indication>;
  insightsOrganisation: InsightsOrganisation;
  insightsOrganisationPortfolioDashboardMetabaseToken: MetabaseGraphToken;
  insightsOrganisations: Array<InsightsOrganisation>;
  insightsUserByEncoderEmail: Maybe<InsightsUser>;
  insightsUsers: Array<InsightsUser>;
  investigator: Investigator;
  investigators: Array<Investigator>;
  locales: Array<Locale>;
  location: Location;
  locations: Array<Location>;
  mfa: MfaConfiguration;
  organisation: Organisation;
  /** @deprecated Use currentInvestigator { manageableInvestigators } instead */
  organisationUserAccess: Array<Investigator>;
  patient: Patient;
  patients: PatientPagedResult;
  preapp: Preapp;
  rawTrial: RawTrial;
  registrationStatus: RegistrationStatus;
  searchAnalyticsJobs: AnalyticsJobPagedResult;
  searchApplicationSites: ApplicationSitePagedResult;
  /** Returns a maximum of 100 search results */
  searchAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  searchInvestigators: Array<Investigator>;
  searchLocations: Array<Location>;
  searchRawTrialsPublic: Array<RawTrial>;
  searchSponsors: Array<Sponsor>;
  searchTrialOnboardingRequests: Array<TrialOnboardingRequest>;
  searchTrialSiteLocations: Array<TrialSiteLocationSearchResult>;
  searchTrialSites: Array<TrialSite>;
  searchTrials: Array<Trial>;
  searchTrialsPaged: TrialPagedResult;
  sponsor: Sponsor;
  suburbAutocomplete: Array<AutocompleteResult>;
  trial: Trial;
  trialByIdOpaque: Trial;
  trialCountByConditions: Scalars['Long'];
  trialOptimisationDashboardMetabaseToken: MetabaseGraphToken;
  trialPerformanceDashboardMetabaseToken: MetabaseGraphToken;
  /** @deprecated Use trialRulesFlat */
  trialRules: Maybe<RootAndTrialRule>;
  trialRulesFlat: TrialRulesFlat;
  trialSite: TrialSite;
  trialSiteInsightsDashboardMetabaseToken: MetabaseGraphToken;
  /** @deprecated We have 30,000 trial sites. We definitely don't want to return all of them. */
  trialSites: Array<TrialSite>;
  trialSitesById: Array<TrialSite>;
};


export type Query_entitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryanalyticsJobArgs = {
  id: Scalars['AnalyticsJobID'];
};


export type QueryapplicationArgs = {
  applicationId: Scalars['ApplicationID'];
};


export type QueryapplicationSiteArgs = {
  applicationSiteId: Scalars['ApplicationSiteID'];
};


export type QueryapplicationSiteSyncChangesetsArgs = {
  filter: InputMaybe<ApplicationSiteSyncChangesetFilterInput>;
  page: InputMaybe<PageInput>;
};


export type QueryapplicationSiteSyncEventsArgs = {
  page: PageInput;
};


export type QueryattributeDefinitionArgs = {
  id: Scalars['AttributeDefinitionID'];
  useReplica: InputMaybe<Scalars['Boolean']>;
};


export type QuerycampaignArgs = {
  id: Scalars['CampaignId'];
};


export type QuerycampaignCallListPatientsArgs = {
  campaignId: Scalars['CampaignId'];
  page: InputMaybe<PageInput>;
};


export type QuerycampaignCallListsByPatientArgs = {
  patientId: Scalars['PatientID'];
};


export type QuerycampaignPatientArgs = {
  id: Scalars['CampaignPatientID'];
};


export type QuerycampaignPatientFilterOptionsArgs = {
  assigneeEmail: Scalars['String'];
};


export type QuerycampaignPatientsArgs = {
  filter: InputMaybe<CampaignPatientFilterInput>;
  page: InputMaybe<PageInput>;
};


export type QueryconditionArgs = {
  id: Scalars['ConditionID'];
};


export type QueryconditionsArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};


export type QueryemailTemplateArgs = {
  id: Scalars['EmailTemplateID'];
};


export type QueryfindAllApplicationSitesForDownloadArgs = {
  filter: InputMaybe<SearchApplicationSitesFilterInput>;
};


export type QuerygetTranslationsForAttributeDefinitionsArgs = {
  attributeDefinitionIds: Array<Scalars['AttributeDefinitionID']>;
  localeCode: LocaleCode;
};


export type QuerygetTranslationsForConditionArgs = {
  conditionId: Scalars['ConditionID'];
  localeCode: LocaleCode;
};


export type QuerygetTranslationsForTrialArgs = {
  localeCode: LocaleCode;
  trialId: Scalars['TrialID'];
};


export type QueryhealthcheckArgs = {
  useDb: InputMaybe<Scalars['Boolean']>;
};


export type QueryinsightsOrganisationArgs = {
  id: Scalars['InsightsOrganisationID'];
};


export type QueryinvestigatorArgs = {
  id: Scalars['InvestigatorID'];
};


export type QuerylocationArgs = {
  id: Scalars['LocationID'];
};


export type QueryorganisationArgs = {
  id: Scalars['OrganisationID'];
};


export type QuerypatientArgs = {
  id: InputMaybe<Scalars['PatientID']>;
};


export type QuerypatientsArgs = {
  filter: InputMaybe<PatientFilterInput>;
  page: InputMaybe<PageInput>;
};


export type QuerypreappArgs = {
  id: Scalars['PreappId'];
};


export type QueryrawTrialArgs = {
  id: Scalars['TrialID'];
};


export type QueryregistrationStatusArgs = {
  emailInput: Scalars['String'];
};


export type QuerysearchAnalyticsJobsArgs = {
  filter: AnalyticsJobsSearchInput;
  page: InputMaybe<PageInput>;
};


export type QuerysearchApplicationSitesArgs = {
  filter: InputMaybe<SearchApplicationSitesFilterInput>;
  page: InputMaybe<PageInput>;
};


export type QuerysearchAttributeDefinitionsArgs = {
  searchFilter: SearchAttributeDefinitionInput;
};


export type QuerysearchInvestigatorsArgs = {
  filter: SearchInvestigatorsInput;
};


export type QuerysearchLocationsArgs = {
  filter: SearchLocationsInput;
};


export type QuerysearchRawTrialsPublicArgs = {
  filter: SearchRawTrialsPublicInput;
};


export type QuerysearchSponsorsArgs = {
  filter: SearchSponsorInput;
};


export type QuerysearchTrialOnboardingRequestsArgs = {
  filter: SearchTrialOnboardingRequestFilterInput;
};


export type QuerysearchTrialSiteLocationsArgs = {
  filter: TrialSiteLocationFilterInput;
};


export type QuerysearchTrialSitesArgs = {
  filter: SearchTrialSiteInput;
};


export type QuerysearchTrialsArgs = {
  filter: SearchTrialInput;
};


export type QuerysearchTrialsPagedArgs = {
  filter: SearchTrialInput;
  page: InputMaybe<PageInput>;
};


export type QuerysponsorArgs = {
  id: Scalars['SponsorID'];
};


export type QuerysuburbAutocompleteArgs = {
  country: Scalars['CountryCode'];
  input: Scalars['String'];
};


export type QuerytrialArgs = {
  id: Scalars['TrialID'];
};


export type QuerytrialByIdOpaqueArgs = {
  id: Scalars['TrialID'];
};


export type QuerytrialCountByConditionsArgs = {
  conditionIds: Array<Scalars['ConditionID']>;
};


export type QuerytrialOptimisationDashboardMetabaseTokenArgs = {
  trialId: Scalars['TrialID'];
};


export type QuerytrialPerformanceDashboardMetabaseTokenArgs = {
  trialId: Scalars['TrialID'];
};


export type QuerytrialRulesArgs = {
  trialId: Scalars['TrialID'];
};


export type QuerytrialRulesFlatArgs = {
  trialId: Scalars['TrialID'];
};


export type QuerytrialSiteArgs = {
  id: Scalars['TrialSiteID'];
};


export type QuerytrialSiteInsightsDashboardMetabaseTokenArgs = {
  trialId: Scalars['TrialID'];
};


export type QuerytrialSitesByIdArgs = {
  ids: Array<Scalars['TrialSiteID']>;
};

export type RadioAttributeDefinition = AttributeDefinition & {
  __typename: 'RadioAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  name: Scalars['String'];
  optionItems: Array<OptionItem>;
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  question: Scalars['String'];
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type RadioAttributeValue = AttributeValue & {
  __typename: 'RadioAttributeValue';
  attributeDefinition: RadioAttributeDefinition;
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
  radioValue: Maybe<Scalars['String']>;
};


export type RadioAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type RadioInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type RadioTrialRule = TrialRuleSerialisable & {
  __typename: 'RadioTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  expectedValues: Array<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  skippedResult: Scalars['Boolean'];
};

export type RadioTrialRuleFlat = TrialRuleFlat & {
  __typename: 'RadioTrialRuleFlat';
  attributeDefinition: RadioAttributeDefinition;
  description: Maybe<Scalars['String']>;
  expectedValues: Array<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  skippedResult: Scalars['Boolean'];
};

export type RadioTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  expectedValues: Array<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  skippedResult: Scalars['Boolean'];
};

export type RadioValue = ValueOrSkip & {
  __typename: 'RadioValue';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
  radioValue: Maybe<Scalars['String']>;
};

export type RawTrial = {
  __typename: 'RawTrial';
  briefSummary: Maybe<Scalars['String']>;
  briefTitle: Maybe<Scalars['String']>;
  conditionKeywordsList: Maybe<Scalars['String']>;
  conditionList: Maybe<Scalars['String']>;
  eligibilityCriteria: Maybe<Scalars['String']>;
  enrollmentCount: Maybe<Scalars['Int']>;
  healthyVolunteers: Maybe<Scalars['String']>;
  hmChangeLogJson: Maybe<Scalars['String']>;
  hmLastScrapedAt: Maybe<Scalars['String']>;
  hmReviewedAt: Maybe<Scalars['String']>;
  id: Scalars['TrialID'];
  lastUpdatePostDate: Maybe<Scalars['String']>;
  leadSponsorClass: Maybe<Scalars['String']>;
  leadSponsorName: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  maximumAge: Maybe<Scalars['String']>;
  minimumAge: Maybe<Scalars['String']>;
  officialTitle: Maybe<Scalars['String']>;
  overallRecruitmentStatus: Maybe<Scalars['String']>;
  phaseList: Maybe<Scalars['String']>;
  registry: Maybe<Scalars['String']>;
  studyFirstPostDate: Maybe<Scalars['String']>;
  studyFirstSubmitDate: Maybe<Scalars['String']>;
  studyType: Maybe<Scalars['String']>;
  trialJson: Maybe<Scalars['String']>;
};

export enum RecruitingStatus {
  completed = 'completed',
  paused = 'paused',
  recruiting = 'recruiting'
}

export type RegisterInvestigatorActionInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type RegisterRequestInput = {
  conditionID: Scalars['ConditionID'];
  country?: InputMaybe<Scalars['CountryCode']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailValidation?: InputMaybe<Scalars['Boolean']>;
  facebookAuth?: InputMaybe<FacebookAuthInput>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['GooglePlaceId']>;
  lastName?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['String']>;
  lng?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<LocaleCode>;
  password?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['StateCode']>;
  suburb?: InputMaybe<Scalars['String']>;
  termsVersion?: InputMaybe<Scalars['Int']>;
};

export type RegistrationStatus = {
  __typename: 'RegistrationStatus';
  patientRegistrationStatus: PatientRegistrationStatus;
};

export type RemoveConditionAliasInput = {
  alias: Scalars['String'];
};

export type RemoveDerivedConditionActionInput = {
  id: Scalars['DerivedConditionRuleID'];
};

export type RemoveInvestigatorInput = {
  id: Scalars['InvestigatorID'];
};

export type RemoveOutgoingDerivedAttributeActionInput = {
  id: Scalars['DerivedAttributeRuleID'];
};

export enum RequestStatus {
  Approved = 'Approved',
  New = 'New',
  Rejected = 'Rejected'
}

export type Response = {
  __typename: 'Response';
  status: Scalars['String'];
};

export enum Role {
  ADMIN_ENCODER = 'ADMIN_ENCODER',
  AGENT = 'AGENT',
  ENCODER = 'ENCODER',
  INSIGHTS_USER = 'INSIGHTS_USER',
  INVESTIGATOR = 'INVESTIGATOR',
  PATIENT = 'PATIENT',
  SERVICE_USER = 'SERVICE_USER'
}

export type RootAndTrialRule = TrialRuleSerialisable & {
  __typename: 'RootAndTrialRule';
  childRules: Array<AndTrialRule | BooleanTrialRule | FloatTrialRule | HasTakenMedicationGroupTrialRule | HasTakenMedicationTrialRule | NotTrialRule | OrTrialRule | RadioTrialRule | RootAndTrialRule | ScreeningBooleanTrialRule>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
};

export type RootAndTrialRuleFlat = TrialRuleFlat & {
  __typename: 'RootAndTrialRuleFlat';
  childRules: Array<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
};

export type RootAndTrialRuleInput = {
  childRules: Array<TrialRuleInput>;
  description?: InputMaybe<Scalars['String']>;
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
};

export type ScreenFormEmailInput = {
  screeningFormUrl: Scalars['String'];
};

export type ScreeningBooleanAttributeDefinition = AttributeDefinition & {
  __typename: 'ScreeningBooleanAttributeDefinition';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  derivedConditions: Array<DerivedConditionRule>;
  expression: Maybe<Scalars['String']>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['AttributeDefinitionID'];
  incomingDerivedAttributes: Array<DerivedAttribute>;
  name: Scalars['String'];
  outgoingDerivedAttributes: Array<DerivedAttribute>;
  question: Scalars['String'];
  requiredAttributeDefinitions: Array<BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition>;
  updatedAt: Maybe<Scalars['String']>;
  usages: AttributeDefinitionUsage;
};

export type ScreeningBooleanAttributeValue = AttributeValue & {
  __typename: 'ScreeningBooleanAttributeValue';
  attributeDefinition: ScreeningBooleanAttributeDefinition;
  booleanValue: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  id: Scalars['AttributeValueID'];
  isSkipped: Scalars['Boolean'];
};


export type ScreeningBooleanAttributeValueattributeDefinitionArgs = {
  useReplica: InputMaybe<Scalars['Boolean']>;
};

export type ScreeningBooleanTrialRule = TrialRuleSerialisable & {
  __typename: 'ScreeningBooleanTrialRule';
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description: Maybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  skippedResult: Scalars['Boolean'];
};

export type ScreeningBooleanTrialRuleFlat = TrialRuleFlat & {
  __typename: 'ScreeningBooleanTrialRuleFlat';
  attributeDefinition: ScreeningBooleanAttributeDefinition;
  description: Maybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
  skippedResult: Scalars['Boolean'];
};

export type ScreeningBooleanTrialRuleInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  description?: InputMaybe<Scalars['String']>;
  expectedValue: Scalars['Boolean'];
  heuristicPassRate?: InputMaybe<Scalars['Float']>;
  isPhoneRule?: InputMaybe<Scalars['Boolean']>;
  skippedResult: Scalars['Boolean'];
};

export type SearchAnalyticsJobsAnalyticsJobStatusInput = {
  equals: Array<AnalyticsJobStatus>;
};

export type SearchApplicationIdEqualsInput = {
  equals: Scalars['ApplicationID'];
};

export type SearchApplicationSiteRejectReasonStatusEqualsInput = {
  equals: ApplicationSiteRejectReasonStatus;
};

export type SearchApplicationSitesFilterInput = {
  applicationId?: InputMaybe<SearchApplicationIdEqualsInput>;
  appliedAtDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  callAttemptsRange?: InputMaybe<SearchIntBetweenInput>;
  countries?: InputMaybe<SearchStringInListInput>;
  email?: InputMaybe<SearchStringEqualsInput>;
  followUpDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  lastContactAttemptAtDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  locationName?: InputMaybe<SearchStringEqualsInput>;
  phone?: InputMaybe<SearchStringEqualsInput>;
  preferredLanguage?: InputMaybe<SearchStringEqualsInput>;
  rejectReason?: InputMaybe<SearchApplicationSiteRejectReasonStatusEqualsInput>;
  rejectedAtDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  scheduledVisitAtDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  screeningConsentSignedDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
  searchText?: InputMaybe<SearchStringLikeInput>;
  sex?: InputMaybe<SearchStringEqualsInput>;
  state?: InputMaybe<SearchStringEqualsInput>;
  statusScreening?: InputMaybe<SearchApplicationStatusScreeningEqualsInput>;
  suburb?: InputMaybe<SearchStringEqualsInput>;
  trialId?: InputMaybe<SearchTrialIdEqualsInput>;
  trialSiteId?: InputMaybe<SearchTrialSiteIdEqualsInput>;
  updatedAtDateRange?: InputMaybe<SearchOffsetDateTimeBetweenInput>;
};

export type SearchApplicationStatusScreeningEqualsInput = {
  equals: ApplicationStatusScreening;
};

export type SearchAttributeDefinitionInput = {
  textFields?: InputMaybe<SearchAttributeDefinitionTextFieldsInput>;
};

export type SearchAttributeDefinitionTextFieldsInput = {
  matches?: InputMaybe<Scalars['String']>;
};

export type SearchIntBetweenInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type SearchInvestigatorIdInput = {
  equals?: InputMaybe<Scalars['InvestigatorID']>;
};

export type SearchInvestigatorTrialSiteInput = {
  locationId?: InputMaybe<SearchLocationIdInput>;
  trialId?: InputMaybe<SearchTrialIdInput>;
};

export type SearchInvestigatorsInput = {
  investigatorId?: InputMaybe<Scalars['InvestigatorID']>;
  textMatches?: InputMaybe<Scalars['String']>;
};

export type SearchLocationIdInput = {
  equals: Scalars['LocationID'];
};

export type SearchLocationsInput = {
  textMatches?: InputMaybe<Scalars['String']>;
};

export type SearchOffsetDateTimeBetweenInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type SearchRawTrialsPublicInput = {
  searchText: Scalars['String'];
};

export type SearchSponsorInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type SearchStringEqualsInput = {
  equals: Scalars['String'];
};

export type SearchStringInListInput = {
  equals: Array<Scalars['String']>;
};

export type SearchStringLikeInput = {
  like: Scalars['String'];
};

export type SearchTrialConditionIdInput = {
  equals: Scalars['ConditionID'];
};

export type SearchTrialConditionIdsInput = {
  oneOf: Array<Scalars['ConditionID']>;
};

export type SearchTrialConditionNameInput = {
  like: Scalars['String'];
};

export type SearchTrialCountryCodesInput = {
  equals: Array<Scalars['String']>;
};

export type SearchTrialEncodingStatusInput = {
  equals?: InputMaybe<EncodingStatus>;
};

export type SearchTrialIdEqualsInput = {
  equals: Scalars['TrialID'];
};

export type SearchTrialIdInput = {
  equals: Scalars['TrialID'];
};

export type SearchTrialInput = {
  conditionId?: InputMaybe<SearchTrialConditionIdInput>;
  conditionIds?: InputMaybe<SearchTrialConditionIdsInput>;
  conditionName?: InputMaybe<SearchTrialConditionNameInput>;
  countryCodes?: InputMaybe<SearchTrialCountryCodesInput>;
  encodingStatus?: InputMaybe<SearchTrialEncodingStatusInput>;
  nickname?: InputMaybe<SearchTrialNicknameInput>;
  patientRecruitingStatuses?: InputMaybe<SearchTrialPatientRecruitingStatusesInput>;
  searchText?: InputMaybe<SearchTrialSearchTextInput>;
  states?: InputMaybe<SearchTrialStatesInput>;
};

export type SearchTrialNicknameInput = {
  like: Scalars['String'];
};

export type SearchTrialOnboardingRequestFilterInput = {
  requestStatus?: InputMaybe<RequestStatus>;
};

export type SearchTrialPatientRecruitingStatusesInput = {
  equals: Array<RecruitingStatus>;
};

export type SearchTrialSearchTextInput = {
  like: Scalars['String'];
};

export type SearchTrialSiteIdEqualsInput = {
  equals: Scalars['TrialSiteID'];
};

export type SearchTrialSiteInput = {
  investigatorId?: InputMaybe<SearchInvestigatorIdInput>;
  locationId?: InputMaybe<SearchLocationIdInput>;
  trialId?: InputMaybe<SearchTrialIdInput>;
};

export type SearchTrialStatesInput = {
  equals: Array<Scalars['String']>;
};

export enum SearchType {
  AND = 'AND',
  OR = 'OR'
}

export type SendLoginCodeResponse = {
  __typename: 'SendLoginCodeResponse';
  loginCodeDestination: Maybe<Scalars['String']>;
  loginCodeFlowType: LoginCodeFlowType;
};

export type SetAnalyticsConfigurationInput = {
  setCampaignNotes?: InputMaybe<SetOptionalStringInput>;
  setCountryCode?: InputMaybe<SetCountryCodeInput>;
  setEstimatedConsentToShareProb?: InputMaybe<SetOptionalDoubleInput>;
  setEstimatedPrescreenCompletedProb?: InputMaybe<SetOptionalDoubleInput>;
  setEstimatedPrescreenPassedProb?: InputMaybe<SetOptionalDoubleInput>;
  setEstimatedReferralsOutsideDatabase?: InputMaybe<SetOptionalIntegerInput>;
  setEstimatedTrialConditionMatchProb?: InputMaybe<SetOptionalDoubleInput>;
  setEvaluationDistances?: InputMaybe<SetEvaluationDistancesInput>;
  setIsFeaso?: InputMaybe<SetBooleanInput>;
};

export type SetApplicationModeInput = {
  applicationMode: ApplicationMode;
  applicationModeReason: ApplicationModeReason;
  sendInstructionsToPendingApps?: InputMaybe<Scalars['Boolean']>;
};

export type SetApplicationStatusInput = {
  applicationStatusScreening?: InputMaybe<ApplicationStatusScreening>;
};

export type SetBdStatusInput = {
  bdStatus: BdStatus;
};

export type SetBooleanInput = {
  value: Scalars['Boolean'];
};

export type SetCampaignPatientOutcomeInput = {
  value?: InputMaybe<CampaignPatientOutcome>;
};

export type SetCampaignPatientStatusInput = {
  value: CampaignPatientStatus;
};

export type SetCampaignTypeInput = {
  value: CampaignType;
};

export type SetCommitmentsInput = {
  commitmentIds: Array<Scalars['CommitmentId']>;
};

export type SetCompensationTypeInput = {
  compensationType: CompensationOption;
};

export type SetConditionsInput = {
  conditionIDs: Array<Scalars['ConditionID']>;
};

export type SetCountryCodeInput = {
  value: Scalars['CountryCode'];
};

export type SetEncodingStatusInput = {
  encodingStatus: EncodingStatus;
};

export type SetEvaluationDistancesInput = {
  evaluationDistances?: InputMaybe<Array<Scalars['Int']>>;
};

export type SetFloatInput = {
  value: Scalars['Float'];
};

export type SetGooglePlaceIdInput = {
  value: Scalars['GooglePlaceId'];
};

export type SetIndicationIdInput = {
  value: Scalars['IndicationID'];
};

export type SetInvestigatorIdInput = {
  investigatorId: Scalars['InvestigatorID'];
};

export type SetLabelsInput = {
  labels: Array<Scalars['String']>;
};

export type SetLocalesInput = {
  codes: Array<LocaleCode>;
};

export type SetLocationIdInput = {
  id: Scalars['LocationID'];
};

export type SetMandatoryAttributeDefinitionIdsInput = {
  mandatoryAttributeDefinitionIds: Array<Scalars['AttributeDefinitionID']>;
};

export type SetNullableStringInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type SetOptionalBooleanInput = {
  value?: InputMaybe<Scalars['Boolean']>;
};

export type SetOptionalContactPreferencesChannelsInput = {
  values?: InputMaybe<Array<ContactPreferencesChannel>>;
};

export type SetOptionalDateInput = {
  date?: InputMaybe<Scalars['String']>;
};

export type SetOptionalDoubleInput = {
  value?: InputMaybe<Scalars['Float']>;
};

export type SetOptionalIntegerInput = {
  value?: InputMaybe<Scalars['Int']>;
};

export type SetOptionalMaxDistanceInput = {
  value?: InputMaybe<MaxDistance>;
};

export type SetOptionalStateCodeInput = {
  value?: InputMaybe<Scalars['StateCode']>;
};

export type SetOptionalStringInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type SetPatientRecruitingStatusInput = {
  recruitingStatus: RecruitingStatus;
};

export type SetPrimaryContactInvestigatorIdInput = {
  id?: InputMaybe<Scalars['InvestigatorID']>;
};

export type SetRejectReasonInput = {
  additionalFeedback: Scalars['String'];
  rejectReason: ApplicationSiteRejectReasonStatus;
};

export type SetRequestStatusInput = {
  requestStatus: RequestStatus;
};

export type SetRestoreRejectedApplicationSiteInput = {
  newScreeningStatus: ApplicationStatusScreening;
};

export type SetShouldSendApiInput = {
  value: ShouldSendApi;
};

export type SetSponsorInput = {
  sponsorID?: InputMaybe<Scalars['SponsorID']>;
};

export type SetStringInput = {
  value: Scalars['String'];
};

export type SetStructuredWeeklyScheduleInput = {
  weeklySchedule: ContactPreferencesWeeklyScheduleInput;
};

export type SetTrialSiteRecruitingStatusInput = {
  value: RecruitingStatus;
};

export enum ShouldSendApi {
  NEVER = 'NEVER',
  ON_APPLICATION_CREATED = 'ON_APPLICATION_CREATED',
  ON_APPLICATION_VISIBLE = 'ON_APPLICATION_VISIBLE'
}

export type Skip = ValueOrSkip & {
  __typename: 'Skip';
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
};

export type SkipInput = {
  dummyValue?: InputMaybe<Scalars['String']>;
};

export type SortColumnInput = {
  columnName: Scalars['String'];
  direction: SortDirection;
};

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export type SortOrderInput = {
  sortColumns: Array<SortColumnInput>;
};

export type Sponsor = {
  __typename: 'Sponsor';
  aliases: Array<Scalars['String']>;
  id: Scalars['SponsorID'];
  name: Scalars['String'];
  sponsorClass: Maybe<Scalars['String']>;
};

export type SponsorLeadInput = {
  company?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  enquiry: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type SuburbWithStats = {
  __typename: 'SuburbWithStats';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  match: Scalars['Int'];
  matchWithSkips: Scalars['Int'];
  name: Scalars['String'];
  needsMoreAnswers: Scalars['Int'];
  notMatch: Scalars['Int'];
  notMatchWithSkips: Scalars['Int'];
};

export enum SyncStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW'
}

export enum SyncType {
  VELOCITY_FILE_SYNC = 'VELOCITY_FILE_SYNC'
}

export type TermsVersion = {
  __typename: 'TermsVersion';
  key: Scalars['String'];
  version: Scalars['Int'];
};

export type TermsVersionInput = {
  key: Scalars['String'];
  version: Scalars['Int'];
};

export type Timezone = {
  __typename: 'Timezone';
  displayName: Scalars['String'];
  id: Scalars['String'];
  zoneId: Scalars['String'];
};

export type Translation = {
  __typename: 'Translation';
  id: Scalars['String'];
  propertyValue: Scalars['String'];
  translatedValue: Maybe<Scalars['String']>;
};

export type Trial = {
  __typename: 'Trial';
  ageRequirements: Maybe<Array<AgeRequirement>>;
  analyticsConfiguration: AnalyticsConfiguration;
  anonymisedApplicationCount: Scalars['Int'];
  applicationCountForInsightsUser: Scalars['Int'];
  bdStatus: BdStatus;
  comment: Maybe<Scalars['String']>;
  commitmentInPerson: Maybe<Scalars['String']>;
  commitmentIsTelehealth: Scalars['Boolean'];
  commitmentLength: Maybe<Scalars['String']>;
  commitmentTelehealth: Maybe<Scalars['String']>;
  commitments: Array<Commitment>;
  compensationTextOverride: Maybe<Scalars['String']>;
  compensationType: CompensationOption;
  conditions: Array<Condition>;
  createdAt: Scalars['DateTime'];
  dateOfMostRecentApplication: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  encodingStatus: EncodingStatus;
  endDate: Maybe<Scalars['DateTime']>;
  exactApplicationCount: Scalars['Int'];
  genderRequirements: Maybe<GenderRequirements>;
  heatmapStats: Array<SuburbWithStats>;
  id: Scalars['TrialID'];
  idOpaque: Scalars['TrialID'];
  isAutovalidatingApplications: Scalars['Boolean'];
  isEmrTrial: Scalars['Boolean'];
  isPhoneTrial: Scalars['Boolean'];
  link: Scalars['String'];
  locations: Array<OldTrialLocation>;
  matchSummary: Maybe<TrialMatchingResult>;
  /** @deprecated Use title instead */
  name: Scalars['String'];
  nickname: Maybe<Scalars['String']>;
  patientEvaluationAnalyticsJobStatistics: Array<EthnicityStats>;
  patientRecruitingStatus: RecruitingStatus;
  preapps: Array<Preapp>;
  protocolId: Maybe<Scalars['String']>;
  rawTrial: Maybe<RawTrial>;
  registry: Scalars['String'];
  registryRecruitingStatus: Maybe<Scalars['String']>;
  registryTrialName: Maybe<Scalars['String']>;
  sponsor: Maybe<Sponsor>;
  startDate: Maybe<Scalars['DateTime']>;
  /** @deprecated Use title instead */
  summary: Scalars['String'];
  title: Scalars['String'];
  trialSites: Array<TrialSite>;
  trialSitesWithPatientEvaluationAnalyticsJobStatistics: Array<TrialSiteWithPatientEvaluationStats>;
};


export type TrialexactApplicationCountArgs = {
  filter: ApplicationCountFilterInput;
};


export type TrialheatmapStatsArgs = {
  limit: Scalars['Int'];
};


export type TriallocationsArgs = {
  useLongDistanceMatching: InputMaybe<Scalars['Boolean']>;
};


export type TrialpreappsArgs = {
  patientId: Scalars['PatientID'];
};


export type TrialtrialSitesArgs = {
  filter: InputMaybe<TrialSiteFilterInput>;
};

export type TrialMatchingResult = {
  __typename: 'TrialMatchingResult';
  hasMissingAnswers: Scalars['Int'];
  skippedFalse: Scalars['Int'];
  skippedTrue: Scalars['Int'];
  total: Scalars['Int'];
  trialId: Scalars['TrialID'];
  unskippedFalse: Scalars['Int'];
  unskippedTrue: Scalars['Int'];
};

export type TrialOnboardingRequest = {
  __typename: 'TrialOnboardingRequest';
  id: Scalars['TrialOnboardingRequestId'];
  investigator: Investigator;
  requestStatus: RequestStatus;
  requestedTrialId: Scalars['String'];
  typeformCompleted: Scalars['Boolean'];
};

export type TrialOnboardingRequestInput = {
  requestedTrialId: Scalars['String'];
};

export type TrialPagedResult = PagedResult & {
  __typename: 'TrialPagedResult';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalElements: Scalars['Int'];
  totalPages: Scalars['Int'];
  trials: Array<Trial>;
};

export type TrialRuleFlat = {
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
  linkingId: Scalars['Int'];
};

export type TrialRuleInput = {
  and?: InputMaybe<AndTrialRuleInput>;
  bool?: InputMaybe<BooleanTrialRuleInput>;
  float?: InputMaybe<FloatTrialRuleInput>;
  hasTakenMedication?: InputMaybe<HasTakenMedicationTrialRuleInput>;
  hasTakenMedicationGroup?: InputMaybe<HasTakenMedicationGroupTrialRuleInput>;
  not?: InputMaybe<NotTrialRuleInput>;
  or?: InputMaybe<OrTrialRuleInput>;
  radio?: InputMaybe<RadioTrialRuleInput>;
  root?: InputMaybe<RootAndTrialRuleInput>;
  screening?: InputMaybe<ScreeningBooleanTrialRuleInput>;
};

export type TrialRuleSerialisable = {
  description: Maybe<Scalars['String']>;
  heuristicPassRate: Maybe<Scalars['Float']>;
  isPhoneRule: Scalars['Boolean'];
};

export type TrialRulesFlat = {
  __typename: 'TrialRulesFlat';
  id: Scalars['TrialID'];
  rules: Array<AndTrialRuleFlat | BooleanTrialRuleFlat | FloatTrialRuleFlat | HasTakenMedicationGroupTrialRuleFlat | HasTakenMedicationTrialRuleFlat | NotTrialRuleFlat | OrTrialRuleFlat | RadioTrialRuleFlat | RootAndTrialRuleFlat | ScreeningBooleanTrialRuleFlat>;
};

export type TrialSite = {
  __typename: 'TrialSite';
  acceptsOutOfState: Maybe<Scalars['Boolean']>;
  applicationMode: ApplicationMode;
  applicationModeReason: Maybe<ApplicationModeReason>;
  bookingLink: Maybe<Scalars['String']>;
  comments: Maybe<Scalars['String']>;
  compensationTextOverride: Maybe<Scalars['String']>;
  compensationType: CompensationOption;
  emailTemplate: EmailTemplate;
  exactApplicationCount: Scalars['Int'];
  hasPendingApplicationSitesForInvestigator: Scalars['Boolean'];
  /** @deprecated Use hasPendingApplicationSitesForInvestigator */
  hasPendingApplicationsForInvestigator: Scalars['Boolean'];
  id: Scalars['TrialSiteID'];
  inboundCallingConfig: Maybe<TrialSiteInboundCallingConfig>;
  investigators: Array<Investigator>;
  isAutoApply: Scalars['Boolean'];
  isShowingApplications: Scalars['Boolean'];
  location: Location;
  maxDistance: Maybe<MaxDistance>;
  nickname: Maybe<Scalars['String']>;
  primaryContactDetails: Maybe<PrimaryContactDetails>;
  primaryContactInvestigatorId: Maybe<Scalars['InvestigatorID']>;
  recruitingStatus: RecruitingStatus;
  registryRecruitingStatus: Maybe<Scalars['String']>;
  screeningFormLink: Maybe<Scalars['String']>;
  shouldSendApi: ShouldSendApi;
  supportsInboundCalling: Scalars['Boolean'];
  trial: Trial;
};


export type TrialSiteexactApplicationCountArgs = {
  filter: ApplicationCountFilterInput;
};

export type TrialSiteAndMatchingResult = {
  __typename: 'TrialSiteAndMatchingResult';
  matchingResult: MatchingResult;
  matchingResultUnique: MatchingResult;
  matchingResultUniqueMatchingCondition: MatchingResult;
  trialSite: TrialSite;
  trialSiteId: Scalars['TrialSiteID'];
};

export type TrialSiteFilterInput = {
  filterToEligibleSites?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['PatientID']>;
  useLongDistanceMatching?: InputMaybe<Scalars['Boolean']>;
};

export type TrialSiteInboundCallingConfig = {
  __typename: 'TrialSiteInboundCallingConfig';
  enabled: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
};

export type TrialSiteLocationFilterInput = {
  search: Scalars['String'];
};

export type TrialSiteLocationSearchResult = {
  __typename: 'TrialSiteLocationSearchResult';
  address: Scalars['String'];
  countryCode: Maybe<Scalars['CountryCode']>;
  googlePlaceIds: Array<Scalars['GooglePlaceId']>;
  latitude: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['LocationID']>;
  longitude: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  state: Maybe<Scalars['StateCode']>;
};

export type TrialSiteWithPatientEvaluationStats = {
  __typename: 'TrialSiteWithPatientEvaluationStats';
  stats: Array<EthnicityStats>;
  trialSite: TrialSite;
};

export type UpdateApplicationSiteInput = {
  id: Scalars['ApplicationSiteID'];
  rejectReason?: InputMaybe<SetRejectReasonInput>;
  setDateOfEnrollment?: InputMaybe<SetOptionalDateInput>;
  setDateOfScheduledVisit?: InputMaybe<SetOptionalDateInput>;
  setDateOfScreeningConsentSigned?: InputMaybe<SetOptionalDateInput>;
  setFollowUpDate?: InputMaybe<SetOptionalDateInput>;
  setHasAttendedAppointment?: InputMaybe<SetBooleanInput>;
  setLongDistanceReason?: InputMaybe<SetOptionalStringInput>;
  setNote?: InputMaybe<SetOptionalStringInput>;
  setRestoreRejectedApplicationSite?: InputMaybe<SetRestoreRejectedApplicationSiteInput>;
  setSitePatientExternalId?: InputMaybe<SetOptionalStringInput>;
  setSponsorRecordExternalId?: InputMaybe<SetOptionalStringInput>;
  setStatus?: InputMaybe<SetApplicationStatusInput>;
};

export type UpdateApplicationSitesInput = {
  ids: Array<Scalars['ApplicationSiteID']>;
  setDateOfEnrollment?: InputMaybe<SetOptionalDateInput>;
  setDateOfScheduledVisit?: InputMaybe<SetOptionalDateInput>;
  setDateOfScreeningConsentSigned?: InputMaybe<SetOptionalDateInput>;
  setFollowUpDate?: InputMaybe<SetOptionalDateInput>;
  setHasAttendedAppointment?: InputMaybe<SetBooleanInput>;
  setLongDistanceReason?: InputMaybe<SetOptionalStringInput>;
  setNote?: InputMaybe<SetOptionalStringInput>;
  setRejectReason?: InputMaybe<SetRejectReasonInput>;
  setRestoreRejectedApplicationSite?: InputMaybe<SetRestoreRejectedApplicationSiteInput>;
  setSitePatientExternalId?: InputMaybe<SetOptionalStringInput>;
  setSponsorRecordExternalId?: InputMaybe<SetOptionalStringInput>;
  setStatus?: InputMaybe<SetApplicationStatusInput>;
};

export type UpdateAttributeDefinitionActionInput = {
  attributeDefinitionId: Scalars['AttributeDefinitionID'];
  setExpression?: InputMaybe<SetStringInput>;
  setName?: InputMaybe<SetStringInput>;
  updateBoolean?: InputMaybe<UpdateBooleanActionInput>;
  updateDerivedAttributes?: InputMaybe<UpdateDerivedAttributesActionInput>;
  updateDerivedConditions?: InputMaybe<UpdateDerivedConditionsActionInput>;
  updateFloat?: InputMaybe<UpdateFloatActionInput>;
  updateMedication?: InputMaybe<UpdateMedicationActionInput>;
  updateMedicationGroup?: InputMaybe<UpdateMedicationGroupActionInput>;
  updateRadio?: InputMaybe<UpdateRadioActionInput>;
  updateRequiredAttributes?: InputMaybe<UpdateRequiredAttributesActionInput>;
  updateScreeningBoolean?: InputMaybe<UpdateBooleanActionInput>;
};

export type UpdateBooleanActionInput = {
  setComment?: InputMaybe<SetOptionalStringInput>;
  setHelpText?: InputMaybe<SetOptionalStringInput>;
  setQuestion?: InputMaybe<SetStringInput>;
};

export type UpdateCampaignActionInput = {
  addTrial?: InputMaybe<Scalars['TrialID']>;
  id: Scalars['CampaignId'];
  removeTrial?: InputMaybe<Scalars['TrialID']>;
  setName?: InputMaybe<SetStringInput>;
  setType?: InputMaybe<SetCampaignTypeInput>;
  updateCampaignTrialConfig?: InputMaybe<CampaignTrialConfigInput>;
};

export type UpdateCommitmentActionInput = {
  commitmentId: Scalars['CommitmentId'];
  setDisplayText?: InputMaybe<SetStringInput>;
  setTitle?: InputMaybe<SetStringInput>;
};

export type UpdateConditionActionInput = {
  addConditionAlias?: InputMaybe<AddConditionAliasInput>;
  conditionId: Scalars['ConditionID'];
  removeConditionAlias?: InputMaybe<RemoveConditionAliasInput>;
  setIndicationId?: InputMaybe<SetIndicationIdInput>;
  setMandatoryAttributeDefinitionIds?: InputMaybe<SetMandatoryAttributeDefinitionIdsInput>;
  setName?: InputMaybe<SetStringInput>;
};

export type UpdateCurrentInsightsUserActionInput = {
  setName?: InputMaybe<SetOptionalStringInput>;
  setTermsVersions?: InputMaybe<Array<TermsVersionInput>>;
};

export type UpdateCurrentInvestigatorInput = {
  setName?: InputMaybe<SetStringInput>;
  setPhone?: InputMaybe<SetOptionalStringInput>;
  setPhoneCode?: InputMaybe<SetOptionalStringInput>;
  setPosition?: InputMaybe<SetOptionalStringInput>;
  setTermsVersions?: InputMaybe<Array<TermsVersionInput>>;
};

export type UpdateDerivedAttributesActionInput = {
  addOutgoingDerivedAttribute?: InputMaybe<AddOutgoingDerivedAttributesActionInput>;
  removeOutgoingDerivedAttribute?: InputMaybe<RemoveOutgoingDerivedAttributeActionInput>;
  updateOutgoingDerivedAttribute?: InputMaybe<UpdateOutgoingDerivedAttributeActionInput>;
};

export type UpdateDerivedConditionActionInput = {
  id: Scalars['DerivedConditionRuleID'];
  setConditionIdTo?: InputMaybe<Scalars['ConditionID']>;
  setHasCondition?: InputMaybe<Scalars['Boolean']>;
  setValueFrom?: InputMaybe<ValueOrSkipInput>;
};

export type UpdateDerivedConditionsActionInput = {
  addDerivedCondition?: InputMaybe<AddDerivedConditionActionInput>;
  removeDerivedCondition?: InputMaybe<RemoveDerivedConditionActionInput>;
  updateDerivedCondition?: InputMaybe<UpdateDerivedConditionActionInput>;
};

export type UpdateEmailTemplateActionInput = {
  id: Scalars['EmailTemplateID'];
  setCalendarBookingEmail?: InputMaybe<CalendarBookingEmailInput>;
  setContactInfoEmail?: InputMaybe<ContactInfoEmailInput>;
  setGenericEmail?: InputMaybe<SetOptionalStringInput>;
  setScreeningFormEmail?: InputMaybe<ScreenFormEmailInput>;
};

export type UpdateFloatActionInput = {
  setComment?: InputMaybe<SetOptionalStringInput>;
  setHelpText?: InputMaybe<SetOptionalStringInput>;
  setQuestion?: InputMaybe<SetStringInput>;
  setUnits?: InputMaybe<SetStringInput>;
};

export type UpdateIndicationActionInput = {
  indicationId: Scalars['IndicationID'];
  setName?: InputMaybe<SetStringInput>;
  setSubject?: InputMaybe<SetStringInput>;
};

export type UpdateInsightsOrganisationActionInput = {
  insightsOrganisationId: Scalars['InsightsOrganisationID'];
  setDashboardConfiguration?: InputMaybe<DashboardConfigurationInput>;
  setName?: InputMaybe<SetStringInput>;
  setTrialSiteIds?: InputMaybe<Array<Scalars['TrialSiteID']>>;
};

export type UpdateInsightsUserActionInput = {
  insightsUserId: Scalars['InsightsUserID'];
  setEmail?: InputMaybe<SetStringInput>;
  setInsightsOrganisationId?: InputMaybe<Scalars['InsightsOrganisationID']>;
  setName?: InputMaybe<SetOptionalStringInput>;
};

export type UpdateInvestigatorActionInput = {
  addLocation?: InputMaybe<SetLocationIdInput>;
  addLocations?: InputMaybe<Array<Scalars['LocationID']>>;
  investigatorId: Scalars['InvestigatorID'];
  removeLocation?: InputMaybe<SetLocationIdInput>;
  removeLocations?: InputMaybe<Array<Scalars['LocationID']>>;
  setComments?: InputMaybe<SetOptionalStringInput>;
  setDontContact?: InputMaybe<SetBooleanInput>;
  setEmail?: InputMaybe<SetOptionalStringInput>;
  setEmails?: InputMaybe<Array<Scalars['String']>>;
  setHasWarmEmail?: InputMaybe<SetBooleanInput>;
  setName?: InputMaybe<SetStringInput>;
  setOrganisationIds?: InputMaybe<Array<Scalars['OrganisationID']>>;
  setPhone?: InputMaybe<SetOptionalStringInput>;
  setPhoneCode?: InputMaybe<SetOptionalStringInput>;
  setPosition?: InputMaybe<SetOptionalStringInput>;
};

export type UpdateLocationActionInput = {
  addGooglePlaceId?: InputMaybe<SetGooglePlaceIdInput>;
  addInvestigator?: InputMaybe<SetInvestigatorIdInput>;
  locationId: Scalars['LocationID'];
  removeInvestigator?: InputMaybe<SetInvestigatorIdInput>;
  setAddress?: InputMaybe<SetStringInput>;
  setContactEmail?: InputMaybe<SetNullableStringInput>;
  setContactPhoneNumber?: InputMaybe<SetNullableStringInput>;
  setCountryCode?: InputMaybe<SetCountryCodeInput>;
  setExternalId?: InputMaybe<SetOptionalStringInput>;
  setLatitude?: InputMaybe<SetFloatInput>;
  setLocales?: InputMaybe<SetLocalesInput>;
  setLongitude?: InputMaybe<SetFloatInput>;
  setName?: InputMaybe<SetStringInput>;
  setPrimaryContactInvestigator?: InputMaybe<SetInvestigatorIdInput>;
  setState?: InputMaybe<SetOptionalStateCodeInput>;
};

export type UpdateManagedInvestigatorActionInput = {
  addLocation?: InputMaybe<SetLocationIdInput>;
  investigatorId: Scalars['InvestigatorID'];
  removeLocation?: InputMaybe<SetLocationIdInput>;
};

export type UpdateMedicationActionInput = {
  setComment?: InputMaybe<SetOptionalStringInput>;
  setHelpText?: InputMaybe<SetOptionalStringInput>;
  setMedicationName?: InputMaybe<SetStringInput>;
};

export type UpdateMedicationGroupActionInput = {
  addMedications?: InputMaybe<Array<Scalars['AttributeDefinitionID']>>;
  removeMedications?: InputMaybe<Array<Scalars['AttributeDefinitionID']>>;
  setComment?: InputMaybe<SetOptionalStringInput>;
  setHelpText?: InputMaybe<SetOptionalStringInput>;
  setMedicationGroupName?: InputMaybe<SetStringInput>;
};

export type UpdateOptionItemActionInput = {
  id: Scalars['String'];
  setLabel?: InputMaybe<SetStringInput>;
};

export type UpdateOutgoingDerivedAttributeActionInput = {
  id: Scalars['DerivedAttributeRuleID'];
  setAttributeDefinitionIdTo?: InputMaybe<Scalars['AttributeDefinitionID']>;
  setValueFrom?: InputMaybe<ValueOrSkipInput>;
  setValueTo?: InputMaybe<ValueOrSkipInput>;
};

export type UpdatePatientInput = {
  conditionIds?: InputMaybe<Array<Scalars['ConditionID']>>;
  country?: InputMaybe<Scalars['CountryCode']>;
  dob?: InputMaybe<Scalars['String']>;
  facebookAuth?: InputMaybe<FacebookAuthInput>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['GooglePlaceId']>;
  lastName?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['String']>;
  lng?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<LocaleCode>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  setContactPreferencesChannels?: InputMaybe<SetOptionalContactPreferencesChannelsInput>;
  setContactPreferencesSchedule?: InputMaybe<SetOptionalStringInput>;
  setEmail?: InputMaybe<SetStringInput>;
  setStructuredWeeklySchedule?: InputMaybe<SetStructuredWeeklyScheduleInput>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['StateCode']>;
  suburb?: InputMaybe<Scalars['String']>;
  termsVersion?: InputMaybe<Scalars['Int']>;
};

export type UpdatePreappActionInput = {
  id: Scalars['PreappId'];
  setInterest?: InputMaybe<SetOptionalStringInput>;
};

export type UpdateRadioActionInput = {
  addOptionItems?: InputMaybe<Array<AddOptionItemActionInput>>;
  setComment?: InputMaybe<SetOptionalStringInput>;
  setHelpText?: InputMaybe<SetOptionalStringInput>;
  setQuestion?: InputMaybe<SetStringInput>;
  updateOptionItems?: InputMaybe<Array<UpdateOptionItemActionInput>>;
};

export type UpdateRequiredAttributesActionInput = {
  addRequiredAttributes?: InputMaybe<Array<Scalars['AttributeDefinitionID']>>;
  removeRequiredAttributes?: InputMaybe<Array<Scalars['AttributeDefinitionID']>>;
};

export type UpdateSponsorActionInput = {
  id: Scalars['SponsorID'];
  setAliases?: InputMaybe<Array<Scalars['String']>>;
  setName?: InputMaybe<SetStringInput>;
  setSponsorClass?: InputMaybe<SetOptionalStringInput>;
};

export type UpdateTrialActionInput = {
  actionNote?: InputMaybe<Scalars['String']>;
  setAnalyticsConfiguration?: InputMaybe<SetAnalyticsConfigurationInput>;
  setBdStatus?: InputMaybe<SetBdStatusInput>;
  setComment?: InputMaybe<SetOptionalStringInput>;
  setCommitmentInPerson?: InputMaybe<SetOptionalStringInput>;
  setCommitmentIsTelehealth?: InputMaybe<SetBooleanInput>;
  setCommitmentLength?: InputMaybe<SetOptionalStringInput>;
  setCommitmentTelehealth?: InputMaybe<SetOptionalStringInput>;
  setCommitments?: InputMaybe<SetCommitmentsInput>;
  setCompensationTextOverride?: InputMaybe<SetOptionalStringInput>;
  setCompensationType?: InputMaybe<SetCompensationTypeInput>;
  setConditions?: InputMaybe<SetConditionsInput>;
  setDescription?: InputMaybe<SetStringInput>;
  setEncodingStatus?: InputMaybe<SetEncodingStatusInput>;
  setEndDate?: InputMaybe<SetOptionalDateInput>;
  setIsAutovalidatingApplications?: InputMaybe<SetBooleanInput>;
  setIsEmrTrial?: InputMaybe<SetBooleanInput>;
  setIsPhoneTrial?: InputMaybe<SetBooleanInput>;
  setNickname?: InputMaybe<SetStringInput>;
  setPatientRecruitingStatus?: InputMaybe<SetPatientRecruitingStatusInput>;
  setProtocolId?: InputMaybe<SetOptionalStringInput>;
  setSponsor?: InputMaybe<SetSponsorInput>;
  setStartDate?: InputMaybe<SetOptionalDateInput>;
  setTitle?: InputMaybe<SetStringInput>;
  trialId: Scalars['TrialID'];
};

export type UpdateTrialOnboardingRequestEncoderActionInput = {
  id: Scalars['TrialOnboardingRequestId'];
  setRequestStatus: SetRequestStatusInput;
};

export type UpdateTrialOnboardingRequestInvestigatorActionInput = {
  id: Scalars['TrialOnboardingRequestId'];
  setTypeformCompleted: SetBooleanInput;
};

export type UploadMetadataInput = {
  attributeDefinitionId?: InputMaybe<Scalars['AttributeDefinitionID']>;
  comment?: InputMaybe<Scalars['String']>;
  fileSizeBytes: Scalars['Int'];
  filename: Scalars['String'];
};

export type UserCredentialsInput = {
  email?: InputMaybe<Scalars['String']>;
  facebookAuth?: InputMaybe<FacebookAuthInput>;
  loginCode?: InputMaybe<Scalars['String']>;
  oneClickLogin?: InputMaybe<OneClickLoginInput>;
  password?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<LoginSource>;
};

export type ValueOrSkip = {
  attributeDefinition: BooleanAttributeDefinition | FloatAttributeDefinition | MedicationAttributeDefinition | MedicationGroupAttributeDefinition | RadioAttributeDefinition | ScreeningBooleanAttributeDefinition;
};

export type ValueOrSkipInput = {
  boolean?: InputMaybe<BooleanInput>;
  float?: InputMaybe<FloatInput>;
  medication?: InputMaybe<MedicationInput>;
  medicationGroup?: InputMaybe<MedicationGroupInput>;
  radio?: InputMaybe<RadioInput>;
  skip?: InputMaybe<SkipInput>;
};

export enum VerificationChannel {
  CALL = 'CALL',
  SMS = 'SMS'
}

export type _Entity = Application | ApplicationSite | ApplicationSiteEvent | ApplicationSiteSyncChangeset | AutocompleteResult | Condition | Indication | InsightsOrganisation | InsightsUser | Investigator | Location | Organisation | RawTrial | Sponsor | Trial | TrialSite;

export type _Service = {
  __typename: '_Service';
  sdl: Scalars['String'];
};
