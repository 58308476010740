import { FC } from 'react';

import { Box, Breadcrumbs, Container, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ErrorPageContent } from 'shared/src/error-page-content/error-page-content';
import { Link } from 'shared/src/link/link';
import { beige } from 'shared/src/palette/color-system';

import { RecruitingPill } from 'mats/src/components/trial-card-public/recruiting-pill/recruiting-pill';
import { useEnhancedTrialCardsQuery } from 'mats/src/components/use-enhanced-trial-cards/use-enhanced-trial-cards';

import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { SiteMap } from 'pages/public-trial/components/site-map/site-map';
import { TrialCardThumbnail } from 'pages/public-trial/components/trial-card-thumbnail/trial-card-thumbnail';
import { usePublicTrialConditionCategoryBySlug } from 'pages/public-trial/queries.contentful';
import { PublicTrialConditionCategoryBySlug } from 'pages/public-trial/types/queries.contentful.generated';

import { TrialFooter } from './components/trial-footer/trial-footer';
import { TrialInfo } from './components/trial-info/trial-info';
import { useGetTrialPublic } from './queries';
import { GetTrialPublic } from './types/queries.generated';

type Trial = GetTrialPublic['trialByIdOpaque'];

type PublicTrialProps = {
  trial: Trial;
  hasTrialSites: boolean;
};

function truncateTrialTitle(trialTitle: string) {
  const prefixLength = 'HealthMatch - '.length;
  const truncated =
    trialTitle.length + prefixLength <= 60 ? trialTitle : trialTitle.slice(0, 57 - prefixLength);
  return truncated.trim().concat('...');
}

const BG_COLOR_1 = beige[50];
const BG_COLOR_2 = beige[200];

const PublicTrial: FC<PublicTrialProps> = ({ trial, hasTrialSites }) => {
  di(useEnhancedTrialCardsQuery);

  const cards = useEnhancedTrialCardsQuery([trial?.id ?? ''], !trial?.id);

  return (
    <>
      <Container>
        <Box marginTop={5} marginBottom={10} display="flex">
          <Box marginRight={2}>
            <TrialCardThumbnail trialId={trial.id} image={cards?.data?.[0]?.photo ?? undefined} />
          </Box>
          <Box>
            <Box marginBottom={1} display="flex">
              <RecruitingPill status={trial.patientRecruitingStatus} />
            </Box>
            <Typography variant="h5">{trial.title}</Typography>
          </Box>
        </Box>
      </Container>
      <Box paddingY={{ xs: 7, sm: 10 }} bgcolor={BG_COLOR_1}>
        <Container>
          <TrialInfo trial={trial} />
        </Container>
      </Box>
      {hasTrialSites && (
        <Box paddingY={{ xs: 7, sm: 10 }} bgcolor={BG_COLOR_2}>
          <Container>
            <Box marginBottom={5}>
              <Typography variant="h5">Trial locations</Typography>
            </Box>
            <Box height={{ sm: 376 }}>
              <SiteMap locations={trial.trialSites.map(s => s.location)} />
            </Box>
          </Container>
        </Box>
      )}
      <Box paddingBottom={10} bgcolor={hasTrialSites ? BG_COLOR_2 : BG_COLOR_1} />
    </>
  );
};

type PublicTrialWithBreadcrumbsProps = {
  conditionCategory?: DeepExtractTypeSkipArrays<
    PublicTrialConditionCategoryBySlug,
    ['conditionCategoryCollection', 'items']
  >;
} & PublicTrialProps;

const PublicTrialWithBreadcrumbs: FC<PublicTrialWithBreadcrumbsProps> = ({
  trial,
  conditionCategory,
  hasTrialSites,
}) => {
  di(useLocation);

  const { pathname } = useLocation();

  const conditionId =
    conditionCategory?.conditionIdList && conditionCategory?.conditionIdList.length === 1
      ? Number(conditionCategory?.conditionIdList[0])
      : undefined;

  const correctTrialForCondition =
    conditionCategory !== undefined &&
    conditionId !== undefined &&
    trial.conditions.some(c => c.id === conditionId);

  if (correctTrialForCondition) {
    return (
      <>
        <Container>
          <Breadcrumbs>
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to={`/${conditionCategory}`}>
              {conditionCategory.condition}
            </Link>
            <Link color="inherit" to={`/${conditionCategory}/clinical-trials`}>
              {conditionCategory.condition} clinical trials
            </Link>
            <Link color="inherit" to={pathname}>
              {trial.title}
            </Link>
          </Breadcrumbs>
        </Container>
        <PublicTrial trial={trial} hasTrialSites={hasTrialSites} />
        {conditionId && (
          <TrialFooter
            conditionId={conditionId as ConditionID}
            background={hasTrialSites ? 'gradient' : 'solid'}
          />
        )}
      </>
    );
  }
  return <PublicTrial trial={trial} hasTrialSites={hasTrialSites} />;
};

const LIGHT_BLEED = { kind: 'background', color: BG_COLOR_1 } as const;
const DARK_BLEED = { kind: 'background', color: BG_COLOR_2 } as const;

export const PublicTrialRoute: FC<RouteComponentProps<{ id: string; category?: string }>> = ({
  match: {
    params: { id: trialIdOpaque, category },
  },
}) => {
  di(useGetTrialPublic, PageBase, usePublicTrialConditionCategoryBySlug);

  const { data, loading, error } = useGetTrialPublic({
    variables: { id: trialIdOpaque as TrialID },
  });

  const { data: conditionCategoryData, loading: conditionCategoryLoading } =
    usePublicTrialConditionCategoryBySlug({
      skip: !category,
      variables: {
        slug: category as string,
      },
    });

  const trial = data?.trialByIdOpaque;

  if (loading || conditionCategoryLoading) {
    return <LoadingPage windowTitle="Trial information" />;
  }

  if (error) {
    return <ErrorPageContent />;
  }

  if (trial == null) {
    return null;
  }

  const hasTrialSites = trial.trialSites.length > 0;
  const conditionCategory =
    conditionCategoryData?.conditionCategoryCollection?.items?.[0] ?? undefined;
  const footerBleed = hasTrialSites ? DARK_BLEED : LIGHT_BLEED;

  const content = category ? (
    <PublicTrialWithBreadcrumbs
      hasTrialSites={hasTrialSites}
      trial={trial}
      conditionCategory={conditionCategory}
    />
  ) : (
    <PublicTrial trial={trial} hasTrialSites={hasTrialSites} />
  );

  return (
    <PageBase windowTitle={truncateTrialTitle(trial.title)} footerBleed={footerBleed}>
      <Box marginTop={5}>{content}</Box>
    </PageBase>
  );
};
