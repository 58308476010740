import { FC } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { VerticalStack } from 'shared/src/stack/vertical-stack';
import { GenderRequirements } from 'shared/src/types/graphql-schema';

import { TrialPublicRecord } from 'mats/src/fragments/trial/types/trial-public-record-fragment.generated';

import { GetTrialPublic } from 'pages/public-trial/types/queries.generated';

type TrialPublic = GetTrialPublic['trialByIdOpaque'];

export const TrialInfo: FC<{ trial: TrialPublic }> = ({ trial }) => (
  <>
    <Box marginBottom={{ xs: 3, sm: 5 }}>
      <Typography variant="h5">About the trial</Typography>
    </Box>
    <Grid container spacing={4}>
      <TrialDetail title="Study status">
        {trial.patientRecruitingStatus === 'recruiting' ? 'Actively recruiting' : 'Not recruiting'}
      </TrialDetail>
      {trial.commitmentIsTelehealth && <TrialDetail title="Type of trial">Telehealth</TrialDetail>}
    </Grid>
    <Box marginY={{ xs: 7, sm: 5 }}>
      <Divider />
    </Box>
    <Box marginBottom={{ xs: 3, sm: 5 }}>
      <Typography variant="h5">Eligibility</Typography>
    </Box>
    <Grid container spacing={4}>
      <TrialDetail title="Condition(s)">{trial.conditions.map(t => t.name).join(', ')}</TrialDetail>
      <TrialDetail title="Age range">{formatAgeInfo(trial.ageRequirements)}</TrialDetail>
      <TrialDetail title="Biological sex">{formatGenderInfo(trial.genderRequirements)}</TrialDetail>
    </Grid>
    {trial.commitments.length > 0 && (
      <>
        <Box marginY={{ xs: 7, sm: 5 }}>
          <Divider />
        </Box>
        <Box marginBottom={{ xs: 3, sm: 5 }}>
          <Typography variant="h5">Trial commitments</Typography>
        </Box>
        <Box marginLeft={-2} marginTop={5}>
          <VerticalStack component="ul" spacing={3}>
            {trial.commitments.map(({ id, displayText }) => (
              <Typography key={id} component="li" variant="body2" color="textSecondary">
                {displayText}
              </Typography>
            ))}
          </VerticalStack>
        </Box>
      </>
    )}
  </>
);

const TrialDetail: FC<{ title: string }> = ({ children, title }) => (
  <Grid item xs={12} sm={4}>
    <Typography variant="body1" paragraph>
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {children}
    </Typography>
  </Grid>
);

const formatAgeInfo = (
  ageRequirements: TrialPublicRecord['ageRequirements'] | null | undefined
): string => {
  if (!ageRequirements || ageRequirements.length === 0) {
    return 'No age requirements';
  }

  const min = Math.min(
    ...ageRequirements.filter(r => r.operator === 'GT' || r.operator === 'GTE').map(r => r.value)
  );
  const max = Math.max(
    ...ageRequirements.filter(r => r.operator === 'LT' || r.operator === 'LTE').map(r => r.value)
  );
  const minText = min === Infinity ? 'no minimum age' : min.toFixed(0);
  const maxText = max === -Infinity ? 'no maximum age' : max.toFixed(0);

  return `${minText}—${maxText}`;
};

const formatGenderInfo = (genderRequirements: GenderRequirements | null | undefined): string => {
  if (!genderRequirements) {
    return 'All';
  }
  return genderRequirements === 'MALE' ? 'Male' : 'Female';
};
